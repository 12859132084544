import React, { useState } from 'react';
import dictionary from 'translations/dictionary.sv.json';


const records = ['Week', 'Month', '3 Months', '6 Months', 'Year', 'All time'] as const
type Selection = typeof records[number]
const timeRecord: Record<Selection, number> = { 'Week': 7, 'Month': 30, '3 Months': 90, '6 Months': 180, Year: 365, 'All time': 0 }
const translateRecord = (record: Selection) => {
    switch (record) {
        case 'Week':
            return dictionary.LastWeek
        case 'Month':
            return dictionary.LastMonth
        case '3 Months':
            return dictionary.LastThreeMonths
        case '6 Months':
            return dictionary.LastSixMonths
        case 'Year':
            return dictionary.LastYear
        case 'All time':
            return dictionary.AllTime
        default:
            return record
    }
}
export function getFromDate(timeframe: Selection) {
    var d = new Date();
    if (timeframe === 'All time') return new Date(0)
    d.setDate(d.getDate() - timeRecord[timeframe])

    return d
}

export const TimeFrameSelector = (props: { setCurrentTimeFrame: (value: Date) => void }) => {
    const { setCurrentTimeFrame } = props

    return <>
        <select onChange={event => setCurrentTimeFrame(getFromDate(event.target.value as Selection))} style={{marginBottom: '8px'}}>
            {records.map(rec => <option key={rec} value={rec}>{translateRecord(rec)}</option>)}
        </select>
    </>

}