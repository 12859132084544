import React, { useEffect, useState } from 'react';

import dictionary from 'translations/dictionary.sv.json';
import styles from './ChooseSubarea.module.scss';
import { AreaNode } from '@viewmodel/areaNode';
import areaNodeService from 'services/areaNodeService';
import LoadingPage from 'components/LoadingPage/LoadingPage';

export const getTaskCount = (node: AreaNode, taskCounts: { node: string, count: number }[]): number => {
    const count = taskCounts.find(tc => tc.node === node.headline)?.count ?? 0
    if (!node.subareas) return count
    return count + node.subareas.reduce((acc, sub) => acc + getTaskCount(sub, taskCounts), 0)
}

export const DisplaySubjectArea = (props: AreaNode & {
    depth?: number,
    selectNode: (id: number) => boolean,
    setNode: (node: AreaNode) => void,
    uninteractNodes: AreaNode[] | undefined
    taskCounts?: { node: string, count: number }[]
}) => {

    const { id, headline, subareas = [], relevantWords = '', depth = 0, description, selectNode, setNode, uninteractNodes, taskCounts } = props;
    const [show, setShow] = useState<{ id: number, show: boolean }>({ id, show: false });
    if (subareas.length === 0) {
        return (
            <span>
                {uninteractNodes?.find(n => n.id === id) !== undefined ?
                    <div>
                        <span className={` ${styles.subarea_name}`} style={{ cursor: 'default', textDecoration: 'none', color: 'gray' }} >{headline}</span>
                        {taskCounts && <span className='ms-2'><b>({getTaskCount(props, taskCounts)})</b></span>}
                    </div> :
                    <div onClick={() => setNode({ id, headline, subareas, relevantWords, description })}>
                        <span className={` ${styles.subarea_name}`} style={{ cursor: 'pointer', textDecoration: 'underline' }} >{headline}</span>
                        {taskCounts && <span className='ms-2'><b>({getTaskCount(props, taskCounts)})</b></span>}
                    </div>
                }
            </span>
        )
    }

    const title = () => {
        if (depth === 0) return <h2>{headline}</h2>;
        if (depth === 1) return <h3>{headline}</h3>;
        if (depth === 2) return <h4>{headline}</h4>;
        if (depth === 3) return <h5>{headline}</h5>;
        return <h6>{headline}</h6>;
    }

    return (
        <section className="row">
            <span style={{ cursor: 'pointer' }} className={"expanded col-1 " + styles.tooltip} data-bs-toggle="collapse"
                data-bs-target={"#collapse" + id} aria-expanded="true" aria-controls={"collapse" + id}
                onClick={() => { if (id === show.id) setShow({ id, show: !show.show }) }} >
                {show && show.id === id && show.show ? <i className={`${styles.right} ${styles.arrow} text-align-right`}></i> : <i className={`${styles.down} ${styles.arrow}`}></i>}
                <span id='' className={`${styles.tooltiptext}`}>Visa underliggande områden</span>
            </span>
            <div className={"col-7 "}>
                <span className={`${styles.tooltip} ${styles.subarea_name}`} style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => setNode({ id, headline, subareas, relevantWords, description })}>
                    {depth === 1 ? <b>{headline}</b> : headline}
                    <span id='' className={`${styles.tooltiptext} ${styles.tooltiptext_left}`}>Klicka för att välja området</span>
                </span>
                {taskCounts && <span className='ms-2'><b>({getTaskCount(props, taskCounts)})</b></span>}
            </div>
            <div id={"collapse" + id} className="accordion-collapse collapse" aria-labelledby={"heading" + id} data-bs-parent={"#accordionExample" + id}>
                <div className="accordion-body" style={{ padding: '0rem 1.25rem' }}>
                    <ul className={`${styles.subareaList}`} style={{ margin: '0px' }}>
                        {subareas.map((area) => (
                            <li key={area.id}>
                                <DisplaySubjectArea {...area} depth={depth + 1} selectNode={selectNode} setNode={setNode} uninteractNodes={uninteractNodes} taskCounts={taskCounts} />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section >
    )
}

function flattenNode(node: AreaNode) {
    let flattenedNodes = [node]
    node.subareas?.forEach(sub => {
        flattenedNodes = flattenedNodes.concat(flattenNode(sub));
    })
    return flattenedNodes
}

function ChooseSubarea(props: {
    headline: string,
    preamble: JSX.Element,
    nodes: AreaNode[] | undefined,
    uninteractNodes: AreaNode[] | undefined
    checkNode: (id: number) => boolean,
    setNode: (node: AreaNode) => void,
    showMyNodesButton: boolean,
    nodeTaskCounts?: { node: string, count: number }[]
}) {
    const {
        headline,
        preamble,
        nodes,
        uninteractNodes,
        checkNode,
        setNode,
        showMyNodesButton,
    } = props;
    const [searchString, setSearchString] = useState<string>('')
    const [gettingMyNodes, setGettingMyNodes] = useState<boolean>(false)
    const [myNodesStatus, setMyNodesStatus] = useState<string>()
    const [nodesToDisplay, setNodesToDisplay] = useState<AreaNode[] | undefined>(nodes)

    const FindNodesBySearchString = (searchString: string) => {
        let filteredNodes: AreaNode[] = []
        if (searchString.trim() === '') return filteredNodes
        if (nodes) {
            nodes.forEach(node => {
                const matches = flattenNode(node).filter(flattenNode => flattenNode.headline.toLowerCase().includes(searchString.toLowerCase().trim()))
                filteredNodes = filteredNodes.concat(matches)
            })
        }
        return filteredNodes
    }

    const SearchNodes = () => {
        const foundNodes = FindNodesBySearchString(searchString)
        return <>
            {foundNodes.length > 0 && <>{foundNodes.map((node, index) =>
                <div><span key={index} className={styles.searchResult} onClick={() => (setNode(node))}>{node.headline}</span></div>)
            } <hr />
            </>}
        </>
    }

    const handleOnlyMyNodes = async () => {
        if (nodes && nodes.length !== nodesToDisplay?.length) {
            setNodesToDisplay(nodes)
        } else {
            setGettingMyNodes(true)
            setMyNodesStatus(dictionary.GettingMyNodes)
            const userNodes = await areaNodeService.getUserAreaNodes()
            if (userNodes.length === 0) setMyNodesStatus(dictionary.YouHaveNotCreatedAnyAssignments)
            else {
                setMyNodesStatus(undefined)
                setNodesToDisplay(userNodes)
            }
            setGettingMyNodes(false)
        }
    }

    useEffect(() => {
        if (!nodesToDisplay) {
            setNodesToDisplay(nodes)
        }
    }, [nodes, nodesToDisplay])

    return (
        <div>
            {/* <div><b>{dictionary.HomeworkText1}</b></div>
            <div>{dictionary.HomeworkText2}</div>
            <br />
            <div>{dictionary.HomeworkText3}</div>
            <br /> */}
            {preamble}
            <h4>{headline}</h4>
            {showMyNodesButton && <><input id="onlyMyNodes" className='ms-3 me-3 btn btn-secondary' type="checkbox" onChange={() => handleOnlyMyNodes()} /><label htmlFor='onlyMyNodes' className=' mb-3'> {dictionary.OnlyMyAssignments}</label></>}

            <div className={`${styles.flexContainer} row`}>
                {nodesToDisplay ? nodesToDisplay.map((area, index) => (
                    <div key={index} className={`col-12 col-md-3`} >
                        <div className="accordion" id={"accordionExample"}>
                            <DisplaySubjectArea {...area} depth={1} selectNode={checkNode} setNode={setNode} uninteractNodes={uninteractNodes} taskCounts={props.nodeTaskCounts} />
                        </div>
                    </div>
                )) : <><div>
                    {dictionary.GettingNodes}
                    <LoadingPage />
                </div></>}
            </div>
            <input  className="mt-4" value={searchString} onChange={(event) => setSearchString(event.target.value)} placeholder={dictionary.SearchSubject} />
            <div className='mt-2 mb-3'>{myNodesStatus ?? <SearchNodes></SearchNodes>}</div>
        </div>
    )
}

export default ChooseSubarea;
