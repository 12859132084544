import { AreaNode, ExportNode } from "@viewmodel/areaNode";

const baseUrl = `${process.env.REACT_APP_API_BASE}/nodes`

async function getAreaNodes(): Promise<AreaNode[]> {
    const response = await fetch(`${baseUrl}`, {
        credentials: 'include',
    });
    return response.json();
}

async function getUserAreaNodes(): Promise<AreaNode[]> {
    const response = await fetch(`${baseUrl}/user`, {
        credentials: 'include',
    });
    return response.json();
}

async function insert(nodes: ExportNode[], rules: {node: string, text: string}[]) {
    const response = await fetch(`${baseUrl}`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({nodes, rules})
    })
    return response.json()
}

async function deleteNodes(ids: number[]) {
    const response = await fetch(`${baseUrl}`, {
        method: 'DELETE',
        credentials: "include",
        body: JSON.stringify({'ids': ids})
    })
    return response;
}

async function GetNodesThatHaveTasks(): Promise<AreaNode[]> {
    const response = await fetch(`${baseUrl}/task-nodes`, {
        credentials: 'include',
    });
    return response.json();
}

export const areaNodeService = {
    getAreaNodes,
    getUserAreaNodes,
    GetNodesThatHaveTasks,
    insert,
    deleteNodes
}

export default areaNodeService