import React, { useCallback, useEffect, useState } from 'react'
import { AreaNode } from '@viewmodel/areaNode';
import ChooseSubarea from 'components/ChooseSubarea/ChooseSubarea';

import dictionary from 'translations/dictionary.sv.json';
import CreateAssignment from './CreateAssignment';
import { NavLink, useParams } from 'react-router-dom';
import areaNodeService from 'services/areaNodeService';
import { useGameGlobals } from 'App';

const findNodeWithHeadline = (searchString: string, nodes?: AreaNode[]): AreaNode | undefined => {
    if (!nodes || nodes.length === 0) return undefined
    let tempNode = undefined
    nodes.forEach(node => {
        if (node.headline === searchString) {
            tempNode = node
            return
        };
        const result = findNodeWithHeadline(searchString, node.subareas)
        if (result) {
            tempNode = result;
            return
        }
    });
    return tempNode
}

const Assignment = () => {
    const [nodes, setNodes] = useState<AreaNode[] | undefined>(undefined)
    const [node, setNode] = useState<AreaNode | undefined>(undefined)

    const params = useParams();
    const { subarea } = params;

    const gameGlobals = useGameGlobals()


    const handleNode = useCallback((node: AreaNode) => {
        window.history.pushState({ assignment: 2 }, node.headline, `Assignment/${encodeURIComponent(node.headline)}`)
        setNode(node);
    }, [])

    const checkNode = useCallback((id: number) => {
        return node?.id === id
    }, [node])

    const checkParams = () => {
        const location = window.location
        const href = location.href
        const hrefSplit = href.split('/')
        const index = hrefSplit.indexOf('Assignment')
        return decodeURIComponent(hrefSplit[index + 1] ?? '');

        // const params = new URLSearchParams(window.location.search);
        // return decodeURIComponent(params.get("area") ?? '');
    }

    const preamble = <>
        <p><b>Skapa egna uppgifter</b>
            <p>
                Har du egna övningar som du vill att dina elever skall göra är det enkelt att lägga till dessa. Tänk på:
            </p>
            <ol>
                <li>För att systemet ska kunna följa upp dina elevers framsteg på ett så bra sätt som möjligt så måste alla övningar tillhöra ett övningsområde, t.ex. abstrakta substantiv, personliga pronomen/de eller personliga pronomen/jag. </li>
                <li>Till varje övningsområde finns det regler och förklaringar inlagda som hjälper eleven att förstå grammatiken. </li>
                <li>Har du några funderingar eller saknar något i Elevert, skicka gärna ett <a href='Feedback'>mail</a> till oss. </li>
            </ol>
        </p>
    </>

    useEffect(() => {
        let running = true;

        if (!nodes && gameGlobals.getAreaNodes) {
            gameGlobals.getAreaNodes(false).then(result => {
                if (running) {
                    setNodes(result)
                    const area = checkParams();
                    if (area) {
                        const node = findNodeWithHeadline(area, nodes)
                        setNode(node)
                    }
                }
            })
            // areaNodeService.getAreaNodes()
            //     .then(nodes => {
            //         if (running) {
            //             setNodes(nodes)
            //             const area = checkParams();
            //             if (area) {
            //                 const node = findNodeWithHeadline(area, nodes)
            //                 setNode(node)
            //             }
            //         }
            //     })
        }

        if (nodes && subarea) {
            const result = findNodeWithHeadline(subarea, nodes)
            setNode(result)
        }
        return () => { running = false; };

    }, [nodes, subarea])

    return (
        <div>
            {!node || !checkParams() ? <>
                <ChooseSubarea headline={dictionary.ChooseSubjectAreaForTheAssignment} preamble={preamble} nodes={nodes} checkNode={checkNode} setNode={handleNode} showMyNodesButton={false} uninteractNodes={undefined} />
            </> : <><CreateAssignment subarea={node.headline} /></>}
        </div>
    )
}

export default Assignment