import React from 'react'

function AddAlternatives(props: { gaps: boolean[], words: string[], handleAlternatives: (index: number, word: string) => void, className: string }) {
    const { gaps, words, handleAlternatives, className } = props;

    return (<>
        {gaps.map((g, index) => gaps[index] && <div className={className}>
            <div className='col-3 mt-2'>{words[index]} </div>
            <div className='col-4 mt-2'>
                <input type="text" onChange={(event) => handleAlternatives(index, event.target.value)} />
            </div>
        </div>)}
    </>)
}

export default AddAlternatives;

