
export type inviteEmail = {
    emailMessage: string,
    studentEmails: string[],
    teacherName: string,
    schoolName: string,
    institutionId: string,
    className: string,
    class_id: string,
}

async function sendInviteEmail(email: inviteEmail, isPreview: boolean = false) {
    const fetchOption: RequestInit = {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(email)
    }

    const response = await fetch(
        `${process.env.REACT_APP_API_BASE}/email/invite${isPreview ? '/preview' : ''}`,
        { ...fetchOption }
    )

    return await response.json();
}

async function sendHomeworkEmail(fetchOptions: RequestInit, isPreview: boolean = false) {

    const response = await fetch(
        `${process.env.REACT_APP_API_BASE}/email/homework${isPreview ? '/preview' : ''}`,
        fetchOptions
    )
    return response;
}

async function sendFeedbackEmail(emailMessage: string) {

    const fetchOptions: RequestInit = {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({emailMessage})
    }

    const response = await fetch(
        `${process.env.REACT_APP_API_BASE}/email/feedback`,
        {...fetchOptions}
    )
    return response;
}

async function sendTestEmail(emailAddress: string) {
    const fetchOptions: RequestInit = {
        method: 'GET',
        credentials: 'include',
    }

    const response = await fetch(
        `${process.env.REACT_APP_API_BASE}/email/test?email=${emailAddress}`,
        {...fetchOptions}
    )
    return response;
}

async function testBrevo(toEmails: {email: string, name: string}[]) {
    const fetchOptions: RequestInit = {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({toEmails})
    }
    const response = await fetch(
        `${process.env.REACT_APP_API_BASE}/brevo/test`,
        {...fetchOptions}
    )
    return response;
}

export const emailService = {
    sendInviteEmail,
    sendHomeworkEmail,
    sendFeedbackEmail,
    sendTestEmail,
    testBrevo,
}

export default emailService