import { StudentBase } from "@viewmodel/users";


async function getStudentsbyClassId(classId: string): Promise<StudentBase[]> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/groups/${classId}/members`, {
        credentials: 'include',
    });
    return response.json();
} 

async function getStudents(): Promise<StudentBase[]> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/student`, {
        credentials: 'include'
    })
    return response.json();
}


async function getStudentsCountByDate(date: Date, limit: number) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/student/count/date?date=${date.toISOString()}&limit=${limit}`, {
        credentials: 'include'
    })
    return response.json();
}

async function getStudentActivityCount(limit: number) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/student/count/activityDays?limit=${limit}`, {
        credentials: 'include'
    })
    return response.json();
}

async function getStudentCorrectActivityCount(limit: number) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/student/count/activityCorrect?limit=${limit}`, {
        credentials: 'include'
    })
    return response.json();
}

async function deleteStudents(studentIds: string[] ) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/student`, {
        credentials: 'include',
        method: 'delete',
        body: JSON.stringify(studentIds)
    })
    return response.json();
}

async function getStudentsCorrectStreakCount() {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/student/count/mode?mode=streak`, {
        credentials: 'include'
    })
    return response.json();
}

export const studentSevice = {
    getStudentsbyClassId,
    getStudents,
    getStudentsCountByDate,
    getStudentActivityCount,
    getStudentCorrectActivityCount,
    getStudentsCorrectStreakCount,
    deleteStudents
}

export default studentSevice