import { TaskDefinition, TaskType } from '@task';

import { useParams } from 'react-router-dom';
import CreateFillGap from 'components/TaskComponents/FillGap/CreateFillGap';
import CreateFreeTextChallenge from 'components/TaskComponents/FreeText/CreateFreeTextChallenge';
import CreateMultipleChoice from 'components/TaskComponents/MultipleChoice/CreateMultipleChoice';
import React, { useEffect, useState } from 'react';

import dictionary from 'translations/dictionary.sv.json'
import TaskTypeCard from './TaskTypeCard';
import taskService from 'services/taskService';

/** A compiler-checked map of all TaskType variants */
const TASK_TYPES: { [Type in TaskType]: Type } = {
    FillGaps: 'FillGaps',
    FreeText: 'FreeText',
    MultipleChoice: 'MultipleChoice',
    PickWords: 'PickWords',
    SortWords: 'SortWords',
};

type AssignmentPart = TaskDefinition[];

export function SetPartBuilder(
    taskType: TaskType,
    setPartBuilders: (partBuilders: JSX.Element[]) => void,
    setParts: (parts: AssignmentPart[]) => void,
) {
    // const params = useParams();
    // const { relevantWords } = params;
    const relevantWords = ''

    /** The index of the new part builder will be just past the end of the current part builders */
    const updatePart = (part: AssignmentPart) => {
        setParts([part]);
    };

    if (taskType === 'FillGaps') {
        setPartBuilders(
            [<CreateFillGap filter={relevantWords ?? undefined} onChange={updatePart} />]
        )
    } else if (taskType === 'FreeText') {
        setPartBuilders(
            [<CreateFreeTextChallenge onChange={updatePart} />]
        )
    } else if (taskType === 'MultipleChoice') {
        setPartBuilders(
            [<CreateMultipleChoice onChange={updatePart} />]
        )
    } else {
        console.error(`Missing handler for task type ${taskType}`);
    }
}

function AddAssignmentPart(props: {
    parts: AssignmentPart[], setParts: (event: any) => void,
    partBuilders: JSX.Element[], setPartBuilders: (event: any) => void,
    setLastTaskType: (taskType: TaskType) => void
    setMeta: (meta: Required<TaskDefinition>['meta']) => void
}) {

    const [nrOfQuestions, setNumberOfQuestions] = useState<number | undefined>(undefined)
    const params = useParams();

    const { relevantWords } = params;

    // if (relevantWords == null) {
    //     // TODO: better error message/recovery
    //     // return <>Något gick fel</>
    // }

    const updateParts = (index: number, part: AssignmentPart) => {
        const assignmentParts = props.parts.slice();
        assignmentParts[index] = part;
        props.setParts(assignmentParts);
    }

    const addPartBuilder = (taskType: TaskType) => {
        /** The index of the new part builder will be just past the end of the current part builders */
        const index = props.partBuilders.length;
        const addBuilder = (builder: JSX.Element) => props.setPartBuilders(props.partBuilders.concat(builder));
        const updatePart = (part: AssignmentPart, meta: Required<TaskDefinition>['meta']) => {updateParts(index, part); props.setMeta(meta)};

        if (taskType === 'FillGaps') {
            addBuilder(
                <CreateFillGap filter={relevantWords ?? undefined} onChange={updatePart} />
            )
            props.setLastTaskType(taskType)
        } else if (taskType === 'FreeText') {
            addBuilder(
                <CreateFreeTextChallenge onChange={updatePart} />
            )
            props.setLastTaskType(taskType)
        } else if (taskType === 'MultipleChoice') {
            addBuilder(
                <CreateMultipleChoice onChange={updatePart} />
            )
            props.setLastTaskType(taskType)
        } else {
            console.error(`Missing handler for task type ${taskType}`);
        }
    }

    useEffect(() => {
        if (!nrOfQuestions) {
            taskService.getTaskCount().then(result => {
                setNumberOfQuestions(result.count);
            })
        }

    }, [nrOfQuestions])

    return (<div>
        <div>
            {(props.partBuilders.length === 0 || window.history.state['assignment'] === 2) && <>
                <b>{dictionary.WhatTypeOfAssignmentDoYouWantToMake}</b>
                {nrOfQuestions && <div>{dictionary.NumberOfQuestionsInTheSystem}: {nrOfQuestions}</div>}
                <TaskTypeCard taskType={TASK_TYPES.FillGaps} taskTypeName={dictionary.FillGaps} taskTypeDescription={dictionary.FillGapDescription} onSelect={addPartBuilder}></TaskTypeCard>
                <TaskTypeCard taskType={TASK_TYPES.FreeText} taskTypeName={dictionary.FreeText} taskTypeDescription={dictionary.FreeTextDescription} onSelect={addPartBuilder}></TaskTypeCard>
                <TaskTypeCard taskType={TASK_TYPES.MultipleChoice} taskTypeName={dictionary.MultiChoiceQuestion} taskTypeDescription={dictionary.MultiChoiceQuestionDescription} onSelect={addPartBuilder}></TaskTypeCard>
            </>}
        </div>
    </div>)
}

export default AddAssignmentPart;