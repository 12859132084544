import React, { useState } from 'react';
import Task, { FillGapsTaskDefinition, TaskDefinition, taskIs } from '@task';
import SimpleXlsxFileInput, { SimpleXlsxFile } from '../../SimpleXlsx/SimpleXlsxFileInput';
import dictionary from 'translations/dictionary.sv.json';
import AddNewFillGapSentence from './AddNewFillGapSentence';
import HandleFillGaps from './HandleFillGaps';
import styles from './../task-components.module.css';
import TaskTypeCard from 'pages/Assignment/_components/TaskTypeCard';
import goldenArrowAnimated from './../golden_arrow animated.png'
import ImportTasksFromExcel from '../Task Functions/ImportTasksFromExcel';

const createFillGapSentence = (filter: string | undefined, meta: TaskDefinition['meta']) => (sentence: string): (FillGapsTaskDefinition & { id: string }) => {
    const words = sentence.split(' ');
    const filterWords = filter?.toLocaleLowerCase().split(',');
    const gaps = filterWords
        ? words.map(word => filterWords.includes(word.toLocaleLowerCase()))
        : words.map(() => false);
    return { id: '', type: 'FillGaps', words, gaps, options: filterWords ?? [], meta, comment: '' };
}

type CreateFillGapProps = {
    filter: string | undefined;
    onChange: (part: (FillGapsTaskDefinition & { id: string })[], meta: Required<TaskDefinition>['meta']) => void;
}

function CreateFillGap(props: CreateFillGapProps): JSX.Element {
    const [data, _setData] = useState<(FillGapsTaskDefinition & { id: string })[]>([]);
    const [showSentence, setshowSentence] = useState(false);
    const [showImport, setshowImport] = useState(false);
    const [readInstructions, setReadInstruction] = useState<boolean>(false)
    const [metaData, setMetaData] = useState<Required<TaskDefinition>['meta']>({ subarea: '', gradeYear: '', instructions: '', difficulty: 0 });

    const gradeYears = [{ difficulty: 100, text: 'Årskurs 1' }, { difficulty: 200, text: 'Årskurs 2' }, { difficulty: 300, text: 'Årskurs 3' }, { difficulty: 400, text: 'Årskurs 4' }, { difficulty: 500, text: 'Årskurs 5' }, { difficulty: 600, text: 'Årskurs 6' }, { difficulty: 700, text: 'Årskurs 7' }, { difficulty: 800, text: 'Årskurs 8' }, { difficulty: 900, text: 'Årskurs 9' }, { difficulty: 1000, text: 'Gymnasium' }]
    const setData: typeof _setData = (action) => {
        _setData((state) => {
            const newData = typeof action === 'function' ? action(state) : action;
            emitChange(newData);
            return newData;
        });
    }

    /** Emits the state to the parent. TODO: move the state to the parent */
    const emitChange = (newData: typeof data) => {
        props.onChange(newData, metaData);
    }

    /** Should only be called synchronously to avoid overwriting async changes */
    const handleChange = (index: number, value: typeof data[number]) => {
        setData(data.map((v, i) => i !== index ? v : value));
    }

    /** Should only be called synchronously to avoid overwriting async changes */
    const handleDelete = (index: number) => {
        setData(data.filter((v, i) => i !== index));
    }

    const appendSentence = (sentence: string) => {
        setData(state => state.concat(createFillGapSentence(props.filter, metaData)(sentence.replace(/\s+/g, ' ').trim())));
    }

    const appendTasks = (tasks: TaskDefinition[]) => {
        if (tasks.length === 0) return;
        const validTasks: typeof data = tasks.filter(taskIs('FillGaps'));
        if (tasks.length !== validTasks.length) {
            console.error('Not all tasks were of the correct type');
        }
        setData(state => state.concat(validTasks));
    }

    const onXlsxFileInputChange = (xlsxFiles: readonly SimpleXlsxFile[]) => {
        let tasks: (FillGapsTaskDefinition & { id: string })[] = []
        const createTask = createFillGapSentence(props.filter, metaData)

        tasks = (ImportTasksFromExcel(xlsxFiles, createTask))

        appendTasks(tasks)
    }

    const handleSetMetaData = (value: string) => {
        const meta = { ...metaData, gradeYear: gradeYears[+value].text, difficulty: gradeYears[+value].difficulty }
        setMetaData(meta)
        setData(state => state.map((v, i) => ({ ...v, meta })))
    }

    return (
        <div>
            <TaskTypeCard taskType="FillGaps" taskTypeName={dictionary.FillGaps} taskTypeDescription={dictionary.FillGapDescription} onSelect={() => { }}></TaskTypeCard>
            <h4>{dictionary.CreateGapSentence}</h4>

            <span>
                {dictionary.CreateTaskDescriptions.CreateGapSentences.LineOne}
                <ol>
                    <li>{dictionary.CreateTaskDescriptions.CreateGapSentences.ListLineOne}</li>
                    <li>{dictionary.CreateTaskDescriptions.CreateGapSentences.ListLineTwo}</li>
                    <li>{dictionary.CreateTaskDescriptions.CreateGapSentences.ListLineThree}</li>
                    <li>{dictionary.CreateTaskDescriptions.CreateGapSentences.ListLineFour}</li>
                </ol>
            </span>

            {!readInstructions && <div style={{ textAlign: "center" }}> <img src={goldenArrowAnimated} alt='Next arrow' style={{ minWidth: '5%', maxWidth: '5%' }} onClick={() => setReadInstruction(true)} /></div>}

            {readInstructions && <>
                <div>
                    <label>Välj lämplig årskurs</label><label style={{ color: 'red' }}>*</label> <br />
                    <select onChange={(event) => handleSetMetaData(event.target.value)}>
                        <option selected hidden>Årskurser</option>
                        {gradeYears.map((year, index) => <option value={index}>{year.text}</option>)}
                    </select>
                </div>
                {data.length === 0 && <div>
                    {!showImport && <div><b>{dictionary.StepOneOfThree}</b>: {dictionary.WriteInAGapSentenceBelow}</div>}
                    {!showSentence && !showImport &&
                        <div className='col-12 col-md-6 mb-3'>
                            <AddNewFillGapSentence onSubmit={appendSentence} />
                            <label className='form-label'>{dictionary.Or}</label>
                            <button className='form-control btn btn-primary' onClick={() => setshowImport(true)}>{dictionary.UploadAnExcelFileWithSentences}</button>
                        </div>
                    }

                    {showImport && <><br />
                        <div className='mt-1'>Vid inläsning av kalkylbladet så skapar vi en uppgift per rad, med hjälp av texten som står i den första kolumnen.</div>
                        <div> Inga andra kolumner kommer att läsas in.</div></>}
                    <SimpleXlsxFileInput onChange={onXlsxFileInputChange} />
                </div>}
                {data.map((value, index) => (
                    <div key={index}>
                        <HandleFillGaps key={index} state={value} onChange={newValue => handleChange(index, newValue)} />
                    </div>
                ))}
                <br />
                {data.length > 0 && <div>
                    <h3>{dictionary.Preview}</h3>
                    {data.map((value, index) => (value.options?.length > 0 &&
                        <div className={`${styles.taskBox} col-md-4`}>
                            <Task key={index} data={value} onCompletion={(taskDef, score) => alert(score > 0 ? dictionary.Correct : dictionary.Incorrect)} />
                            <br />
                        </div>
                    ))}
                </div>}</>}
        </div>
    )
}

export default CreateFillGap;
