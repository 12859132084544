import React from 'react'; 
import { IconCheckboxSelected, IconCheckboxUnselected } from 'ux_components/icons';

export type CheckboxProps = {
    value: boolean, 
    onClick: () => void, 
    label?: string, 
};

export const Checkbox = (props: CheckboxProps & {children?: React.ReactNode}) => {
    // TODO: Create without gap, and span around label that use left-margin
    return (<div onClick={props.onClick}>
        {props.value ? <IconCheckboxSelected /> : <IconCheckboxUnselected /> } {props.label}{props.children}
    </div>);
}; 