import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HandleAllTasks from "./HandleAllTasks";
import ImportTasks from "./ImportTasks";
import HandleTaskFeedback from "./HandleTaskFeedback";

const tabs = ['None', 'All Tasks', 'ImportTasks', 'Task Feedback',] as const
export type Tabs = (typeof tabs)[number];
const isTab = (x: any): x is Tabs => tabs.includes(x);
const isTabType = (x: any, y: Tabs): x is Tabs => x === y;

const HandleTasks = () => {
    const [chosenTab, setChosenTab] = useState<Tabs>('None')
    const params = useParams();
    const { subtab } = params;

    const GetTab = () => {
        let tab = <div style={{textAlign: 'center'}}>Select a tab</div>
        chosenTab !== 'None' ? window.history.pushState({ tab: chosenTab }, `Admin/Tasks/${chosenTab}`, `/Admin/Tasks/${encodeURIComponent(chosenTab)}`)
            : window.history.pushState({ tab: chosenTab }, `Admin Tasks`, `/Admin/Tasks`)
        switch (chosenTab) {
            case 'All Tasks':
                tab = <HandleAllTasks />
                break;
            case 'ImportTasks':
                tab = <ImportTasks />
                break;
            case 'Task Feedback':
                tab = <HandleTaskFeedback />
                break;
            case 'None':
            default:
                <div>Select a tab</div>
                break;
        }
        return tab;
    }

    useEffect(() => {
        if (subtab) {
            setChosenTab(isTab(subtab) ? subtab : 'None')
        }
    }, [subtab])

    return <div>
        <div className="text-center mb-3">
            {tabs.map((t, index) => (
                !isTabType(t, 'None') && <span key={index} className="me-5" style={{ cursor: 'pointer', textDecoration: 'underline', marginRight: '10px' }}
                    onClick={() => setChosenTab(t)}>{t}</span>
            ))}
        </div>
        <GetTab />
    </div>
}

export default HandleTasks;