import { useGameGlobals } from 'App';
import React, { useEffect } from 'react';
import imgChildrenSuccessVideoGame from './children-success-video-game.jpg';
import styles from './Home.module.css';
import { PopupButtonComponent } from 'components/CalendlyIntegration';

function Home() {
    const gameGlobals = useGameGlobals()

    useEffect(() => {
        if (gameGlobals.getAreaNodes)
            gameGlobals.getAreaNodes(false)
    })

    return (
        <div className={`${styles.home} row align-items-end`} id="pageroot-home">
            <h1>Välkommen till Elevert!</h1>
            <img src={imgChildrenSuccessVideoGame} className="col-12 offset-md-2 col-md-8" alt='Happy children' />
        </div>
    );
}

export default Home;
