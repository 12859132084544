import { FreeTextTaskDefinition } from '@task';
import React, { useRef, useState } from 'react'
import dictionary from 'translations/dictionary.sv.json';
import AddNewFillGapSentence from '../FillGap/AddNewFillGapSentence';
import AddAlternatives from './AddAlternatives';
import ChooseGaps from './ChooseGaps';

export type CreateFreeTextTaskProps2 = {
    state: (FreeTextTaskDefinition & { id: string });
    onChange: (state: (FreeTextTaskDefinition & { id: string })) => void;
    setShowSentence: (showSentence: boolean) => void;
}

function CreateFreeTextTask2(props: CreateFreeTextTaskProps2) {
    const searchInput = useRef<HTMLInputElement>(null);
    const [words, setWords] = useState<string[]>(props.state.sentence.split(' ').filter(s => s.trim() !== '').map(w => w.replaceAll('§§', '')));
    const [gaps, setGaps] = useState<boolean[]>(props.state.sentence.split(' ').map(s => s.startsWith('§§') && s.endsWith('§§')));
    const [caseSensetive, setCaseSensetive] = useState(false)


    const handleSentence = (sentence: string) => {
        const sentenceArray = sentence.split(' ').filter(s => s.trim() !== '')
        setWords(sentenceArray)
        setGaps(sentenceArray.map(s => false))
        props.setShowSentence(true);
    }

    const handleGaps = (gaps: boolean[]) => {
        const test2 = words.map((w, index) => gaps[index] ? `§§${w}§§` : w).join(' ')
        setGaps(gaps)
        props.onChange({ ...props.state, sentence: test2 })
    }
    const addAlternatives = (alternativesIndex: number, alternatives: string) => {
        let newWord = `§§${words[alternativesIndex]}§§`
        if (alternatives.trim() !== '') {
            let formattedAlts = alternatives.trim().split(' ').join('&&').split(',').join('&&')
            while (formattedAlts.endsWith('&') && formattedAlts.length > 2) {
                formattedAlts = formattedAlts.substring(0, formattedAlts.length - 2)
            }
            newWord = `§§${words[alternativesIndex]}&&${formattedAlts}§§`
        }

        const updatedWords = words.map((w, index) => index === alternativesIndex ? newWord : w).join(' ')
        props.onChange({ ...props.state, sentence: updatedWords })
    }

    const handleCaseSensetive = (newValue: boolean) => {
        props.onChange({ ...props.state, caseSensetive: newValue })
        setCaseSensetive(newValue)
    }

    return (<>
        <h4>{dictionary.WriteInTheCorrectSentenceBelowHowYouWantTheStudentToWriteIt}</h4>
        {words.length === 0 && <div><b>{dictionary.StepOneOfTwo}</b>: Skriv in den korrekta meningen nedan och tryck på retur.</div>}
        {words.length > 0 && <div><b>{dictionary.StepTwoOfTwo}</b>: Markera de ord du vill att spelaren ska skriva in själv.</div>}
        <div className='col-12 col-md-12 mb-3'>
            {words.length === 0 ?
                <AddNewFillGapSentence onSubmit={handleSentence} />
                : words && <ChooseGaps words={words} gaps={gaps} setGaps={handleGaps} />
            }
            {gaps.some(g => g) && <>
                <div className='mt-2 row'>
                    <b>{dictionary.Alternatives}</b><br />
                    {/* <p>If you want to allow alternative words to be allowed, capitalization or synonyms for example, type them on the equivalant row.</p> */}
                    <p>{dictionary.IfYouWantThePlayerToBeAbleToAddSynonymsEnterThemBelow}</p>
                    <div className='row'>
                        <AddAlternatives gaps={gaps} words={words} handleAlternatives={addAlternatives} className={'row col-8'} />
                        <div className='col-12 col-md-3 mt-2'>
                            <input id="caseSensetiveCheckbox" type="checkbox" className='me-3' onChange={() => handleCaseSensetive(!caseSensetive)} checked={caseSensetive} />
                            <label htmlFor='caseSensetiveCheckbox'>{dictionary.CaseSensitive}</label>
                        </div>
                    </div>
                </div>
            </>}

        </div>

    </>)
}

export default CreateFreeTextTask2;