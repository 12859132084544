import React, { ReactNode } from 'react'

import styles from './../box.module.scss'


const InputBox = (props: {
    className?: string,
    children: ReactNode,
    isOpen: boolean,
    header: ReactNode,
    toggleOpen: ((value: boolean) => void)
}) => {

    return (
        <div className={styles.boxcontainer + ' ' + (props.isOpen ? styles.selected : styles.notSelected) + ' ' + props.className + ' col'} >
            <div onClick={() => props.toggleOpen(!props.isOpen)} className="row">
                <div className='col'>{props.header}</div>
            </div>
            {props.isOpen && <div className=''>{props.children}</div>}
        </div>
    )
}

export default InputBox