import React, { ChangeEventHandler, useCallback, useMemo } from 'react';
import styles from './Select.module.scss';

export type SelectOption<T> = {
    label: string,
    value: T
};

export type SelectProps<T> = {
    keyPrefix: string,
    options: SelectOption<T>[],
    icon?: any,
    extractUniqueKey: (value: T) => string,
    onChange: (value: T | undefined) => void,
    selectedValue?: T,
    placeholder?: string,
    labelFor?: string
};

function SimpleSelect<T>(props: SelectProps<T>) {

    const options = useMemo(() => props.options.map(option => ({ ...option, key: props.extractUniqueKey(option.value) })), [props.options]);
    const selected = useMemo(() => props.selectedValue != null ? props.extractUniqueKey(props.selectedValue) : undefined, [props.selectedValue]);

    // TODO: This whole component is nothing but a temporary Q&D, with the purpose of not having HTML <select> used in the code base - 
    // implement a proper, styleable, searchable, groupable, etc component - considering performance!
    const onChange: ChangeEventHandler<HTMLSelectElement> = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        const selectedId = event.target.value;
        if (selectedId != null) {
            props.onChange(options.find(o =>  o.key.toString() === selectedId.toString())?.value);
        }
    }, [props.onChange]);

    return (
        <div className={styles.selectContainer + ' mb-2'}>
            <select className='col-12' name={`${props.keyPrefix}-select`} onChange={onChange} value={selected ?? props.placeholder}>
                {props.placeholder && <option value={props.placeholder} >{props.placeholder}</option>}
                {
                    options.map(option => <option key={`${props.keyPrefix}-option-${option.key}`} value={option.key} >{option.label}</option>)
                }
            </select>
        </div>
    );
}

export default SimpleSelect;
