import { TaskActivity, TaskAnswerAssessment, TaskAnswerDataFreetext, TaskAnswerDataGap, TaskAnswerDataMultichoice } from '@viewmodel/reports';
import dayjs from 'dayjs';
import dictionary from 'translations/dictionary.sv.json';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { reportService } from 'services/reportService';
import { assertNever } from 'utils/typescriptTricks';
import { Checkbox } from 'ux_components/checkbox';
import { IconAbandoned, IconCorrect, IconIncorrect } from 'ux_components/icons';
import SimpleSelect, { SelectOption } from 'ux_components/select';
import { ReportFilter } from '.';
import styles from './TaskActivity.module.scss';
import { WriteSentence } from '@task/TaskUtils';
import { PagesRow } from 'pages/Admin/HandleTasks/HandleAllTasks';

type AssessmentFilterType = Record<TaskActivity['metaData']['assessment'], boolean>;
type SubjectAreaFilterType = TaskActivity['metaData']['areaNodeId'];

const assesmentLabels: { [key in keyof AssessmentFilterType]: string } = {
    abandoned: dictionary.AbandonedTasks,
    correct: dictionary.CorrectAnswers,
    incorrect: dictionary.IncorrectAnswers,
    partial: dictionary.PartiallyCorrectAnswers
};

const extractSubjectAreaFilterOptions = (data: TaskActivity[]) => {
    const areas = data.reduce<{ [key: string]: string }>((acc, curr) => {
        if (!(curr.metaData.areaNodeId in acc)) {
            acc[curr.metaData.areaNodeId] = curr.metaData.areaNodeId;
        }
        return acc;
    }, {});
    return Object.entries(areas).map(([value, label]) => ({ label, value }));
};

export default function TaskActivityReport(props: ReportFilter) {
    const [taskActivity, setTaskActivity] = useState<TaskActivity[]>([]);
    const [displayTaskActivities, setDisplayTasksActivities] = useState<TaskActivity[]>([]);
    const [assessmentFilter, setAssessmentFilter] = useState<AssessmentFilterType>({ abandoned: true, correct: true, incorrect: true, partial: true });
    const [subjectAreaFilter, setSubjectAreaFilter] = useState<SubjectAreaFilterType>();
    const [lastSeen, setLastSeen] = useState<Date>();
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const pageSize = 25

    const subjectAreaFilterOptions = useMemo<SelectOption<SubjectAreaFilterType>[]>(() => extractSubjectAreaFilterOptions(taskActivity), [taskActivity]);

    const filteredTaskActivity = useMemo(() => {
        return taskActivity.filter(d => !assessmentFilter || assessmentFilter[d.metaData.assessment]).filter(d => !subjectAreaFilter || d.metaData.areaNodeId === subjectAreaFilter);
    }, [taskActivity, assessmentFilter, subjectAreaFilter]);

    const toggleAssessment = useCallback((assessment: keyof AssessmentFilterType) => {
        const change: AssessmentFilterType = { ...assessmentFilter };
        change[assessment] = !assessmentFilter[assessment];
        setAssessmentFilter(change)
    }, [assessmentFilter, setAssessmentFilter])

    const getStudentName = useCallback((studentId: string) => {
        if (props.selectedStudent) return undefined; // Only one; save the space!
        return props.selectedClass?.students.find(s => s.id === studentId)?.shortName;
    }, [props.selectedClass, props.selectedStudent]);

    useEffect(() => { // TODO: Save stopper function, implement paging, use stopper function
        let running = true;
        setLoading(true);
        reportService.getTaskActivity(props, { pageSize: pageSize * 3, lastSeen: undefined }).then(activities => {
            if (running) {
                setTaskActivity(activities);
                console.log('activities', activities);
                setLastSeen(activities[activities.length - 1]?.metaData.shown);
            }
        }).finally(() => setLoading(false));
        return () => { running = false };
    }, [props]);

    function handlePageSwitch(currentPage: number, pageSize: number, length: number) {
        if (currentPage * pageSize >= length - (pageSize * 2)) {
            reportService.getTaskActivity(props, { pageSize: pageSize * 3, lastSeen: lastSeen ? lastSeen : undefined }).then(activities => {
                setTaskActivity([...taskActivity, ...activities]);
                setLastSeen(activities[activities.length - 1]?.metaData.shown);
            });
        }
    }

    const getPages = (currentPage: number,) => {
        const pages = [];
        for (let i = 0; i < Math.ceil(filteredTaskActivity.length / pageSize); i++) {
            if (i === currentPage) {
                pages.push(<span style={{ textDecoration: 'underline', cursor: 'default' }} >{i + 1}</span>)
            }
            else pages.push(<span style={{ cursor: 'pointer' }} onClick={() => handleChangePage(i)}>{i + 1}</span>);
        }
        return pages;
    }

    const handleChangePage = (page: number) => {
        if (page > currentPage) handlePageSwitch(page, pageSize, filteredTaskActivity.length)
        setCurrentPage(page)
    }

    return (
        <>
            <div>
                <SimpleSelect
                    onChange={newValue => setSubjectAreaFilter(newValue)}
                    options={subjectAreaFilterOptions}
                    keyPrefix="report-taskactivity-area-filter"
                    placeholder={dictionary.ShowAllSubjectAreas}
                    extractUniqueKey={value => value || '--subject-area'}
                ></SimpleSelect>
                <div className={styles.assesmentFilter}>
                    <Checkbox value={assessmentFilter.correct} onClick={() => toggleAssessment('correct')}>{assesmentLabels.correct}</Checkbox>
                    <Checkbox value={assessmentFilter.incorrect} onClick={() => toggleAssessment('incorrect')}>{assesmentLabels.incorrect}</Checkbox>
                    {/* <Checkbox value={assessmentFilter.partial} onClick={() => toggleAssessment('partial')}>{assesmentLabels.partial}</Checkbox> */}
                    {/* <Checkbox value={assessmentFilter.abandoned} onClick={() => toggleAssessment('abandoned')}>{assesmentLabels.abandoned}</Checkbox> */}
                </div>
            </div>
            <div className={styles.reportTaskActivity}>
                {!loading ?
                    filteredTaskActivity.length > 0 ?
                        filteredTaskActivity.slice(currentPage * pageSize, filteredTaskActivity.length > ((currentPage * pageSize) + pageSize) ? ((currentPage * pageSize) + pageSize) : filteredTaskActivity.length).map((activity, index) => <><TaskAnswerPresentation key={index} activity={activity} getStudentName={getStudentName} /></>)
                        : <div style={{ marginTop: '5px' }}>Här kommer du att se elevernas svar när de börjat använda Elevert</div>
                    : <div>Loading...</div>
                }
            </div>
            <PagesRow {...{ currentPage, pageLength: pageSize, getPages, setCurrentPage: handleChangePage, tasks: filteredTaskActivity, setTasks: () => { } }} />
        </>
    );
}

function TaskAnswerPresentation(props: { getStudentName: (studentId: string) => string | undefined, activity: TaskActivity }) {
    const { getStudentName, activity } = props;
    const Wrapper = (wraps: { children: ReactNode }) => (<div key={activity.metaData.taskId + activity.metaData.shown} className={styles.taskActivity}>{wraps.children}</div>);

    const studentName = getStudentName(activity.studentId);
    const header = (<>
        {studentName && <><span className={styles.studentName}>{studentName}</span><br /></>}
        <span className={styles.shownTime}>{dayjs(activity.metaData.shown).format('YY-MM-DD HH:mm')}</span>: <span className={styles.taskHeadline}>{activity.metaData.taskHeadline}</span>
    </>);

    switch (activity.taskType) {
        case 'FillGaps':
            return (
                <Wrapper>
                    {header}
                    <QuestionRow><RenderQuestion.FillGaps answerData={activity.answerData} /></QuestionRow>
                    <AlternativesRow><RenderAlternatives.FillGaps answerData={activity.answerData} /></AlternativesRow>
                    <AnswerRow assessment={activity.metaData.assessment}><RenderAnswer.FillGaps answerData={activity.answerData} /></AnswerRow>
                </Wrapper>
            );
        case 'FreeText':
            return (
                <Wrapper>
                    {header}
                    <QuestionRow><RenderQuestion.FreeText answerData={activity.answerData} /></QuestionRow>
                    <NoAlternatives reason={dictionary.FreeTextQuestion} />
                    <AnswerRow assessment={activity.metaData.assessment}><RenderAnswer.FreeText answerData={activity.answerData} /></AnswerRow>
                </Wrapper>
            );
        case 'MultipleChoice':
            return (
                <Wrapper>
                    {header}
                    <QuestionRow><RenderQuestion.MultipleChoice answerData={activity.answerData} /></QuestionRow>
                    <AlternativesRow><RenderAlternatives.MultipleChoice answerData={activity.answerData} /></AlternativesRow>
                    <AnswerRow assessment={activity.metaData.assessment}><RenderAnswer.MultipleChoice answerData={activity.answerData} /></AnswerRow>
                </Wrapper>
            );
    }
    assertNever(activity);
}

function QuestionRow(props: { children: ReactNode }) {
    return (<>
        <br /><span className={`${styles.question} ${styles.label}`}>{dictionary.Question}:</span> <span className={`${styles.question} ${styles.value}`}>{props.children}</span>
    </>);
}

function AlternativesRow(props: { children: ReactNode }) {
    return (<>
        <br /><span className={`${styles.alternative} ${styles.label}`}>{dictionary.Alternatives}:</span> <span className={`${styles.alternative} ${styles.value}`}>{props.children}</span>
    </>);
}

function NoAlternatives(props: { reason: string }) {
    return (<><br /><span className={`${styles.alternative} ${styles.label}`}>{props.reason}</span></>);
}

function AnswerRow(props: { assessment: TaskAnswerAssessment } & { children: ReactNode }) {
    if (props.assessment === 'abandoned') {
        return (<>
            <br /><IconAbandoned colorCode={'#530'} /><span className={`${styles.answer} ${styles.label} ${assessmentToStyle(props.assessment)}`}>{dictionary.StudentDidNotAnswerTask}</span>
        </>);
    }

    // TODO: No explicit support for partial
    return (<>
        <br />{props.assessment === 'correct' ? <IconCorrect /> : <IconIncorrect />}<span className={`${styles.answer} ${styles.label} ${assessmentToStyle(props.assessment)}`} >{dictionary.Answer}:</span><span style={{ backgroundColor: props.assessment === 'correct' ? '#b3ea4b' : 'red' }} > <span className={`${styles.answer} ${styles.value}`}>{props.children}</span></span>
    </>);
}

function assessmentToStyle(assessment: TaskAnswerAssessment) {
    switch (assessment) {
        case 'abandoned':
            return styles.abandoned;
        case 'correct':
            return styles.correct;
        case 'incorrect':
            return styles.incorrect;
        case 'partial':
            return styles.partial;
        default:
            return assertNever(assessment);
    }
}

const RenderQuestion = {
    FillGaps: (props: { answerData: TaskAnswerDataGap }) => {
        return (<>{props.answerData.words.map((word, index) => {
            return <span key={index}>{index > 0 ? ' ' : ''}<span className={props.answerData.gaps[index] ? styles.questionChangeablePart : styles.questionGivenPart}><WriteSentence sentenceFragment={word} /></span></span>;
        })}</>);
    },
    FreeText: (props: { answerData: TaskAnswerDataFreetext }) => {
        const parts = props.answerData.question.split('§§');

        return (<>{parts.map((part, index) => {
            return <span key={index} className={!!(index % 2) ? styles.changeablePart : styles.givenPart}><WriteSentence sentenceFragment={part} /></span>
        })}</>);
    },
    MultipleChoice: (props: { answerData: TaskAnswerDataMultichoice }) => {
        return <span><WriteSentence sentenceFragment={props.answerData.question} /></span>;
    }
};

const RenderAlternatives = {
    FillGaps: (props: { answerData: TaskAnswerDataGap }) => {
        const alternatives = props.answerData.words.filter((word, index) => props.answerData.gaps[index]);
        console.log('props', props)
        console.log('alts', alternatives)
        return (<>{alternatives.map((word, index) => {
            return <span key={index}>{index > 0 ? ', ' : ''}<span className={`${styles.alternative} ${styles.givenPart}`}>{word}</span></span>;
        })}</>);
    },
    MultipleChoice: (props: { answerData: TaskAnswerDataMultichoice }) => {
        const alternatives = props.answerData.alternatives;

        return (<>{alternatives.map((alternative, index) => {
            return <>{index > 0 ? ', ' : ''}<span key={index} className={`${styles.alternative} ${alternative.isCorrect ? styles.correct : styles.incorrect}`}>{alternative.answer}</span></>;
        })}</>);
    }
};

const RenderAnswer = {
    FillGaps: (props: { answerData: TaskAnswerDataGap }) => {
        return (<>{props.answerData.answers.map((answer, index) => {
            return <span key={index}>{(index > 0) ? ', ' : ''}<span>{answer}</span></span>;
        })}</>);
    },
    FreeText: (props: { answerData: TaskAnswerDataFreetext }) => {
        const parts = props.answerData.question.split('§§');

        // return (<>{parts.map((part, index) => {
        //     return (
        //         !!(index % 2)
        //             ? <span key={index} className={styles.changeablePart}>{props.answerData?.answers ? props.answerData.answers[(index - 1) / 2] || '--' : '--'}</span>
        //             : <span key={index} className={styles.givenPart}>{part}</span>
        //     );
        // })}</>);
        return <span>{props.answerData.answers.join(',')}</span>
    },
    MultipleChoice: (props: { answerData: TaskAnswerDataMultichoice }) => {
        return (<>{props.answerData.selected.map((answer, index) => {
            return <span key={index}>{(index > 0) ? ', ' : ''}<span key={index}>{answer}</span></span>;
        })}</>);
    }
};

