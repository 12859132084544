
async function create(headline: string, institutionId: string, teacherId: string, syllabusId: string) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/groups`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ headline, institutionId, teacherId, main_syllabus_id: syllabusId })
    })

    return response.json()
}

async function getInviteCode(groupId: string) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/groups/${groupId}/code`, {
        credentials: 'include',
    })

    return response.json()
}

export const studentGroupService = {
    create,
    getInviteCode,
}

export default studentGroupService;