import React, { useState } from 'react';

import Task, { FreeTextTaskDefinition, TaskDefinition, taskIs } from '@task';
import dictionary from 'translations/dictionary.sv.json';
import styles from './../task-components.module.css';
import TaskTypeCard from 'pages/Assignment/_components/TaskTypeCard';
import goldenArrowAnimated from './../golden_arrow animated.png'
import CreateFreeTextTask2 from './CreateFreeTextTask2';
import SimpleXlsxFileInput, { SimpleXlsxFile } from 'components/SimpleXlsx/SimpleXlsxFileInput';
import ImportTasksFromExcel from '../Task Functions/ImportTasksFromExcel';

const EMPTY_TASK: (FreeTextTaskDefinition & { id: string }) = { id: '', type: 'FreeText', sentence: '', caseSensetive: false };

const createFreeTextSentence = (meta: TaskDefinition['meta']) => (sentence: string): (FreeTextTaskDefinition & { id: string }) => {
    return { id: '', type: 'FreeText', sentence, caseSensetive: false, meta, comment: '' };
}

export type CreateFreeTextChallengeProps = {
    onChange: (tasks: (FreeTextTaskDefinition & { id: string })[], meta: Required<TaskDefinition>['meta']) => void;
}

function CreateFreeTextChallenge({ onChange }: CreateFreeTextChallengeProps) {
    const [tasks, _setTasks] = useState<(FreeTextTaskDefinition & { id: string })[]>([]);
    const [readInstructions, setReadInstruction] = useState<boolean>(false)
    const [metaData, setMetaData] = useState<Required<TaskDefinition>['meta']>({ subarea: '', gradeYear: '', instructions: '', difficulty: 0 });
    const [showImport, setShowImport] = useState(false);
    const [showSentence, setShowSentence] = useState(false);

    const gradeYears = [{ difficulty: 100, text: 'Årskurs 1' }, { difficulty: 200, text: 'Årskurs 2' }, { difficulty: 300, text: 'Årskurs 3' }, { difficulty: 400, text: 'Årskurs 4' }, { difficulty: 500, text: 'Årskurs 5' }, { difficulty: 600, text: 'Årskurs 6' }, { difficulty: 700, text: 'Årskurs 7' }, { difficulty: 800, text: 'Årskurs 8' }, { difficulty: 900, text: 'Årskurs 9' }, { difficulty: 1000, text: 'Gymnasium' }]

    const setTasks: typeof _setTasks = (action) => {
        _setTasks((state) => {
            const newData = typeof action === 'function' ? action(state) : action;
            onChange(newData, { subarea: '' }); // TODO: move the state to the parent
            return newData;
        });
    }

    const setTask = (index: number, value: typeof tasks[typeof index]) => {
        const newData = tasks.slice();
        newData[index] = value;
        setTasks(newData);
    }

    const removeTask = (index: number) => {
        setTasks(tasks.filter((v, i) => i !== index));
    }

    const appendTasks = (tempTasks: TaskDefinition[]) => {
        console.log('tempTasks', tempTasks);
        console.log('tasks', tasks)
        if (tempTasks.length === 0) return;
        const validTasks: typeof tasks = tempTasks.filter(taskIs('FreeText'));
        if (tempTasks.length !== validTasks.length) {
            console.error('Not all tasks were of the correct type');
        }
        setTasks(state => state.concat(validTasks));
    }

    const onXlsxFileInputChange = (xlsxFiles: readonly SimpleXlsxFile[]) => {
        let tasks: (FreeTextTaskDefinition & { id: string })[] = []
        const createTask = createFreeTextSentence(metaData)
        console.log('createTask', xlsxFiles);
        tasks = (ImportTasksFromExcel(xlsxFiles, createTask))

        appendTasks(tasks)
    }

    const handleSetMetaData = (value: string) => {
        const meta = { ...metaData, gradeYear: gradeYears[+value].text, difficulty: gradeYears[+value].difficulty }
        setMetaData(meta)
        setTasks(state => state.map((v, i) => ({ ...v, meta })))
    }

    return (
        <div>
            <TaskTypeCard taskType="FreeText" taskTypeName={dictionary.FreeText} taskTypeDescription={dictionary.FreeTextDescription} onSelect={() => { }}></TaskTypeCard>
            <h4>{dictionary.CreateFreeTextAssignment}</h4>

            <span>
                {dictionary.CreateTaskDescriptions.CreateFreeTexts.LineOne}
                <ol>
                    <li>{dictionary.CreateTaskDescriptions.CreateFreeTexts.ListLineOne}</li>
                    <li>
                        {dictionary.CreateTaskDescriptions.CreateFreeTexts.ListLineTwo.PartOne}<br />
                        {dictionary.CreateTaskDescriptions.CreateFreeTexts.ListLineTwo.PartTwo}
                    </li>
                    <li>
                        {dictionary.CreateTaskDescriptions.CreateFreeTexts.ListLineThree.PartOne}<br />
                        {dictionary.CreateTaskDescriptions.CreateFreeTexts.ListLineThree.PartTwo}
                    </li>
                    <li>{dictionary.CreateTaskDescriptions.CreateFreeTexts.ListLineFour.PartOne}</li>
                </ol>
            </span>

            {!readInstructions && <div style={{ textAlign: "center" }}> <img src={goldenArrowAnimated} alt='Next arrow' style={{ minWidth: '5%', maxWidth: '5%' }} onClick={() => setReadInstruction(true)} /></div>}

            {readInstructions && <>
                <div>
                    <label>Välj lämplig årskurs</label><label style={{ color: 'red' }}>*</label> <br />
                    <select onChange={(event) => handleSetMetaData(event.target.value)}>
                        <option selected hidden>Årskurser</option>
                        {gradeYears.map((year, index) => <option value={index}>{year.text}</option>)}
                    </select>
                </div>
                {!showImport && <div className='col-12 col-md-6 mb-3'>
                    <div style={{ margin: '1em' }} className='col-12 col-md-6' >
                        {/* <CreateFreeTextTask state={task} onChange={newValue => setTask(index, newValue)} /> */}
                        <CreateFreeTextTask2 state={EMPTY_TASK} onChange={newValue => setTask(0, { ...newValue, meta: metaData })} setShowSentence={setShowSentence} />
                        {/* <button style={{ color: 'red' }} onClick={() => removeTask(index)}>{dictionary.Remove}</button> */}
                    </div>{!showSentence && <>
                        <label className='form-label'>{dictionary.Or}</label>
                        <button className='form-control btn btn-primary' onClick={() => setShowImport(true)}>{dictionary.UploadAnExcelFileWithSentences}</button>
                    </>}
                </div>}
                {showImport && <><br /><SimpleXlsxFileInput onChange={onXlsxFileInputChange} />
                    <div className='mt-1'>Vid inläsning av kalkylbladet så skapar vi en uppgift per rad, med hjälp av texten som står i den första kolumnen.</div>
                    <div> Inga andra kolumner kommer att läsas in.</div></>}

                {
                    tasks.map((task, index) => (
                        <div key={index} style={{ margin: '1em' }} className='col-12 col-md-6' >
                            <CreateFreeTextTask2 state={task} onChange={newValue => setTask(index, { ...newValue, meta: metaData })} setShowSentence={setShowSentence} />
                        </div>
                    ))
                }

                {tasks.length > 0 && <div>
                    <h5>{dictionary.Preview}</h5>
                    {tasks.map((task, index) => (
                        <div className={`${styles.taskBox} col-md-4`} key={index}>
                            <Task data={task} onCompletion={(taskDef, score) => alert(score > 0 ? dictionary.Correct : dictionary.Incorrect)} />
                            <br />
                        </div>
                    ))}
                </div>}
            </>}
        </div>
    )
}

export default CreateFreeTextChallenge;
