
async function putUserData(first_name: string, last_name: string, telephone_number: string) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/userdata`, {
        method: 'put',
        credentials: 'include',
        body: JSON.stringify({first_name, last_name, telephone_number})
    });
    return response.json();
} 

export const userDataService = {
    putUserData
}

export default userDataService