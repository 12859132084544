import React, { createContext, ReactNode, useEffect, useState } from 'react';
import styles from './App.module.css';

import { BrowserRouter } from 'react-router-dom'
import Header from './components/Header/Header'
import Routes from './Routes';
import { SignInProvider, useAuth, useTeacher } from 'providers/SignInProvider';
import Signup from 'pages/Signup/Signup';
import dictionary from './translations/dictionary.sv.json'
import 'bootstrap/dist/js/bootstrap.bundle';
import ErrorPage from 'pages/ErrorPage';
import sessionService from 'services/sessionService';
import { AreaNode } from '@viewmodel/areaNode';
import areaNodeService from 'services/areaNodeService';
import CookieConsent from 'components/cookie_consent/CookieConsent';
import { PopupButtonComponent } from 'components/CalendlyIntegration';

export interface GameGlobals {
  getAreaNodes: (forceNew: boolean) => Promise<AreaNode[]>
}

const GameGlobalsContext = createContext<Partial<GameGlobals>>({});
export const useGameGlobals = () => React.useContext(GameGlobalsContext)

const Error = (props: { children: ReactNode }) => {
  if (window.location.pathname.toLowerCase() === '/error'.toLowerCase())
    return <div className={styles.app}><ErrorPage /></div>
  else return <>{props.children}</>
}

// TODO: dedupe between admin and game
const RequireLogin = (props: { children: ReactNode }): JSX.Element | null => {
  const auth = useAuth();
  const teacher = useTeacher();
  useEffect(() => {
    sessionService.ping()
  }, [])


  if (!auth) return <>Laddar...</>;
  if (!auth.isLoggedIn) {
    return (
      <div>
        <a href={`${process.env.REACT_APP_API_BASE}/session/init/oidc/google/skola`}>{dictionary.LoginWithGoogle}</a> <br />
        <a href={`${process.env.REACT_APP_API_BASE}/session/init/oidc/microsoft/skola`}>{dictionary.LoginWithMicrosoft}</a>
        {/* <DemoLogin /> */}
      </div>
    )
  }
  if (teacher?.id === '0') {
    return <>
      <Header />
      <Signup />
    </>
  }
  return <>{props.children}</>;
}

/** Main entry point. */
function App() {
  const [areaNodes, setAreaNodes] = useState<AreaNode[]>()
  // useEffect(() => {
  //   if (!areaNodes)
  //     setAreaNodes([])
  //   areaNodeService.getAreaNodes().then(result => setAreaNodes(result))
  // }, [areaNodes])

  const getGameGlobals = () => {
    const getAreaNodes = async (forceNew: boolean) => {
      if (!forceNew && areaNodes) return areaNodes
      else {
        // setAreaNodes([])
        const tempAreaNodes = await areaNodeService.getAreaNodes()
        setAreaNodes(tempAreaNodes)
        return tempAreaNodes
      }
    }
    return {
      getAreaNodes
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_API_BASE?.includes('localhost') || process.env.REACT_APP_API_BASE?.includes('test'))
      document.body.classList.add('isDevelopment')
  })

  useEffect(() => {
    // make sure that the browser not supported message is removed
    document.querySelector('.browser_not_supported')?.remove()
  }, [])

  return (
    <div className={styles.app}>
      <Error>
        <SignInProvider>
          <BrowserRouter>
            <RequireLogin>
              <GameGlobalsContext.Provider value={getGameGlobals()}>
                <Header />
                <Routes />
                <PopupButtonComponent />
              </GameGlobalsContext.Provider>
            </RequireLogin>
          </BrowserRouter>
        </SignInProvider>
      </Error>
      <CookieConsent />
    </div>
  );
}

export default App;
