import React, { useState } from 'react';

import dictionary from 'translations/dictionary.sv.json';
import { TaskDefinition, TaskType } from '@task';
import { MetaDataInputProps } from 'components/TaskComponents/TaskMetaDataInput/MetaDataInput';
import AddAssignmentPart, { SetPartBuilder } from './_components/AddAssignmentPart';
import SubmitAssignment from './_components/SubmitAssignment';
import ShowAssignmentParts from './_components/ShowAssignmentParts';
import { useLocation } from 'react-router-dom';


type AssignmentPart = TaskDefinition[];

function CreateAssignment(props: { subarea: string }) {
    const { subarea } = props;

    const [metaInput, setMetaInput] = useState<MetaDataInputProps['metaData']>({
        gradeYear: '',
        instructions: '',
        difficulty: 0
    });

    const [partBuilders, setPartBuilders] = useState<JSX.Element[]>([]);
    const [parts, setParts] = useState<AssignmentPart[]>([]);
    const [lastTaskType, setLastTaskType] = useState<TaskType>()
    const location = useLocation()

    if (subarea == null) {
        // TODO: better error message/recovery
        return <>{dictionary.SomethingWentWrong}</>
    }

    const meta: Required<TaskDefinition>['meta'] = { ...metaInput, subarea };

    const handleExit = async (isValid: boolean, onSubmit: () => void) => {
        if (window.confirm('Om du avbryter så sparas inte uppgiften \nKlicka på OK för att avbryta utan att spara. \nKlicka på Cancel/Avbryt för att gå tillbaka till uppgiften.')) {
            setParts([])
            setPartBuilders([])
            window.history.back()
        }
    }

    const handlePartBuilder = (partBuilders: JSX.Element[]) => {
        window.history.pushState({ assignment: 3 }, partBuilders[0].type, location.pathname)
        // currently only allow one part at a time
        const lastPart = partBuilders.pop()
        setPartBuilders(lastPart ? [lastPart] : [])
        setParts([])
    }

    const afterSubmit = () => {
        if (lastTaskType) {
            setPartBuilders([])
            setParts([])
            SetPartBuilder(lastTaskType, setPartBuilders, setParts);
        } else {
            setPartBuilders([])
            setParts([])
        }
    }

    return (
        <div>
            {subarea && <h5>{dictionary.SubjectArea}: {subarea}</h5>}
            <AddAssignmentPart parts={parts} setParts={setParts} partBuilders={partBuilders} setPartBuilders={handlePartBuilder} setLastTaskType={setLastTaskType} setMeta={setMetaInput} />
            {partBuilders && window.history.state['assignment'] === 3 && <ShowAssignmentParts partBuilders={partBuilders} />}
            {parts.length > 0 && window.history.state['assignment'] === 3 && <div className='mt-3'><SubmitAssignment parts={parts} meta={meta} afterSubmit={afterSubmit} handleExit={handleExit} /></div>}
        </div>
    )
}

export default CreateAssignment;