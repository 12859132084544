import React from "react"
import dictionary from 'translations/dictionary.sv.json';
import styles from './EmailPreview.module.scss';

export type emailPreview = {
    toAddresses: string[],
    ccAddresses: string[],
    subject: string
    messageHtml: string,
    messageText: string,
}

export const CreateEmailPreview = (preview: any) => {
    const toAddresses = preview['Destination']['ToAddresses'] as string[]
    const ccAddresses = preview['Destination']['CcAddresses'] as string[]
    let previewEmailMessageHtml = preview['Message']['Body']['Html']['Data'] as string
    console.log('previewEmailMessageHtml', previewEmailMessageHtml)
    if(previewEmailMessageHtml !== "Lorem Ipsum") {
        previewEmailMessageHtml = previewEmailMessageHtml.replaceAll('?invite=', '?invite=<invite_code>')
    }
    let previewEmailMessageText = preview['Message']['Body']['Text']['Data'] as string
    console.log('preivewEmailMessageText', previewEmailMessageText)
    if(previewEmailMessageText !== "Lorem Ipsum") {
        previewEmailMessageText = previewEmailMessageText.replaceAll('?invite=', '?invite=<invite_code>')
    }
    const previewEmailSubject = preview['Message']['Subject']['Data'] as string
    const previewEmailBodyStart = previewEmailMessageHtml.indexOf('<body>')
    const previewEmailBodyEnd = previewEmailMessageHtml.indexOf('</body>')
    const emailPreview: emailPreview = {
        toAddresses: toAddresses,
        ccAddresses: ccAddresses,
        subject: previewEmailSubject,
        messageHtml: `${previewEmailMessageHtml?.substring(previewEmailBodyStart + 6, previewEmailBodyEnd)}`,
        messageText: previewEmailMessageText
    }
    return emailPreview;
}

const PreviewEmail = (props: { preview: emailPreview }) => {
    const { preview } = props;

    return <div className={`${styles.previewEmail}`}>
        <div className={styles.previewEmailBox} >
            {dictionary.To}: {preview.toAddresses.join(', ') ?? '' }
        </div>
        <div className={styles.previewEmailBox} >
            {dictionary.CC}: {preview.ccAddresses.join(', ') ?? ''}
        </div>
        <div className={styles.previewEmailBox} >
            {dictionary.Subject}: {preview.subject}
        </div>
        {/* <div className={`${styles.previewEmailBox}`}
            dangerouslySetInnerHTML={{ __html: preview.message }} ></div> */}
        <div className={`${styles.previewEmailBox}`} >
                {preview.messageText.split('\r\n').map((line,index) => line.trim() !== '' ? <div key={index}>{line}</div> : <br key={index} />)}
            </div>
    </div>
}

export default PreviewEmail
