import React from 'react'
import { MultipleChoiceTaskDefinition } from '@task';
import dictionary from 'translations/dictionary.sv.json';

type Alternative = MultipleChoiceTaskDefinition['alternatives'][number];

type AddNewMultipleChoiceProps = {
    state: (MultipleChoiceTaskDefinition & {id: string});
    onChange: (multiChoice: (MultipleChoiceTaskDefinition & {id: string})) => void;
}

const EMPTY_ALTERNATIVE: Alternative = { answer: '', isCorrect: false };

function AddNewMultipleChoice({ state, onChange }: AddNewMultipleChoiceProps) {
    const { question, alternatives } = state;

    const setQuestion = (newQuestion: typeof question) => onChange({ ...state, question: newQuestion });

    const updateAlternative = (index: number, patch: Partial<Alternative>) => {
        const newAlternatives: Alternative[] = alternatives.slice();
        const alternative = newAlternatives[index] || EMPTY_ALTERNATIVE;
        newAlternatives[index] = Object.assign({}, alternative, patch);
        if (newAlternatives[index].answer.trim() === '') {
            newAlternatives.splice(index, 1);
        }
        onChange({ ...state, alternatives: newAlternatives });
    }

    const updateAnswer = (index: number, answer: string) => updateAlternative(index, { answer });
    const updateIsCorrect = (index: number, isCorrect: boolean) => updateAlternative(index, { isCorrect });

    // const isValid = question.trim() !== '' && alternatives.length > 0 && alternatives.some(alt => alt.isCorrect);

    return (
        <div className='col-md-5'>
            <label className='form-label'>{dictionary.CreateMultipleChoiceQuestionBelow}:</label>
            <input className='form-control mb-3' type="text" value={state.question} onChange={event => setQuestion(event.target.value)} placeholder={dictionary.Question} />
            {alternatives.concat(EMPTY_ALTERNATIVE).map((alternative, index) =>
                <div key={index} className='input-group'>
                    <input type="text" className='form-control' value={alternative.answer} onChange={event => updateAnswer(index, event.target.value)} placeholder={dictionary.Alternative} />
                    <div className='input-group-text' style={{background: 'none', border: 'none'}}>
                        <input type="checkbox" className='form-input-check mt-0' id={'isCorrectCheckboxId'+index} onChange={event => updateIsCorrect(index, event.target.checked)} checked={alternative.isCorrect} />
                        <label className='ms-1' htmlFor={'isCorrectCheckboxId'+index}>{dictionary.Correct}</label>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddNewMultipleChoice;
