import React from "react";
import style from './ErrorPage.module.css'
import dictionary from 'translations/dictionary.sv.json'
import errorMonster from './server not found.png'

function ErrorPage() {

    return <div className={style.error}>
        <h4>{dictionary.ErrorPageHeadline}</h4>
        <img src={errorMonster} alt="errorMonster" className={style.errorMonster} />
        <div>{dictionary.ErrorPageText}</div>
        <br />
        <a href="/">{dictionary.BackToHomepage}</a>
    </div>
}

export default ErrorPage