import React, { useEffect, useState } from 'react';
import { ReportFilter } from '.';
import styles from './Reports.module.scss';
import dictionary from 'translations/dictionary.sv.json';
import { reportService } from 'services/reportService';
import { AreaActivity } from '@viewmodel/reports';
import { TimeFrameSelector, getFromDate } from './Components/TimeFrameSelector';
import PercentageFilters, { percentageFilters } from './Components/PercentFilter';

export type activityOfSubjectArea = {
    headline: string,
    nrOfCorrect: number,
    nrOfQuestions: number,
    subareas: activityOfSubjectArea[]
}

const doNotDisplayName = '***DO NOT DISPLAY***'

const AreaTableRow = (props: { area: AreaActivity, depth: number }) => {
    const { area, depth } = props
    const [showSubareas, setShowSubareas] = useState(false)
    const percentage = area.totalAnswers > 0 ? Math.round((area.correctAnswers / area.totalAnswers) * 100) : 0

    const handleDepth = (depth: number) => {
        let arrows = ''
        for (let i = 0; i < depth; i++) {
            arrows += '-'
        }
        return arrows
    }

    useEffect(() => {
        if (area.headline === doNotDisplayName) {
            setShowSubareas(true)
        } else setShowSubareas(false)
    }, [area.headline])

    const handleHeadline = (activity: AreaActivity) => {
        if (depth === 0) return <h5><b>{area.subareas.length > 0 ? '>' : ''} {handleDepth(depth)}<span>{activity.headline}</span></b></h5>
        else if (depth === 1) return <h5><i>{area.subareas.length > 0 ? '>' : ''} {handleDepth(depth)}<span>{activity.headline}</span></i></h5>
        else if (depth === 2) return <h5>{area.subareas.length > 0 ? '>' : ''} {handleDepth(depth)}<span>{activity.headline}</span></h5>
        else return <h6>{area.subareas.length > 0 ? '>' : ''} {handleDepth(depth)}<span>{activity.headline}</span></h6>
    }

    return <>
        {area.headline !== doNotDisplayName && <tr style={{ borderColor: depth > 0 ? 'orange' : 'black', borderWidth: depth > 0 ? 0 : '3px 0 0 0' }} onClick={() => setShowSubareas(!showSubareas)}>
            <th scope="row" ><span>{handleHeadline(area)}</span></th>
            <td>{area.correctAnswers}</td>
            <td>{area.totalAnswers}</td>
            {percentage > 80 && <td><span className={styles.aboveAverage}>{percentage}</span></td>}
            {percentage <= 80 && <>
                {percentage >= 60 && <td><span className={styles.average}>{percentage}</span></td>}
                {percentage < 60 && <td><span className={styles.belowAverage}>{percentage}</span></td>}
            </>}
        </tr>}
        {showSubareas && area.subareas
            && area.subareas?.map((sa, index) => <AreaTableRow key={index} area={sa} depth={depth + 1} />)
        }
    </>
}

export const AreaTable = (props: { areas: AreaActivity[] }) => {
    const [areas, setAreas] = useState<AreaActivity[]>([])

    useEffect(() => {
        setAreas(props.areas.slice())
    }, [props.areas])

    return <div>
        <table title='areaTable' className='table table-hover'>
            <thead>
                <tr>
                    <th title='subject' scope="col">{dictionary.Subject}</th>
                    <th title='correct' scope="col">{dictionary.Right}</th>
                    <th title='questions' scope="col">{dictionary.Questions}</th>
                    <th title='percentage' scope="col">%</th>
                </tr>
            </thead>
            <tbody>
                {areas.map((a, index) =>
                    <AreaTableRow key={index} area={a} depth={0} />
                )}
            </tbody>
        </table>
    </div>
}

const filterAreaActivities = (activities: AreaActivity[], percentageFilters: percentageFilters) => {
    const tempActivities = activities.filter(a => a.totalAnswers > 0).slice()
    // return activities.map(a => filterAreaActivity(a)).filter(a => a !== undefined) as AreaActivity[]
    const activitiesToDisplay: AreaActivity[] = []
    tempActivities.forEach(a => {
        const percentage = a.totalAnswers > 0 ? Math.round((a.correctAnswers / a.totalAnswers) * 100) : 0;
        const result = (a.totalAnswers !== -1) && (
            (percentage <= 25 && percentageFilters.low)
            || (percentage > 25 && percentage <= 50 && percentageFilters.belowAverage)
            || (percentage > 50 && percentage <= 75 && percentageFilters.average)
            || (percentage > 75 && percentageFilters.aboveAverage))

        const subs = filterAreaActivities(a.subareas, percentageFilters)
        if (result) {
            activitiesToDisplay.push({ ...a, subareas: subs })
        }
        else activitiesToDisplay.push({ studentId: '-1', headline: doNotDisplayName, correctAnswers: -1, totalAnswers: -1, subareas: subs })
    })
    return activitiesToDisplay
}

function AreaActivityReport(props: ReportFilter) {
    const [percentageFilters, setPercentageFilters] = useState<percentageFilters>({ low: true, belowAverage: true, average: true, aboveAverage: true })
    const [areaActivity, setAreaActivity] = useState<AreaActivity[] | undefined>(undefined)
    const [currentTimeFrame, setCurrentTimeFrame] = useState<Date>(getFromDate('Week'))
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (currentTimeFrame) {
            setLoading(true)
            let running = true
            reportService.getAreaActivity(props, { to: new Date(), from: currentTimeFrame }).then(activities => {
                if (running) {
                    setAreaActivity(activities)
                }
            }).finally(() => setLoading(false));
            return () => { running = false }
        }
    }, [props, currentTimeFrame])

    return (<>
        <h2>{dictionary.ListOfCoveredAreasForSelectedStudent}</h2>
        <PercentageFilters {...{ percentageFilters, setPercentageFilters }} />
        <TimeFrameSelector {...{ setCurrentTimeFrame: (value: Date) => setCurrentTimeFrame(value) }} />
        {!loading ?
            areaActivity ? <> {areaActivity.length > 0
                ? <AreaTable areas={filterAreaActivities(areaActivity, percentageFilters)} />
                : <div>{dictionary.NoActivities}</div>}
            </> : <div>{dictionary.GettingActivities}</div>
            : <div>Loading...</div>}
    </>);
}

export default AreaActivityReport;
