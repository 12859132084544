import React from "react"

function ChooseGaps(props: { words: string[], gaps: boolean[], setGaps: (gaps: boolean[]) => void }) {
    const toggleGap = (indexToToggle: number) => {
        const tempGaps = props.gaps.slice().map((gap, index) => index === indexToToggle ? !gap : gap )
        props.setGaps(tempGaps)
    }

    return (
        <>{props.words.map((word, index) =>
            <input type="button" key={index}
                className="btn btn-primary"
                value={word}
                onClick={() => toggleGap(index)}
                style={{ color: "white", backgroundColor: props.gaps[index] ? "black" : "dimgray", margin: "0px 5px" }}
            />
        )}</>
    )
}

export default ChooseGaps;