import React, { ReactNode } from 'react'

import styles from './../box.module.scss'

const SubmitBox = (
    props: { isActive: boolean, children: ReactNode, onClick: () => void }) => {
    return (
        <button className={styles.boxcontainer + ' ' + (props.isActive ? 'btn-primary' : 'btn-dark') + ' p-1 mt-3 col-12'} disabled={!props.isActive} onClick={props.onClick}>
            {props.children}
        </button>
    )
}

export default SubmitBox
