import React, { useState } from 'react'

import dictionary from 'translations/dictionary.sv.json';

type AddNewFillGapProps = {
    onSubmit: (sentence: string) => void;
}

function AddNewFillGapSentence({ onSubmit }: AddNewFillGapProps) {
    const [sentence, setSentence] = useState('');

    const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== 'Enter') return;
        event.preventDefault();
        handleSentence()
    }

    const handleSentence = () => {
        onSubmit(sentence.trim());
        setSentence('');
    }

    return <div>
        <input className='form-control mb-1' type="text" name="sentence" value={sentence}
            onChange={event => setSentence(event.target.value)}
            onKeyDown={handleEnter}
        />
        {sentence.trim() !== '' && <button className="btn btn-primary" onClick={handleSentence}>{dictionary.Next}</button>}
    </div>
}

export default AddNewFillGapSentence;
