import { TaskType } from "@viewmodel/tasks";
import React from "react";
import styles from './TaskTypeCard.module.css'
import fritextPng from './fritext.png'
import luckmeningPng from './luckmening.png'
import flervalPng from './flerval.png'

export type TaskTypeCardProps = {
    taskType: TaskType, 
    taskTypeName: string, 
    taskTypeDescription: string, 
    onSelect: (type: TaskType) => void
}

const TaskTypeCard = (props: TaskTypeCardProps) => {
    const { taskType, taskTypeName, taskTypeDescription, onSelect } = props;

    return <div onClick={() => onSelect(taskType)} className={`${styles.taskCard} col col-md-12`}>
        <h5>{taskTypeName}</h5>
        {taskType === "FillGaps" && <img src={luckmeningPng} alt="example" />}
        {taskType === "FreeText" && <img src={fritextPng} alt="example" />}
        {taskType === "MultipleChoice" && <img src={flervalPng} alt="example" />}
        <p dangerouslySetInnerHTML={{ __html: taskTypeDescription }}></p>
    </div>

}

export default TaskTypeCard