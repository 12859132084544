import React from 'react';
import { Routes, Route } from 'react-router-dom'

import Home from './pages/Home/Home';
import Reports from './pages/Reports';
import { CreateHomework } from 'pages/Homework/CreateHomework';
import { Feedback } from 'pages/Feedback/Feedback';
import Assignment from 'pages/Assignment';
import Admin from 'pages/Admin';
import InviteStudents2 from 'pages/InviteStudents2';

function AppRoutes() {
  return (
    <main>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Assignment">
          <Route path="" element={<Assignment />} />
          <Route path=":subarea" element={<Assignment />} />
          <Route path=":subarea/:relevantWords" element={<Assignment />} />
        </Route>
        <Route path="/InviteStudents" element={<InviteStudents2 />} />
        <Route path="/Reports">
          <Route path="" element={<Reports />} />
          <Route path=":selectedReport/:filterState" element={<Reports />} />
        </Route>
        <Route path="/CreateHomework" element={<CreateHomework />}></Route>
        <Route path="/Feedback" element={<Feedback />}></Route>
        <Route path="/Admin" >
          <Route path="" element={<Admin />} />
          <Route path=":tab" element={<Admin />}>
            <Route path="" element={<Admin />} />
            <Route path=":subtab" element={<Admin />} />
          </Route>
        </Route>
      </Routes>
    </main>
  );
}

export default AppRoutes;