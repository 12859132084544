export type studentEvent = {
    id: string
    userId: string
    name: string
    description: string
    created: Date
    metadata: string
}

export type notification = {
    id: string
    studentId: string
    studentEvent: studentEvent
    created: Date
    handled?: Date
}

async function getByStudentId(studentId: string): Promise<notification[]> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/notifications/students/${studentId}`, {
        credentials: 'include',
    }).catch(err => {
        console.log('err', err)
        return undefined
    })
    return response?.json();
}

async function getByStudentIds(studentIds: string[]): Promise<notification[]> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/notifications/students?ids=${studentIds.join(',')}`, {
        credentials: 'include',
    }).catch(err => {
        console.log('err', err)
        return undefined
    })
    return response?.json();
}

async function sendFeedbackEmail(notificationId: string): Promise<notification> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/email/notification/${notificationId}/feedback`, {
        credentials: 'include',
        method: 'put'
    }).catch(err => {
        console.log('err', err)
        return undefined
    })
    return response?.json();
}

async function previewFeedbackEmail(notificationId: string): Promise<any> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/email/notification/${notificationId}/feedback/preview`, {
        credentials: 'include',
        method: 'put'
    }).catch(err => {
        console.log('err', err)
        return undefined
    })
    return response?.json();
}


export const NotificationsService = {
    getByStudentId,
    getByStudentIds,
    sendFeedbackEmail,
    previewFeedbackEmail
}

export default NotificationsService