import React from "react";

export type percentageFilters = {
    low: boolean,
    belowAverage: boolean,
    average: boolean,
    aboveAverage: boolean
}

const PercentageFilters = (props: { percentageFilters: percentageFilters, setPercentageFilters: (values: percentageFilters) => void }) => {
    const { percentageFilters, setPercentageFilters } = props
    return <>
        <div title='filterPercentage' className='row' style={{marginBottom: '8px'}}>
            <div className='col-6 col-md'><label className='me-2' htmlFor='low'>00-25%</label><input id='low' type='checkbox' onChange={() => setPercentageFilters({ ...percentageFilters, low: !percentageFilters.low })} checked={percentageFilters.low} /></div>
            <div className='col-6 col-md'><label className='me-2' htmlFor='belowAverage'>26-50%</label><input id='belowAverage' type='checkbox' onChange={() => setPercentageFilters({ ...percentageFilters, belowAverage: !percentageFilters.belowAverage })} checked={percentageFilters.belowAverage} /></div>
            <div className='col-6 col-md'><label className='me-2' htmlFor='average'>51-75%</label><input id='average' type='checkbox' onChange={() => setPercentageFilters({ ...percentageFilters, average: !percentageFilters.average })} checked={percentageFilters.average} /></div>
            <div className='col-6 col-md'><label className='me-2' htmlFor='aboveAverage'>76-100%</label><input id='aboveAverage' type='checkbox' onChange={() => setPercentageFilters({ ...percentageFilters, aboveAverage: !percentageFilters.aboveAverage })} checked={percentageFilters.aboveAverage} /></div>
        </div>
    </>
}

export default PercentageFilters;