import React, { useEffect, useState } from 'react';
import { ReportFilter } from '.';
import styles from './Reports.module.scss';
import dictionary from 'translations/dictionary.sv.json';
import { TimeFrameSelector, getFromDate } from './Components/TimeFrameSelector';
import PercentageFilters, { percentageFilters } from './Components/PercentFilter';
import { reportService } from 'services/reportService';
import { OverviewActivity } from '@viewmodel/reports';

const StudentRow = (props: { student: OverviewActivity, selectStudent: (student: string) => void }) => {
    const percentage = Math.round(props.student.correctAnswers / props.student.totalAnswers * 100)
    return <tr >
        <td onClick={() => props.selectStudent(props.student.studentId)} style={{ cursor: 'pointer' }}>{'> ' + props.student.studentName}</td>
        <td>{props.student.correctAnswers}</td>
        <td>{props.student.totalAnswers}</td>
        {percentage > 80 && <td><span className={styles.aboveAverage}>{percentage}</span></td>}
        {percentage <= 80 && <>
            {percentage >= 60 && <td><span className={styles.average}>{percentage}</span></td>}
            {percentage < 60 && <td><span className={styles.belowAverage}>{percentage}</span></td>}
        </>}
    </tr>
}

const ClassTable = (props: { students: OverviewActivity[], selectStudent: (student: string) => void }) => {
    const students = props.students.reduce((result: OverviewActivity[], item) => {
        const { studentId, studentName, correctAnswers, totalAnswers } = item;
        const existingItem = result.find((r) => r.studentId === studentId);

        if (existingItem) {
            existingItem.correctAnswers += correctAnswers;
            existingItem.totalAnswers += totalAnswers;
        } else {
            result.push({ studentId, studentName, correctAnswers, totalAnswers, areaNode: 'All' });
        }
        return result;
    }, []);

    return <div>
        <table title='classTable' className='table table-hover'>
            <thead>
                <tr>
                    <th title='student' scope="col">{dictionary.Student}</th>
                    <th title='correct' scope="col">{dictionary.Right}</th>
                    <th title='questions' scope="col">{dictionary.Questions}</th>
                    <th title='percentage' scope="col">%</th>
                </tr>
            </thead>
            <tbody>
                {students.map((student, index) => {

                    return <StudentRow key={index} student={student} selectStudent={props.selectStudent} />
                })}
            </tbody>
        </table>
    </div>
}

const filterPercentageActivities = (activities: OverviewActivity[], percentageFilters: percentageFilters) => {
    const tempActivities = activities.filter(a => a.totalAnswers > 0).slice()
    return tempActivities.filter(a => {
        const percentage = a.totalAnswers > 0 ? Math.round((a.correctAnswers / a.totalAnswers) * 100) : 0;
        return (a.totalAnswers !== -1) && (
            (percentage <= 25 && percentageFilters.low)
            || (percentage > 25 && percentage <= 50 && percentageFilters.belowAverage)
            || (percentage > 50 && percentage <= 75 && percentageFilters.average)
            || (percentage > 75 && percentageFilters.aboveAverage))

    })
}

const AreaActivitiesFilter = (props: { activities: OverviewActivity[], setSelectedArea: (value: string) => void }) => {
    const areas = props.activities.map(a => a.areaNode).filter((v, i, a) => a.indexOf(v) === i)

    return <select onChange={e => props.setSelectedArea(e.target.value)} style={{ marginBottom: '8px' }}>
        <option value=''>{dictionary.AllAreas}</option>
        {areas.map((area, index) => <option key={index} value={area}>{area}</option>)}
    </select>
}

function OverviewReport(props: { reportFilter: ReportFilter, selectStudent: (value: string) => void }) {
    const { reportFilter, selectStudent } = props;
    const [percentageFilters, setPercentageFilters] = useState<percentageFilters>({ low: true, belowAverage: true, average: true, aboveAverage: true })
    const [currentTimeFrame, setCurrentTimeFrame] = React.useState<Date>(getFromDate('Week'))
    const [selectedArea, setSelectedArea] = useState<string>('')
    const [classActivity, setClassActivity] = useState<OverviewActivity[] | undefined>(undefined)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (currentTimeFrame) {
            setLoading(true)
            let running = true;
            setClassActivity(undefined);
            if (reportFilter.selectedStudent) {
                props.selectStudent(reportFilter.selectedStudent.id)
            } else {
                reportService.getOverviewActivity(reportFilter, { to: new Date(), from: currentTimeFrame }).then(activities => {
                    if (running) {
                        activities.forEach(a =>
                            reportFilter.selectedClass?.students.forEach(s => {
                                if (s.id === a.studentId) {
                                    a.studentName = s.fullName !== '' ? s.fullName : s.email;
                                    return;
                                }
                            })
                        )
                        setClassActivity(activities);
                    }
                }).finally(() => setLoading(false));
            }
            return () => { running = false }
        }
    }, [reportFilter, currentTimeFrame, props])

    const filterActivites = (activities: OverviewActivity[]) => {
        const selectedAreaActivities = selectedArea === '' ? activities : activities.filter(a => a.areaNode === selectedArea)
        return filterPercentageActivities(selectedAreaActivities, percentageFilters)
    }

    return (<>
        <h2>{dictionary.OverviewForSelectedClassOrStudent}</h2>
        <TimeFrameSelector setCurrentTimeFrame={setCurrentTimeFrame} />
        <br />
        <AreaActivitiesFilter {...{ activities: classActivity ?? [] }} setSelectedArea={setSelectedArea} />
        <PercentageFilters {...{ percentageFilters, setPercentageFilters }} />
        {!loading ?
            classActivity ?
                classActivity.length > 0
                    ? <ClassTable students={filterActivites(classActivity)} selectStudent={selectStudent} />
                    : <div>{dictionary.NoActivities}</div>
                : <div>{dictionary.GettingActivities}</div>
            : <div>Loading...</div>
        }

    </>);
}

export default OverviewReport;
