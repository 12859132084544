import React, { useEffect } from "react";
import { PopupButton } from "react-calendly";

export const PopupButtonComponent = () => {
    const [rootElement, setRootElement] = React.useState<HTMLElement | null>(null);
    const pageSettings= {
        // backgroundColor: 'f6d635',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        // primaryColor: '00a2ff',
        // textColor: '4d5055'
      }
    useEffect(() => {
        setRootElement(document.querySelector("main"));
    }, []);
    return (<>
        {rootElement && <div style={{position: 'fixed', right: '20px', bottom: '95px'}}><PopupButton className="btn btn-primary" url="https://calendly.com/grammatik-med-elevert/30min?hide_gdpr_banner=1" rootElement={rootElement} text="boka introduktion" pageSettings={pageSettings}/></div>}
    </>);
};
