import { AreaNode } from "@viewmodel/areaNode";
import { useGameGlobals } from "App";
import ChooseSubarea from "components/ChooseSubarea/ChooseSubarea";
import React, { useEffect, useState } from "react"
import areaNodeService from "services/areaNodeService";

function HandleNodes() {

    const [nodes, setNodes] = useState<AreaNode[] | undefined>(undefined)
    const [nodesToDisplay, setNodesToDisplay] = useState<AreaNode[]>([])
    const [nodesToDelete, setNodesToDelete] = useState<AreaNode[]>([])
    const [nonInteractableNodes, setNonInteractableNodes] = useState<AreaNode[]>([])
    const [isDeleting, setIsDeleting] = useState<boolean>(false)

    const gameGlobals = useGameGlobals()

    const addNodeForDeletion = (node: AreaNode) => {
        if (node) {
            const tempDeleteNodes = nodesToDelete.slice()
            const existingNode = nodesToDelete.findIndex(n => n.id === node.id)
            if (existingNode === -1) {
                tempDeleteNodes.push(node)
                setNodesToDelete(tempDeleteNodes)
            } else {
                tempDeleteNodes.splice(existingNode, 1)
                setNodesToDelete(tempDeleteNodes)
            }
        }
    }

    const checkNode = (id: number) => {
        return nodesToDelete.some(n => n.id === id)
    }

    const deleteNodes = () => {
        setIsDeleting(true);
        areaNodeService.deleteNodes(nodesToDelete.map(n => n.id)).then(ids => {
            setNodesToDelete([])
            if (gameGlobals.getAreaNodes)
                gameGlobals.getAreaNodes(true).then(result => {
                    setNodes(result)
                    setNodesToDisplay(result)

                })
            // areaNodeService.getAreaNodes().then(newNodes => {
            //     setNodes(newNodes)
            //     setNodesToDisplay(newNodes)
            // })
            setIsDeleting(false);

        })
    }

    const checkIfDisabled = () => {
        return nodesToDelete.length === 0 || isDeleting
    }

    const preamble = <>
        <div>Handle nodes preamble</div>
    </>

    useEffect(() => {
        if (!nodes) {
            if (gameGlobals.getAreaNodes)
                gameGlobals.getAreaNodes(false).then(result => {
                    setNodes(result)
                    setNodesToDisplay(result)

                })
            // areaNodeService.getAreaNodes().then(newNodes => {
            //     setNodes(newNodes)
            //     setNodesToDisplay(newNodes)
            // })
        }
    }, [nodes, nodesToDisplay, nonInteractableNodes, gameGlobals])

    return <div>
        <div>
            Nodes to reject: {nodesToDelete.length > 0 ? nodesToDelete.map(n => <span onClick={() => addNodeForDeletion(n)}> {n.headline},</span>)
                : <span>No nodes selected</span>}
        </div>
        <button className="btn btn-primary" onClick={deleteNodes} disabled={checkIfDisabled()}>Reject Nodes</button> {isDeleting && <span>Deleting</span>}
        {
            nodesToDisplay.length > 0 &&
            <ChooseSubarea headline="Admin" preamble={preamble} nodes={nodesToDisplay} setNode={addNodeForDeletion} checkNode={checkNode} showMyNodesButton={false} uninteractNodes={nonInteractableNodes} ></ChooseSubarea>
        }    </div>
}


export default HandleNodes;