import React from 'react'; 
import { Check, CheckSquare, ChevronDown, Clock, Mail, Square, X } from "react-feather";
import { assertNever } from 'utils/typescriptTricks';
import styles from './Icons.module.scss'

export type IconProps = ({colorState?: 'default'|'greyscale'|'disabled'} | {colorCode: string}) & {size?: number};
export type IconElement = React.ReactElement<IconProps, (props: IconProps) => IconElement>;

function pickColor(defaultColor: string, props: IconProps) {
    if ('colorCode' in props) return props.colorCode || defaultColor;
    if (!props.colorState) return defaultColor;
    switch (props.colorState) {
        case 'default':
            return defaultColor;
        case 'disabled':
            return '#807030';
        case 'greyscale':
            return '#333';
    }
    assertNever(props.colorState); 
}

const featherOptions = (defaultColor: string, props: IconProps) => ({ 
    className: styles.featherIcon, 
    color: pickColor(defaultColor, props), 
    size: `${props.size || 1}em` 
});

export const Icon = {
    CheckboxSelected: (props: IconProps): IconElement => <CheckSquare {...featherOptions('#444030', props)} />,
    CheckboxUnselected: (props: IconProps): IconElement => <Square {...featherOptions('#444030', props)} />,
    Correct: (props: IconProps): IconElement => <Check {...featherOptions('#140', props)} />,
    Incorrect: (props: IconProps): IconElement => <X {...featherOptions('#720', props)} />,
    Abandoned: (props: IconProps): IconElement => <Clock {...featherOptions('#530', props)} />,
    ExpandDown: (props: IconProps): IconElement => <ChevronDown {...featherOptions('#530', props)} />,
    Mail: (props: IconProps): IconElement => <Mail {...featherOptions('#444030', props)} />,
}; 

export const IconCheckboxSelected = Icon.CheckboxSelected;
export const IconCheckboxUnselected = Icon.CheckboxUnselected;
export const IconCorrect = Icon.Correct;
export const IconIncorrect = Icon.Incorrect;
export const IconAbandoned = Icon.Abandoned;
export const IconExpandDown = Icon.ExpandDown;
export const IconMail = Icon.Mail;
