import React from 'react';
import { ReportFilter } from '.';
import styles from './Reports.module.scss';
import dictionary from 'translations/dictionary.sv.json';

function ReportDashboard(props: ReportFilter) {
    
    return (
        <h2>Dashboard</h2>
    );
}

export default ReportDashboard;
