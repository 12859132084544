import Task, { TaskDefinition, TaskType } from '@task'
import { AreaNode } from '@viewmodel/areaNode'
import { TaskStatus } from '@viewmodel/tasks'
import React, { useEffect, useState } from 'react'
import areaNodeService from 'services/areaNodeService'
import taskService from 'services/taskService'
import style from './../Admin.module.scss'

type Sort = 'rejected' | 'id' | 'type'
type SortDirection = 'none' | 'up' | 'down'

type HandleTaskType = {
    props: { data: TaskDefinition }
    display: boolean
    initialStatus: TaskStatus
    status: TaskStatus
    isSelected: boolean
    created_date: Date
}

type filters = {
    filterStatuses: { status: TaskStatus, show: boolean }[]
    filterTypes: { taskType: TaskType, show: boolean }[]
    filterNodes: { node: AreaNode, show: boolean }[]
}

const checkNode = (node: AreaNode, name: string, checkSubnodes: boolean, maxDepth?: number, currentDepth?: number): boolean => {
    if (node.headline === name) return true
    if (!checkSubnodes) return false
    let tempCurrentDepth = currentDepth ? currentDepth : 0
    if (maxDepth) {
        tempCurrentDepth++;
        if (maxDepth === tempCurrentDepth) return false
    }
    return node.subareas ? node.subareas.some(subnode => checkNode(subnode, name, checkSubnodes, maxDepth, tempCurrentDepth)) : false
}

const checkIfToShow = (
    t: HandleTaskType,
    filters: filters,
    showSubnodes: boolean
) => {
    const filterNodes = filters.filterNodes.filter(fn => fn.show)
    return filters.filterStatuses.some(fs => fs.status === t.status && fs.show)
        && filters.filterTypes.some(fs => fs.taskType === t.props.data.type && fs.show)
        && (filterNodes.length === 0 || filterNodes.some(fn => t.props.data.meta?.subarea ? checkNode(fn.node, t.props.data.meta?.subarea, showSubnodes) : false))
}

const handleDelete = (taskId: string) => {
    taskService.deleteTasks([taskId]).then(result => window.location.reload())
}

const DisplayPage = (
    props: {
        tasks: HandleTaskType[],
        setTasks: (tasks: HandleTaskType[]) => void,
        indexOffset: number,
        pageLength: number,
        showOnlyChanged: boolean,
        filters: filters,
        setSelect: (t: HandleTaskType, index: number, filters: filters) => void,
        currentTodo: React.MutableRefObject<any>,
        changeDisplay: (index: number, filters: filters) => void,
        handleTask: (taskDef: TaskDefinition) => void,
        handleTask2: (taskDef: TaskDefinition) => void,
        changeStatus: (index: number, status: TaskStatus, filters: filters) => void,
        showSubnodes: boolean
    }
) => {
    const { tasks, indexOffset, pageLength, showOnlyChanged, filters, setSelect, currentTodo, changeDisplay, handleTask, handleTask2, changeStatus } = props
    return <>{tasks
        .filter(t => (showOnlyChanged && t.initialStatus !== t.status)
            || (!showOnlyChanged && checkIfToShow(t, filters, props.showSubnodes)))
        .slice(indexOffset, indexOffset + pageLength).map((t, i) => {
            const indexWithOffset = indexOffset + i
            return <div key={indexWithOffset}>
                <div onFocus={() => setSelect(t, indexWithOffset, filters)} tabIndex={indexWithOffset + 1} ref={t.isSelected ? currentTodo : null} className={'row ' + (t.isSelected ? style.taskSelected : style.taskNotSelected)}>
                    {t.status === 'rejected' && <>
                        <h5 className='col-2' onClick={() => changeDisplay(indexWithOffset, filters)}>{handleTask2(t.props.data)}: {t.isSelected && handleTask(t.props.data)}</h5>
                        <span className='col-3 col-md-1' onClick={() => changeStatus(indexWithOffset, 'draft', filters)}><button className='btn col-12'>{'-->'}</button></span>
                    </>}
                    {t.status === 'draft' && <>
                        <span className='col-3 offset-md-2 col-md-1' onClick={() => changeStatus(indexWithOffset, 'rejected', filters)}><button className='btn col-12' >{'<--'}</button></span>
                        <h5 className='col-5' onClick={() => changeDisplay(indexWithOffset, filters)}>{handleTask2(t.props.data)}: {handleTask(t.props.data)}</h5>
                        <span className='col-3 col-md-1' onClick={() => changeStatus(indexWithOffset, 'approved', filters)}><button className='btn col-12' >{'-->'}</button></span>
                    </>}
                    {t.status === 'approved' && <>
                        <span className='offset-5 col-3 offset-md-8 col-md-1' onClick={() => changeStatus(indexWithOffset, 'draft', filters)}><button className='btn col-12' >{'<--'}</button></span>
                        <h5 className='col-2' onClick={() => changeDisplay(indexWithOffset, filters)}>{handleTask2(t.props.data)}: {t.isSelected && handleTask(t.props.data)}</h5>
                    </>}
                </div>
                {/* {t.status === 'rejected' && <div className='col-4' style={{ display: t.display ? 'block' : 'none' }}>
                <Task {...t.props} />
            </div>} */}
                {<div className='offset-3 col-4' style={{ display: t.display ? 'block' : 'none' }}>
                    <Task {...t.props} onCompletion={() => { }} />
                    {t.initialStatus === 'rejected' && t.status === 'rejected' && <button className='btn btn-primary' onClick={() => handleDelete(t.props.data.id)}>Delete</button>}
                </div>}
                {/* {t.status === 'approved' && <div className='offset-8 col-4' style={{ display: t.display ? 'block' : 'none' }}>
                <Task {...t.props} />
            </div>} */}

            </div>
        }
        )}</>
}

function changePage<T>(currentPage: number, newPage: number, pageLength: number, setCurrentPage: (newPage: number) => void, tasks: T[], setTasks: (tasks: T[]) => void) {
    setCurrentPage(newPage)
    const tempTasks = tasks.slice()
    setTasks(tempTasks.map((t, index) => pageLength * currentPage === index ? { ...t, isSelected: true, } : { ...t, isSelected: false, display: false }))

}

export function PagesRow<T>(props: { currentPage: number, pageLength: number, getPages: (currentPage: number) => void, setCurrentPage: (newPage: number) => void, tasks: T[], setTasks: (tasks: T[]) => void }) {
    const { currentPage, pageLength, getPages, setCurrentPage, tasks, setTasks } = props
    return <div className='text-center'>
        <button className='btn btn-primary-outline' onClick={() => changePage(currentPage, currentPage - 1, pageLength, setCurrentPage, tasks, setTasks)} disabled={currentPage <= 0}>{'<--'}</button>
        {getPages(currentPage)}
        <button className='btn btn-primary-outline' onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage + 1 >= tasks.length / pageLength}>{'-->'}</button>
    </div>
}

const handlePages = (
    currentPage: number,
    setCurrentPage: (page: number) => void,
    tasks: HandleTaskType[],
    setTasks: (tasks: HandleTaskType[]) => void,
    showOnlyChanged: boolean,
    filters: filters,
    setSelect: (t: HandleTaskType, index: number, filters: filters) => void,
    currentTodo: React.MutableRefObject<any>,
    changeDisplay: (index: number, filters: filters) => void,
    handleTask: (taskDef: TaskDefinition) => void,
    handleTask2: (taskDef: TaskDefinition) => void,
    changeStatus: (index: number, status: TaskStatus, filters: filters) => void,
    getMoreTasks: () => void,
    sending: boolean,
    showSubnodes: boolean
) => {
    const filteredTasks = tasks.filter(t => checkIfToShow(t, filters, showSubnodes))
    const pageLength = 25
    const getPages = (currentPage: number) => {
        const nrOfPages = Math.ceil(filteredTasks.length / pageLength)
        let pages: JSX.Element[] = []
        let displayPages = nrOfPages
        let startNr = 0
        if (nrOfPages > 15) {
            displayPages = 15
            startNr = currentPage - 5
            if (currentPage < 5) {
                startNr = 0
            }
            else if (currentPage > 5) {
                displayPages--
                pages.push(<span style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(0)}>{1} </span>)
                if (currentPage > 6) {
                    pages.push(<span>... </span>)
                    displayPages--
                }
            }
            if (currentPage + 5 < nrOfPages) displayPages--
            if (currentPage + 6 < nrOfPages) displayPages--
            if (currentPage + 7 >= nrOfPages) startNr = nrOfPages - 13
        }
        for (let i = startNr; i < displayPages + startNr; i++) {
            let page = <span style={{ cursor: 'default' }}>{currentPage === i
                ? <span style={{ textDecoration: 'underline', cursor: 'default' }}>{i + 1}</span>
                : <span style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(i)}>{i + 1}</span>
            } </span>
            pages.push(page)
        }
        if (nrOfPages > 15) {
            if (currentPage + 6 < nrOfPages) pages.push(<span>...</span>)
            if (currentPage + 5 < nrOfPages) pages.push(<span style={{ cursor: 'pointer' }} onClick={() => setCurrentPage(nrOfPages - 1)}> {nrOfPages} </span>)
        }
        return <span>{pages}</span>
    }

    return (
        <>
            <PagesRow {...{ currentPage, pageLength, getPages, setCurrentPage, tasks: filteredTasks, setTasks }} />
            <DisplayPage {...{
                tasks: filteredTasks, setTasks, indexOffset: pageLength * currentPage, pageLength, showOnlyChanged, filters, setSelect, currentTodo,
                changeDisplay, handleTask, handleTask2, changeStatus, showSubnodes
            }} />
            <PagesRow {...{ currentPage, pageLength, getPages, setCurrentPage, tasks: filteredTasks, setTasks }} />
            <button className='btn btn-primary mt-3 col-3' onClick={() => getMoreTasks()} disabled={sending}>{sending ? 'Hämtar' : 'Hämta fler uppgifter'}</button>
        </>
    )
}

function HandleAllTasks() {
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [oldestDate, setOldestDate] = useState<Date | undefined>()
    const [tasks, setTasks] = useState<HandleTaskType[] | undefined>(undefined)
    const [lastSortType, setLastSortType] = useState<Sort>('rejected')
    const [sortDirection, setSortDirection] = useState<SortDirection>('down')
    const [filterTypes, setFilterTypes] = useState<{ taskType: TaskType, show: boolean }[]>([{ taskType: "FillGaps", show: true }, { taskType: "FreeText", show: true }, { taskType: "MultipleChoice", show: true },])
    const [filterStatuses, setFilterStatuses] = useState<{ status: TaskStatus, show: boolean }[]>([{ status: "draft", show: true }, { status: "approved", show: true }, { status: "rejected", show: true },])
    const [sending, setSending] = useState(false)
    const [showOnlyChanged, setShowOnlyChanged] = useState(false)
    const [filterNodes, setFilterNodes] = useState<{ node: AreaNode, show: boolean }[] | undefined>(undefined)
    const [showSubnodes, setShowSubnodes] = useState(true)

    const changeDisplay = (index: number) => {
        if (!tasks) return false;
        const tempTask = tasks.slice()
        tempTask.map(t => t.isSelected = false)
        tempTask[index].display = !tempTask[index].display
        tempTask[index].isSelected = true
        setTasks(tempTask)
    }

    const changeStatus = (index: number, status: TaskStatus, filters: filters) => {
        lastSortType === 'rejected' && setSortDirection('none')
        if (!tasks) return false;
        const tempTasks = tasks.slice()
        tempTasks.filter(t => checkIfToShow(t, filters, showSubnodes))[index].status = status
        setTasks(tempTasks)
    }

    const sortTaskList = (tasks: HandleTaskType[], sort: Sort) => {
        let tempSortDirection = sort !== lastSortType || sortDirection === 'none' ? 'down' : sortDirection === 'up' ? 'down' : 'up'
        let tempTasks = tasks.slice()
        switch (sort) {
            case 'rejected':
                tempTasks = tempTasks.sort((a, b) => +a.props.data.id - +b.props.data.id)
                tempTasks = tempSortDirection === 'down' ? tempTasks.sort((a, b) => { if (a.status > b.status) return 1; if (a.status < b.status) return -1; return 0 })
                    : tempTasks.sort((b, a) => { if (a.status > b.status) return 1; if (a.status < b.status) return -1; return 0 })
                break;
            case 'id':
                tempTasks = tempSortDirection === 'down' ? tempTasks.sort((a, b) => +a.props.data.id - +b.props.data.id)
                    : tempTasks.sort((b, a) => +a.props.data.id - +b.props.data.id)
                break;
            case 'type':
                tempTasks = tempTasks.sort((a, b) => +a.props.data.id - +b.props.data.id)
                tempTasks = tempSortDirection === 'down' ? tempTasks.sort((a, b) => { if (a.props.data.type > b.props.data.type) return 1; if (a.props.data.type < b.props.data.type) return -1; return 0 })
                    : tempTasks.sort((b, a) => { if (a.props.data.type > b.props.data.type) return 1; if (a.props.data.type < b.props.data.type) return -1; return 0 })
                break;
            default:
                break;
        }
        return tempTasks
    }

    const sortTasks = (tasks: HandleTaskType[], sort: Sort) => {
        setTasks(sortTaskList(tasks.slice(), sort))
        sort === lastSortType ? setSortDirection(sortDirection === 'down' ? 'up' : 'down') : setSortDirection('down')
        setLastSortType(sort)
    }

    const filterType = (type: TaskType) => {
        setFilterTypes(filterTypes.slice().map(ft => { ft.show = ft.taskType === type ? !ft.show : ft.show; return ft }))
    }

    const filterStatus = (status: TaskStatus) => {
        setFilterStatuses(filterStatuses.slice().map(ft => { ft.show = ft.status === status ? !ft.show : ft.show; return ft }))
    }

    const handleTask = (taskDef: TaskDefinition) => {
        switch (taskDef.type) {
            case 'FillGaps':
                return taskDef.words.join(' ')
            case 'FreeText':
                return taskDef.sentence
            case 'MultipleChoice':
                return taskDef.question
            default:
                break;
        }
    }

    useEffect(() => {
        if (!tasks) {
            try {
                taskService.getbyFilter({ date: oldestDate?.toString(), limit: 50, order_by: 'date' }).then(result => {
                    const tasks: HandleTaskType[] = result.map(r => ({ display: false, initialStatus: r.status ?? 'draft', status: r.status ?? 'draft', isSelected: false, created_date: r.created_date, props: { data: { ...r.data, id: r.id }, }, selectedForDeleting: false }))
                    tasks[0].isSelected = true
                    setTasks(tasks)
                    setOldestDate(tasks[tasks.length - 1].created_date)
                })
            } catch {
                setTasks([])
            }
        }
    }, [tasks])

    const getNodeAndSubnodes = (node: AreaNode) => {
        let nodes = [node]
        node.subareas?.forEach(sn => {
            nodes = nodes.concat(getNodeAndSubnodes(sn))
        })
        return nodes;
    }

    useEffect(() => {
        if (!filterNodes) {
            try {
                areaNodeService.getAreaNodes().then(result => {
                    const nodesAndSubNodes = result.flatMap(r => getNodeAndSubnodes(r))
                    setFilterNodes(nodesAndSubNodes.map(r => ({ node: r, show: false })))
                })
            } catch (error) {
                console.error('err', error);
                setFilterNodes([])
            }
        }
    }, [filterNodes])

    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.key !== 'Tab' && event.key !== 'Enter' && event.key !== 'ArrowUp' && event.key !== 'ArrowDown' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') return
            event.preventDefault()
            const tempTasks = tasks?.slice() ?? []
            const tempTasksFiltered = tasks?.slice().filter(t => checkIfToShow(t, { filterStatuses, filterTypes, filterNodes: [] }, showSubnodes)) ?? []
            const index = tempTasksFiltered.findIndex(t => t.isSelected === true)
            if (index === -1) {
                tempTasksFiltered.map(at => at.isSelected = false)
                tempTasksFiltered.map(at => at.display = false)
                tempTasksFiltered[0].isSelected = true
                tempTasksFiltered[0].display = true
            }
            else if (event.key === 'Enter') {
                tempTasksFiltered[index].display = !tempTasksFiltered[index].display
            }
            else if (event.key === 'ArrowRight') {
                if (tempTasksFiltered[index].status === 'rejected')
                    tempTasksFiltered[index].status = 'draft'
                else if (tempTasksFiltered[index].status === 'draft') {
                    tempTasksFiltered[index].display = false
                    tempTasksFiltered[index].status = 'approved'
                    tempTasksFiltered[index].isSelected = false
                    if (tempTasksFiltered.length !== index + 1) {
                        tempTasksFiltered[index + 1].isSelected = true
                        tempTasksFiltered[index + 1].display = true
                    }
                }
            }
            else if (event.key === 'ArrowLeft') {
                if (tempTasksFiltered[index].status === 'approved')
                    tempTasksFiltered[index].status = 'draft'
                else if (tempTasksFiltered[index].status === 'draft') {
                    tempTasksFiltered[index].display = false
                    tempTasksFiltered[index].status = 'rejected'
                    tempTasksFiltered[index].isSelected = false
                    if (tempTasksFiltered.length !== index + 1) {
                        tempTasksFiltered[index + 1].isSelected = true
                        tempTasksFiltered[index + 1].display = true
                    }

                }
            }
            else if (event.key === 'ArrowUp') {
                tempTasksFiltered[index].isSelected = false
                tempTasksFiltered[index].display = false
                if (index !== 0) {
                    tempTasksFiltered[index - 1].isSelected = true
                    tempTasksFiltered[index - 1].display = true
                }

                else {
                    tempTasksFiltered[tempTasksFiltered.length - 1].isSelected = true
                    tempTasksFiltered[tempTasksFiltered.length - 1].display = true
                }
            }
            else if (event.key === 'ArrowDown' || event.key === 'Tab') {
                tempTasksFiltered[index].isSelected = false
                tempTasksFiltered[index].display = false
                if (index !== tempTasksFiltered.length - 1) {
                    tempTasksFiltered[index + 1].isSelected = true
                    tempTasksFiltered[index + 1].display = true
                }
                else {
                    tempTasksFiltered[0].isSelected = true
                    tempTasksFiltered[0].display = true
                }
            }
            setTasks(tempTasks)
        }

        // event listener for click
        document.addEventListener('keydown', handleKeyDown);

        // removal of event listener on unmount
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [tasks, filterStatuses, filterTypes])

    const handleTask2 = (taskDef: TaskDefinition) => {
        return `${taskDef.id}: ${taskDef.meta?.subarea ? `(${taskDef.meta?.subarea})` : ''} ${taskDef.type}`
    }

    const currentTodo = React.useRef<any>(null)
    useEffect(() => {
        currentTodo.current?.focus();
    }, []);

    const setSelect = (t: HandleTaskType, index: number, filters: filters) => {
        if (tasks) {
            const tempTasks = tasks.slice()
            tempTasks.filter(t  => checkIfToShow(t, filters, showSubnodes)).map((tt, tIndex) => tt.isSelected = index === tIndex)
            setTasks(tempTasks)
        }
    }

    const getSubnodes = (node: AreaNode) => {
        let nodes = [node]
        node.subareas?.forEach(sn => {
            nodes = nodes.concat(getSubnodes(sn))
        })
        return nodes;
    }

    const getMoreTasks = () => {
        setSending(true)
        if (filterStatuses.filter(fs => fs.show).length === 0) {
            setSending(false)
            return
        }
        const nodes = filterNodes?.filter(fn => fn.show).map(fn => fn.node.id).join(',')
        taskService.getbyFilter({
            id: tasks ? tasks[tasks.length - 1].props.data.id : undefined,
            date: oldestDate?.toString(),
            status: filterStatuses.filter(fs => fs.show).map(fs => fs.status).join(','),
            nodes,
            limit: 50,
            order_by: 'date'
        }).then(result => {
            if (result.length === 0) {
                alert('No tasks found');
            }
            const newTasks: HandleTaskType[] = result.map(r => ({ display: false, initialStatus: r.status ?? 'draft', status: r.status ?? 'draft', isSelected: false, created_date: r.created_date, props: { data: { ...r.data, id: r.id }, }, selectedForDeleting: false }))
            setTasks(t => t ? t.concat(newTasks) : newTasks)
            if (newTasks.length > 0) {
                setOldestDate(newTasks[newTasks.length - 1].created_date)
            }
        }).finally(() => {
            setSending(false)
        })
    }

    const saveChanges = () => {
        const changedTasks = tasks?.filter(t => t.initialStatus !== t.status)
        if (!tasks || !changedTasks || changedTasks.length === 0) return
        setSending(true)
        const approved = changedTasks.filter(t => t.status === 'approved').map(t => t.props.data.id)
        const draft = changedTasks.filter(t => t.status === 'draft').map(t => t.props.data.id)
        const rejected = changedTasks.filter(t => t.status === 'rejected').map(t => t.props.data.id)
        taskService.postTaskStatuses(approved, draft, rejected).finally(() => {
            setSending(false)
        })
    }

    const handleDeleteAllRejected = () => {
        setSending(true)
        taskService.deleteAll()
            .then(result => {
                console.log('result', result)
                if (result.error !== undefined) {
                    alert('Something went wrong')
                } else {
                    alert('Tasks deleted')
                    window.location.reload()
                }
            }).finally(() => setSending(false))

    }

    return <div >
        <div className='row'>
            <div className='col'>sort direction: {sortDirection}</div>
            <div className='col'>filter node: {filterNodes?.every(fn => !fn.show) ? 'All nodes' : filterNodes?.filter(fn => fn.show).map(fn => fn.node.headline)}</div>
            {filterNodes && <div className='col'><select onChange={event => setFilterNodes(filterNodes.slice().map(fn => ({ ...fn, show: fn.node.headline === event.target.value })))}>
                <option value={undefined}>All nodes</option>
                {filterNodes.map((n, index) => <option key={index} value={n.node.headline}>{n.node.headline}</option>)}
            </select>
                <input className='ms-2 me-1' id="notSubnodesCheckbox" type='checkbox' onChange={() => setShowSubnodes(!showSubnodes)} checked={showSubnodes} /><label htmlFor='notSubnodesCheckbox'>Show with subnodes</label>
            </div>}
        </div>
        <div className='row'>
            <div className='col-4 text-start'><h3>Rejected</h3></div>
            <div className='col-4 text-center'><h3>Draft</h3></div>
            <div className='col-4 text-end'><h3>Approved</h3></div>
        </div>
        <div>
            {tasks ? <>
                {tasks.filter(t => showOnlyChanged || checkIfToShow(t, { filterStatuses, filterTypes, filterNodes: filterNodes ?? [] }, showSubnodes)).length > 0 ?
                    handlePages(currentPage, setCurrentPage, tasks, setTasks, showOnlyChanged, { filterStatuses: filterStatuses, filterTypes: filterTypes, filterNodes: filterNodes ?? [] },
                        setSelect, currentTodo, changeDisplay, handleTask, handleTask2, changeStatus, getMoreTasks, sending, showSubnodes)
                    : <div className='text-center'>
                        <div>Inga uppgifter kvar att hantera</div>
                        <button className='btn btn-primary mt-3 col-3' onClick={() => getMoreTasks()} disabled={sending}>{sending ? 'Hämtar' : 'Hämta fler uppgifter'}</button>
                    </div>}
                <div style={{ position: 'fixed', width: '100%', left: '0', bottom: '0', height: '5em', zIndex: '1' }}>
                    <div className='row mt-2 mb-1 text-center'>
                        {filterTypes.map((ft, index) =>
                            <div key={index} className={'col-2 '}>{index === 0 ? 'Types: ' : ''}
                                <label htmlFor={'filter-type-' + index} className='me-2' >{ft.taskType}</label><input id={'filter-type-' + index} key={index} type="checkbox" checked={ft.show} onChange={() => filterType(ft.taskType)} disabled={showOnlyChanged} />
                            </div>
                        )}
                        {filterStatuses.map((ft, index) =>
                            <div key={index} className={'col-2 '}>{index === 0 ? 'Statuses: ' : ''}
                                <label htmlFor={'filter-status-' + index} className='me-2' >{ft.status}</label><input id={'filter-status-' + index} key={index} type="checkbox" checked={ft.show} onChange={() => filterStatus(ft.status)} disabled={showOnlyChanged} />
                            </div>
                        )}
                    </div>
                    <div className='row'>
                        <div className='offset-1 col'><button className='col-6 btn btn-secondary' onClick={() => sortTasks(tasks.slice(), 'rejected')}>Sort by rejected</button></div>
                        <div className='col'><button className='col-6 btn btn-secondary' onClick={() => sortTasks(tasks.slice(), 'id')}>Sort by id</button></div>
                        <div className='col'><button className={'col-6 btn ' + (!showOnlyChanged ? 'btn-secondary' : 'btn-info')} onClick={() => setShowOnlyChanged(s => !s)}>{!showOnlyChanged ? 'Visa ändrade' : 'Visa alla'}</button></div>
                        <div className='col '><button className='col-6 btn btn-primary' onClick={saveChanges} disabled={sending}>{sending ? 'Sparar' : 'Spara'} </button></div>
                        <div className='col' ><button className='col-6 btn btn-delete' onClick={() => handleDeleteAllRejected()} disabled={sending}>{'Delete all rejected'}</button></div>
                    </div>
                </div>
            </> : <div className='text-center'>Hämtar uppgifter</div>}
        </div>
    </div >
}

export default HandleAllTasks