import PreviewEmail, { emailPreview } from 'components/EmailPreview/EmailPreview'
import React, { useState } from 'react'
import styles from './Feedback.module.scss';
import dictionary from 'translations/dictionary.sv.json';
import emailService from 'services/emailService';


export const Feedback = () => {

    const [message, setMessage] = useState<string>('')
    const [status, setStatus] = useState<string>('')

    const emailPreview: emailPreview = {
        toAddresses: ['feedback@elevert.se'],
        ccAddresses: [],
        subject: 'Feedback',
        messageHtml: 'Hello World',
        messageText: 'Hello World'
    }

    const handleSubmit = async () => {
        setStatus(`${dictionary.Working}...`)
        try {
            const result = await emailService.sendFeedbackEmail(message)
            setStatus(result.ok ? dictionary.Submitted : dictionary.FailedToSendFeedback)
            if (result.ok) setMessage('')
        } catch (error) {
            setStatus(dictionary.FailedToSendFeedback)
        }

    }

    return <div className='offset-1 col-md-6'>
        <h3>{dictionary.Feedback}</h3>
        <p className='col-12'>
            {dictionary.FeedbackIntro}
        </p>
        <div className={`${styles.feedback}`}>
            <div className={styles.feedbackHeader + ' row col-12'}><b>{dictionary.NewMessage}</b></div>
            <div className={styles.feedbackBox + ' py-1 row col-12'}>
                <span className='col-2'>{dictionary.To}:</span>
                <input id="to" className={styles.feedbackInput + ' col'} value='feedback@elevert.se' disabled />
            </div>
            <div className={styles.feedbackBox + ' py-1 row col-12'}>
                <span className='col-2'>{dictionary.Subject}:</span>
                <input id="subject" className={styles.feedbackInput + ' col'} value={dictionary.Suggestion} disabled />
            </div>
            <div className='row col-12'><textarea data-testid="message" className={styles.feedbackInput + ' col-12 py-4'} placeholder={dictionary.WriteYourFeedbackHere} onChange={event => { setStatus(''); setMessage(event.target.value) }} disabled={status === `${dictionary.Working}...`} value={message} /></div>
            <div className='row col-12'>
                <div role={status === dictionary.Submitted || status === dictionary.FailedToSendFeedback ? "alert" : "status"} className='col-2'>{status}</div>
                <div className='d-flex flex-row-reverse col-10'><input type="submit" className='btn btn-primary mt-2 col-md-3' data-testid="submit" disabled={message === '' || status === `${dictionary.Working}...`} onClick={handleSubmit} value={dictionary.Submit} /></div>
            </div>
        </div>
    </div>
}