import React, { useEffect, useState } from "react"
import HandleNodes from "./HandleNodes"
import SendEmail from "./SendTestEmail"
import { useParams } from "react-router-dom";
import HandleSchools from "./HandleSchools";
import HandlePeople from "./HandlePeople";
import { useTeacher } from "providers/SignInProvider";
import HandleTasks from "./HandleTasks/HandleTasks";
import HandleStatistics from "./HandleStatistics";

const tabs = ['None', 'Tasks', 'Nodes', 'Email', 'People', 'Skolor', 'Statistics'] as const
export type Tabs = (typeof tabs)[number];
const isTab = (x: any): x is Tabs => tabs.includes(x);
const isTabType = (x: any, y: Tabs): x is Tabs => x === y;

function Admin() {
    const [chosenTab, setChosenTab] = useState<Tabs>('None')
    const params = useParams();
    const { tab } = params;

    const teacher = useTeacher();
    const [hasAccess, setHasAccess] = useState(false)

    const GetTab = () => {
        let tab = <div style={{ textAlign: 'center' }}>Select a tab</div>
        chosenTab !== 'None' ? window.history.pushState({ tab: chosenTab }, `Admin/${chosenTab}`, `/Admin/${encodeURIComponent(chosenTab)}`)
            : window.history.pushState({ tab: chosenTab }, `Admin`, `/Admin`)
        switch (chosenTab) {
            case 'Nodes':
                tab = <HandleNodes />;
                break;
            case 'Email':
                tab = <SendEmail />
                break;
            case 'Tasks':
                tab = <HandleTasks />
                break;
            case 'People':
                tab = <HandlePeople />
                break;
            case 'Skolor':
                tab = <HandleSchools />
                break;
            case 'Statistics':
                tab = <HandleStatistics />
                break;
            case 'None':
            default:
                <div>Select a tab above</div>
                break;
        }
        return tab;
    }

    useEffect(() => {
        setHasAccess(true)
        if (!window.location.href.includes('localhost') && teacher) {
            if (teacher?.email && (teacher.email.includes('@systemvaruhuset') || teacher.email.includes('@11k'))) {
                setHasAccess(true)
            }
            else {
                window.location.pathname = '/'
                // window.location.reload()
            }
        }
    }, [teacher])

    useEffect(() => {
        if (tab) {
            setChosenTab(isTab(tab) ? tab : 'None')
        }
    }, [tab])

    return <>{hasAccess && <div>
        <div className="text-center mb-3">
            {tabs.map((t, index) => (
                !isTabType(t, 'None') && <span key={index} className="me-5" style={{ cursor: 'pointer', textDecoration: 'underline', marginRight: '10px' }}
                    onClick={() => setChosenTab(t)}>{t}</span>
            ))}
        </div>
        <GetTab />
    </div>}</>
}


export default Admin