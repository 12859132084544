import { ClassBase } from '@viewmodel/school';
import { TeacherSessionContext } from '@viewmodel/session';
import PreviewEmail, { emailPreview, CreateEmailPreview } from 'components/EmailPreview/EmailPreview';
import { useTeacher, useUpdateTeacher } from 'providers/SignInProvider';
import React, { ChangeEventHandler, Children, Dispatch, ReactNode, useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom';
import emailService, { inviteEmail } from 'services/emailService';
import studentGroupService from 'services/studentGroupService';

import dictionary from 'translations/dictionary.sv.json';
import InputBox from 'ux_components/boxes/input_box';
import SubmitBox from 'ux_components/boxes/submit_box';
import { SelectOption } from 'ux_components/select';
import { ReactComponent as DeleteIcon } from './delete-icon.svg';
import userDataService from 'services/userDataService';


async function sendInviteEmail(message: string, recipientsString: string, studentClass: ClassBase, teacher: TeacherSessionContext) {
    const recipientsArray = recipientsString.split(',').map(r => r.trim());

    const email: inviteEmail = {
        emailMessage: message,
        studentEmails: recipientsArray,
        teacherName: teacher.signedInUser.fullName,
        schoolName: teacher.school.name,
        institutionId: teacher.school.id,
        className: studentClass.headline,
        class_id: studentClass.id,
    }

    return await emailService.sendInviteEmail(email)
}

async function previewInviteEmail(message: string, recipientsString: string, className: string, teacher: TeacherSessionContext) {
    const recipientsArray = recipientsString.split(',').map(r => r.trim());

    const email: inviteEmail = {
        emailMessage: message,
        studentEmails: recipientsArray,
        teacherName: teacher.signedInUser.fullName,
        schoolName: teacher.school.name,
        institutionId: teacher.school.id,
        className: className,
        class_id: '-1',
    }

    return await emailService.sendInviteEmail(email, true)
}

const SelectClass = (props: {
    isNewClass: boolean,
    setIsNewClass: (value: boolean) => void,
    newClassName: string | undefined
    setNewClassName: (value: string | undefined) => void,
    classOption: SelectOption<ClassBase>[],
    studentClass: ClassBase | undefined
    setStudentClass: (newValue: ClassBase | undefined) => void
}) => {
    const {
        isNewClass, setIsNewClass,
        newClassName, setNewClassName,
        setStudentClass } = props

    const [newClass, setNewClass] = useState(isNewClass)
    // const [newClassName, setNewClassName] = useState('')
    const options = useMemo(() => props.classOption.map((option, index) => ({ ...option, key: `${option.value.id}-${option.value.headline}-class` })), [props.classOption]);
    const selected = useMemo(() => isNewClass ? 'new class' : (props.studentClass != null ? `${props.studentClass.headline}-class` : undefined), [props.studentClass, isNewClass]);


    const onChange: ChangeEventHandler<HTMLSelectElement> = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        const selectedId = event.target.value;
        if (selectedId != null) {
            if (selectedId === 'new class') {
                setNewClass(true)
                setStudentClass(undefined)
            }
            else {
                setIsNewClass(false)
                setNewClass(false)
                setStudentClass(options.find(o => o.key === selectedId)?.value);
            }
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== 'Enter' && event.key !== 'Tab') return;
        event.preventDefault();
        if (newClassName?.trim() === '') return
        setIsNewClass(true)
    }
    return <div>
        <div className='ms-0 ms-md-3 col-10 col-md-12 mb-3' >
            <select className='col-12' name={`teacher-class-select`} onChange={onChange} value={selected}>
                <option hidden value={undefined}>{dictionary.SelectClass}</option>
                <option value="new class">Skapa ny klass</option>
                <optgroup label="Klasser">{options.map(opt => <option key={opt.key} value={opt.key} >{opt.label}</option>)}
                </optgroup>
            </select>
        </div>
        {newClass && <div className=''>
            <div className='ms-0 ms-md-3 col-10 col-md-12'>
                <label className='col-12 mb-1'>{dictionary.ClassName}:</label>
                <input className='col-12 mb-2' type='text' value={newClassName ?? ''}
                    onChange={(event) => { setNewClassName(event.target.value) }}
                    onKeyDown={handleKeyDown} onBlur={() => newClassName?.trim() !== '' && setIsNewClass(true)} />
            </div>
        </div>}
    </div>
}

const HandleNewStudents = (props: {
    studentEmails: string[],
    setStudentEmails: (students: string[]) => void,
}) => {
    const [studentEmail, setStudentEmail] = useState<string>('')

    const handleEnter = (event: any) => {
        if (event.key !== 'Enter' && event.key !== ',' && event.key !== ' ') return;
        event.preventDefault();
        saveEmail();
    }

    const saveEmail = () => {
        if (validateEmail(studentEmail)) {
            const emailsCopy = props.studentEmails.slice()
            emailsCopy.push(studentEmail)
            props.setStudentEmails(emailsCopy)
            setStudentEmail('')
        }
    }

    const removeStudent = (index: number) => {
        const emailsCopy = props.studentEmails.slice()
        emailsCopy.splice(index, 1)
        props.setStudentEmails(emailsCopy)
    }

    function validateEmail(email: string) {
        const res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return res.test(String(email).toLowerCase());
    }

    return (
        <div>
            <div className=''>
                <div className='row ms-0 ms-md-3'>
                    <input className='col-8 col-md-9 col-lg-10' type='email' onKeyDown={handleEnter} value={studentEmail} onChange={event => setStudentEmail(event.target.value)} />
                    <h3 className='col-3 col-md-3 offset-lg-1 col-lg-1' style={{ cursor: 'pointer', textAlign: 'center' }} onClick={saveEmail}>+</h3>
                </div>
            </div>
            <div className=' ms-0 ms-md-3 mt-1'>
                {props.studentEmails.map((email, index) => <div key={index} className=''>
                    {props.studentEmails.some((se, seIndex) => se === email && index !== seIndex)
                        ? <label className='' style={{ color: 'red' }}>{email}</label>
                        : <label className=''>{email}</label>}
                    <label className='ms-5' style={{}}>
                        <DeleteIcon style={{ height: '13px', cursor: 'pointer' }} onClick={() => removeStudent(index)} />
                    </label>
                </div>)}
            </div>
        </div>
    )
}

type InviteStudentsProps = {
    newClassName: string | undefined
    studentClass: ClassBase | undefined
    isNewClass: boolean
    studentEmails: string[]
    message: string
    messageStartIndex: number
    emailPreview: emailPreview | undefined
    emailPreviewStatusMessage: string | undefined
    inviteLink: string | undefined
}

type InviteStudentsOpenBoxesProps = {
    openClass: boolean
    openStudents: boolean
    openGenerateCode: boolean
    openStudentEmails: boolean
    openMessage: boolean
    openPreview: boolean
}

const InputTelephoneNumber = () => {
    const [telephoneNumber, setTelephoneNumber] = useState<string>('')
    const [displayErrorText, setDisplayErrorText] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const teacher = useTeacher();

    const checkTelephoneNumber = (displayErrorText: boolean, telephoneNumber: string,) => {
        if (!displayErrorText) return true;
        var pattern = /^[0-9+-]+$/;
        return (telephoneNumber.length > 6
            && telephoneNumber.length < 18
            && pattern.test(telephoneNumber))
    }

    const handleSave = () => {
        setDisplayErrorText(true)
        if (telephoneNumber.trim() === '') return
        if (!checkTelephoneNumber(true, telephoneNumber)) return
        setIsSaving(true)
        const nameArray = teacher?.signedInUser.fullName.split(' ') ?? ['', '']
        // save telephone number     
        userDataService.putUserData(nameArray[0] ?? '', nameArray.slice(1).join(' '), telephoneNumber)
            .then(result => {
                window.location.reload();
            })
            .finally(() => setIsSaving(false))
    }

    return <div>
        {/* <div style={{ marginBottom: '5px' }}>{dictionary.ToInviteStudentsYouNeedToHaveAMobilePhoneNumberRegistered}</div> */}
        <div style={{ marginBottom: '5px' }}>
            Av integritetsskäl så är det inte möjligt att skapa klasser och bjuda in elever om vi inte kan verifiera dig som lärare.
            <br />För att göra det behöver vi kunna bekräfta din lärarroll via telefon.
        </div>
        <div style={{ marginBottom: '5px' }}><input type='text' value={telephoneNumber} placeholder={dictionary.TelephoneNumber} onChange={event => setTelephoneNumber(event.target.value)} /></div>
        <div style={{ marginBottom: '5px' }}>{!checkTelephoneNumber(displayErrorText, telephoneNumber) && <label className='mt-2 text-danger'>{dictionary.TelephoneNumberMustBeBetween7And17Symbols}</label>} </div>
        <div style={{ marginBottom: '5px' }}><button className='btn btn-primary' onClick={() => handleSave()} disabled={isSaving}>{dictionary.Save}</button></div>
    </div>

}

function InviteStudents2() {
    const teacher = useTeacher();
    const updateTeacher = useUpdateTeacher()
    const [propsState, setPropsState] = useState<InviteStudentsProps>({
        newClassName: undefined,
        studentClass: undefined,
        isNewClass: false,
        studentEmails: [],
        message: '',
        messageStartIndex: -1,
        emailPreview: undefined,
        emailPreviewStatusMessage: undefined,
        inviteLink: undefined
    })
    const [openState, setOpenStates] = useState<InviteStudentsOpenBoxesProps>({
        openClass: true,
        openStudents: false,
        openGenerateCode: false,
        openStudentEmails: false,
        openMessage: false,
        openPreview: true,
    })

    const [isSendEmail, setIsSendEmail] = useState<boolean | undefined>(true)
    const [emailSent, setEmailSent] = useState(false)
    const [sendingEmail, setSendingEmail] = useState(false)

    const classOption = useMemo<SelectOption<ClassBase>[]>(() => {
        const classes = (teacher?.school.classes || []).map(c => ({ value: c, label: c.headline }))
        return (classes)
    }, [teacher])


    const handleOpenState = (name: string, value: boolean) => {
        setOpenStates(p => ({ ...p, [name]: value }))
    }

    const handleSetClass = (classOption: ClassBase | undefined) => {
        if (classOption) {
            handleOpenState('openClass', false)
            handleOpenState('openStudents', true)
            setPropsState({
                newClassName: undefined,
                isNewClass: false,
                studentEmails: [],
                message: '',
                messageStartIndex: -1,
                emailPreview: undefined,
                emailPreviewStatusMessage: undefined,
                studentClass: classOption,
                inviteLink: 'hämtar länk'
            })
            studentGroupService.getInviteCode(classOption.id).then(result => {
                setPropsState(p => ({ ...p, studentClass: classOption, inviteLink: `spel.elevert.se?invite=${result.invite_code}` }))
            })
            setIsSendEmail(true)
            handleOpenState('openStudentEmails', true)
        } else {
            if (propsState.isNewClass && propsState.newClassName && propsState.newClassName.trim() !== '') {
                setIsSendEmail(true)
                handleOpenState('openStudentEmails', true)
            }
            setPropsState(p => ({ ...p, isNewClass: false, studentClass: undefined, inviteLink: undefined }))
        }

    }

    const handleNewClass = (isNewClass: boolean) => {
        setPropsState({ ...propsState, isNewClass })
        if (isNewClass) {
            handleOpenState('openClass', false)
            handleOpenState('openStudents', true)
        }
    }

    const handleNewClassName = (className: string | undefined) => {
        setPropsState({ ...propsState, newClassName: className })
    }

    const checkClassStatus = () => {
        if (propsState.isNewClass) return propsState.newClassName?.trim() !== ''
        else return propsState.studentClass !== undefined

    }

    const handleMessage = (event: any) => {
        const newMessage = event.target.value
        event.preventDefault()
        // const messageArray = emailCopy.message.split('')
        if (propsState.emailPreview && propsState.emailPreview.messageText) {
            handleOpenState('openStudentEmails', false)
            const emailCopy = Object.assign({}, propsState.emailPreview)
            if (newMessage === '') {
                emailCopy.messageText = emailCopy.messageText
                    .replace(`Din lärare skriver: \r\n ${propsState.message}`, '')
                setPropsState(p => ({ ...p, message: newMessage, emailPreview: emailCopy }))

            }
            else if (newMessage && newMessage !== propsState.message) {
                emailCopy.messageText = emailCopy.messageText.includes('Din lärare skriver:') ? emailCopy.messageText.replace(`Din lärare skriver: \r\n ${propsState.message}`,
                    `Din lärare skriver: \r\n ${newMessage}`)
                    : emailCopy.messageText.substring(0, propsState.messageStartIndex) + `Din lärare skriver: \r\n ${newMessage}` + emailCopy.messageText.substring(propsState.messageStartIndex)
                setPropsState(p => ({ ...p, message: newMessage, emailPreview: emailCopy }))
            }
        }
        else
            setPropsState({ ...propsState, message: newMessage })
    }

    const handleStudentEmail = (studentEmails: string[]) => {
        if (propsState.emailPreview) {
            const emailCopy = Object.assign({}, propsState.emailPreview)
            emailCopy.toAddresses = studentEmails
            setPropsState(p => ({ ...p, emailPreview: emailCopy }))
        }
        setPropsState(p => ({ ...p, studentEmails }))
        setIsSendEmail(true)
        handleOpenState('openGenerateCode', false)
        handleOpenState('openMessage', true)
    }

    const createNewClass = async () => {
        if (propsState.newClassName && teacher) {
            setSendingEmail(true)
            const newStudentClass = await studentGroupService.create(propsState.newClassName, teacher?.school.id, teacher?.id, teacher?.school.syllabi[0] ?? '0') as ClassBase
            if (newStudentClass) {
                const invite = await studentGroupService.getInviteCode(newStudentClass.id)
                const classes = teacher.school.classes.slice()
                classes.push(newStudentClass)
                if (updateTeacher) updateTeacher({ ...teacher, school: { ...teacher.school, classes } })
                setPropsState(p => ({ ...p, studentClass: newStudentClass, inviteLink: `spel.elevert.se?invite=${invite.invite_code}` }))
            }
            setSendingEmail(false)
            return newStudentClass;
        }
    }

    const handleSend = async () => {
        let tempStudentClass;
        setSendingEmail(true)
        if (propsState.isNewClass) {
            if (propsState.newClassName && teacher) {
                tempStudentClass = await createNewClass()
                window.dataLayer.push({ event: 'createClass', createClassProps: { school: { id: teacher.school.id, name: teacher.school.name } } })
            }
            else {
                setSendingEmail(false)
                return
            };
        }
        else {
            tempStudentClass = propsState.studentClass
        }
        if (!tempStudentClass || propsState.studentEmails.length === 0 || !teacher) {
            setSendingEmail(false)
            return
        };
        await sendInviteEmail(propsState.message, propsState.studentEmails.join(','), tempStudentClass, teacher).then(result => {
            window.dataLayer.push({ event: 'invite', inviteProps: { nrOfStudents: propsState.studentEmails.length, school: { id: teacher.school.id, name: teacher.school.name } } })
            setEmailSent(true)
            setSendingEmail(false)
            alert('Inbjudan är nu skickad')
            window.location.reload();
        });
    }

    useEffect(() => {
        if (teacher) {
            if (!propsState.emailPreview) {
                setPropsState(p => ({ ...p, emailPreviewStatusMessage: dictionary.GettingPreview }))
                try {
                    previewInviteEmail('Lorem Ipsum', '', '', teacher).then(result => {
                        let emailPreview = CreateEmailPreview(result)
                        const index = emailPreview.messageText.indexOf('Din lärare skriver:')
                        emailPreview.messageText = emailPreview.messageText.replace('Din lärare skriver: \r\n', '')
                        emailPreview.messageText = emailPreview.messageText.replace('Lorem Ipsum', '')

                        setPropsState(p => ({ ...p, messageStartIndex: index, emailPreview: emailPreview, emailPreviewStatusMessage: undefined }))
                    })
                }
                catch {
                    setPropsState(p => ({ ...p, emailPreviewStatusMessage: dictionary.FailedToGetPreview }))
                }
            }
        }
    }, [teacher, propsState.emailPreview, propsState.emailPreviewStatusMessage, propsState.messageStartIndex])

    return (<>
        {teacher?.mobile ? <div className='row'>
            <h2>{dictionary.InviteStudents}</h2>
            <div className='col-md-6 col-lg-5'>
                <div className='row mb-3'>
                    <div className='col-1 me-3'><img style={{ maxWidth: '35px', display: propsState.studentClass || (propsState.isNewClass && propsState.newClassName) ? 'block' : 'none' }} src={'./small-check-mark-icon.png'} alt="first step" /></div>
                    <InputBox header={<>
                        <h5>1) {dictionary.ChooseClassForTheStudentsToBelong}</h5>
                        <h5>{propsState.isNewClass ? 'Ny klass: ' + propsState.newClassName : propsState.studentClass?.headline}</h5>
                    </>}
                        isOpen={openState.openClass} toggleOpen={(value: boolean) => {
                            handleOpenState('openClass', value);
                            handleOpenState('openStudents', false); setIsSendEmail(true)
                        }} >
                        <SelectClass
                            isNewClass={propsState.isNewClass}
                            setIsNewClass={handleNewClass}
                            newClassName={propsState.newClassName}
                            setNewClassName={handleNewClassName}
                            studentClass={propsState.studentClass}
                            classOption={classOption}
                            setStudentClass={handleSetClass}
                        />
                    </InputBox>
                </div>
                {<div className='row mb-3'>
                    <div className='col-1 me-3'><img style={{ maxWidth: '35px', display: propsState.studentEmails.length > 0 ? 'block' : 'none' }} src={'./small-check-mark-icon.png'} alt="first step" /></div>
                    <InputBox header={<h5>2) Ange e-post adresser</h5>} isOpen={openState.openStudentEmails} toggleOpen={(value: boolean) => { handleOpenState('openStudentEmails', value); value && setIsSendEmail(value); value && handleOpenState('openGenerateCode', false); }} >
                        {checkClassStatus() ? <HandleNewStudents
                            studentEmails={propsState.studentEmails}
                            setStudentEmails={handleStudentEmail} />
                            : <div>Välj eller skapa klass först</div>}
                    </InputBox>
                </div>}
                {isSendEmail && <><div className='row'>
                    <div className='col-1 me-3'><img style={{ maxWidth: '35px', display: propsState.message ? 'block' : 'none' }} src={'./small-check-mark-icon.png'} alt="third step" /></div>
                    <InputBox header={<h5>3) {dictionary.OptionalMessage}</h5>} isOpen={openState.openMessage}
                        toggleOpen={(value: boolean) => handleOpenState('openMessage', value)}>
                        {checkClassStatus() ?
                            <textarea key='optional-message-textbox' className='ms-0 ms-md-3 col-10 col-md' onChange={(event) => handleMessage(event)} />
                            : <div>Välj eller skapa klass först</div>}
                    </InputBox>
                </div>
                    <div className='row'>
                        <SubmitBox isActive={propsState.studentEmails.length > 0 && !sendingEmail} onClick={handleSend}>
                            <h5 >{dictionary.Send}</h5>
                        </SubmitBox>
                        {emailSent && <div className='mt-3' id='emailSent'>
                            <h3>Inbjudan är nu skickad</h3>
                            <p>På
                                <NavLink to="/Reports"> uppföljningsidan </NavLink>
                                kan du se när eleverna börjar använda spelet.</p>
                        </div>}
                        <InputBox className='d-block d-md-none mt-3' header={
                            <h5 style={{ textAlign: 'center' }}>{dictionary.PreviewEmail}</h5>
                        } isOpen={openState.openPreview} toggleOpen={() => handleOpenState("openPreview", !openState.openPreview)} >
                            <>
                                {propsState.emailPreviewStatusMessage && <div className='p-3'>{propsState.emailPreviewStatusMessage}</div>}
                                {propsState.emailPreview && <div className='p-3'><PreviewEmail preview={propsState.emailPreview} /></div>}
                            </>
                        </InputBox>
                    </div></>}
            </div>
            {propsState.studentEmails.length > 0 && <>
                <div className='d-none d-md-block col-md-5 offset-md-1 col-lg-5'>
                    <div className='mt-3' >
                        <h4>{dictionary.PreviewEmail}</h4>
                        {propsState.emailPreviewStatusMessage && <div className=''>{propsState.emailPreviewStatusMessage}</div>}
                        {propsState.emailPreview && <div className=''><PreviewEmail preview={propsState.emailPreview} /></div>}
                    </div>
                </div>
            </>}
        </div> : <div>
            <h2>{dictionary.InviteStudents}</h2>
            <InputTelephoneNumber />
        </div>}</>)
}

export default InviteStudents2