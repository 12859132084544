import React, { useState } from 'react';

import dictionary from 'translations/dictionary.sv.json';
import { FillGapsTaskDefinition } from '@task';

type HandleFillGapsProps = {
    state: (FillGapsTaskDefinition & {id: string});
    onChange: (state: (FillGapsTaskDefinition & {id: string})) => void;
}

function HandleFillGaps({ state, onChange }: HandleFillGapsProps) {
    const difficulties = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    const [gapIsSelected, setGapIsSelected] = useState<boolean>(false)
    const toggleGap = (index: number) => {
        const gaps = state.gaps.slice();
        gaps[index] = !gaps[index];
        onChange({ ...state, gaps });
        setGapIsSelected(gaps.some(gap => gap))
    }

    return (
        <>
            <label>
                <h4>{dictionary.CreateGapSentence} {dictionary.TwoOfThree}</h4>
                {dictionary.ChooseTheWordsThatTheStudentShouldFillIn}:
                <div className=''>
                {state.words.map((word, index) =>
                    <input type="button" key={index}
                        className="btn btn-primary"
                        value={word}
                        onClick={() => toggleGap(index)}
                        style={{ color: "white", backgroundColor: state.gaps[index] ? "black" : "dimgrey", margin: '0px 5px' }}
                    />
                )}
                </div>
            </label>
            <br />
            <br />
            {gapIsSelected && <label>
                <h4>{dictionary.CreateGapSentence} {dictionary.ThreeOfThree}</h4>
                <div>{dictionary.ChooseTheIncorrectAlternativesForTheStudentToChooseBetween}:</div>
                <div>
                    <input type="text" className='col-12' value={state.options.join(',')}
                        onChange={event => onChange({ ...state, options: event.target.value !== '' ? event.target.value.split(/[\s,.]+/) : [] })}
                    />
                </div>
            </label>}
        </>
    )
}

export default HandleFillGaps;
