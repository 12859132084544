import { AddApiResponseWrapper, AddPromise, DateRangeFilter, DescendingPagingFilter, PrependApiRequestArgument } from "@viewmodel/apiHelpers";
import { UserId } from "@viewmodel/common";
import { ReportApiEndpoints, ReportDataDashboard, ReportDataTaskActivity, ReportDataAreaActivity, ReportDataCurriculum, ReportDataOverviewActivity } from "@viewmodel/reports";
import { ReportFilter } from "pages/Reports";

type _endpoint = keyof ReportApiEndpoints;

function makeCaller<Params extends { [key: string]: string }, Filter, RequestBody, ResponseBody>(endpoint: _endpoint, queryFn: (filter: Filter) => string) {
    // TODO: Support body for POST and PUT

    const match = endpoint.match(/^(?<method>GET|POST|PUT|DELETE)\/(?<path>[a-zA-Z0-9\/:]+)$/i);
    const method = match?.groups?.method;
    const pathTemplate = match?.groups?.path;

    if (!method || !pathTemplate) {
        throw new Error(`Malformed endpoint template ${endpoint}`);
    }

    const identifiedParams = (pathTemplate.match(/(?:\/:)([a-zA-Z0-9]+)/g) || []).map(p => p.substr(2));
    const fetchOptions: RequestInit = {
        method,
        credentials: 'include',
        // TODO make helper that adds auth headers/cookies/whatever
    };

    return async (params: Params, filter: Filter) => {
        const path = identifiedParams.reduce((acc, curr) => {
            return acc.replace(`:${curr}`, params[curr]);
        }, pathTemplate);
        const url = `${process.env.REACT_APP_API_BASE}/${path}${queryFn(filter)}`;
        const response = await fetch(url, { ...fetchOptions });

        // TODO Add standardized error handling, logging, reporting
        return (await response.json())?.payload as ResponseBody;
    };
}

function makeBasicCaller<Params extends { [key: string]: string }, ResponseBody>(endpoint: _endpoint) {
    const fn = makeCaller<Params, undefined, never, ResponseBody>(endpoint, () => '');
    return async (params: Params) => await fn(params, undefined);
}
function makeFilteredCaller<Params extends { [key: string]: string }, Filter, ResponseBody>(endpoint: _endpoint, queryFn: (filter: Filter) => string) {
    return makeCaller<Params, Filter, never, ResponseBody>(endpoint, queryFn);
}
function makeBodiedCaller<Params extends { [key: string]: string }, RequestBody, ResponseBody>(endpoint: _endpoint) {
    // TODO: Actually support the request body! 
    const fn = makeCaller<Params, undefined, RequestBody, ResponseBody>(endpoint, () => '');
    return async (params: Params) => await fn(params, undefined);
}

const apiEndpointCalls: AddPromise<ReportApiEndpoints> = {
    'GET/report/dashboard/class/:classId': makeBasicCaller('GET/report/dashboard/class/:classId'),
    'GET/report/dashboard/student/:studentId': makeBasicCaller('GET/report/dashboard/student/:studentId'),

    'GET/report/activity/areas/class/:classId': makeFilteredCaller('GET/report/activity/areas/class/:classId', buildDateRangeFilter),
    'GET/report/activity/areas/student/:studentId': makeFilteredCaller('GET/report/activity/areas/student/:studentId', buildDateRangeFilter),

    'GET/report/activity/tasks/class/:classId': makeFilteredCaller('GET/report/activity/tasks/class/:classId', buildDescendingDatePagingFilter),
    'GET/report/activity/tasks/student/:studentId': makeFilteredCaller('GET/report/activity/tasks/student/:studentId', buildDescendingDatePagingFilter),

    'GET/report/activity/overview/class/:classId': makeFilteredCaller('GET/report/activity/overview/class/:classId', buildDateRangeFilter),
    'GET/report/activity/overview/student/:studentId': makeFilteredCaller('GET/report/activity/overview/student/:studentId', buildDateRangeFilter),

    'GET/report/curriculum/class/:classId': (params: { classId: string }): Promise<ReportDataCurriculum> => { throw new Error('Not implemented'); },
    'GET/report/curriculum/student/:studentId': (params: { studentId: UserId }): Promise<ReportDataCurriculum> => { throw new Error('Not implemented'); },
};

function buildDescendingDatePagingFilter(filter: DescendingPagingFilter<Date>) {
    const pars: string[] = [];
    if (filter.lastSeen) {
        pars.push(`lastSeen=${filter.lastSeen}`);
    }
    pars.push(`pageSize=${filter.pageSize}`);
    return `?${pars.join('&')}`;
}

function buildDateRangeFilter(filter: DateRangeFilter) {
    const to = filter.to
    to.setDate(filter.to.getDate() + 1);
    return `?from=${filter.from.toISOString().substring(0,10)}&to=${to.toISOString().substring(0,10)}`
}

async function getDashboard(filter: ReportFilter): Promise<ReportDataDashboard> {
    if (!!filter.selectedStudent) {
        return apiEndpointCalls['GET/report/dashboard/student/:studentId']({ studentId: filter.selectedStudent.id });
    }
    if (!!filter.selectedClass) {
        return apiEndpointCalls['GET/report/dashboard/class/:classId']({ classId: filter.selectedClass.id });
    }
    throw new Error('Required parameters not set');
}

async function getTaskActivity(filter: ReportFilter, page: DescendingPagingFilter<Date>): Promise<ReportDataTaskActivity> {
    if (!!filter.selectedStudent) {
        return await apiEndpointCalls['GET/report/activity/tasks/student/:studentId']({ studentId: filter.selectedStudent.id }, page);
    }
    if (!!filter.selectedClass) {
        return await apiEndpointCalls['GET/report/activity/tasks/class/:classId']({ classId: filter.selectedClass.id }, page);
    }
    // TODO: Implement endpoint for all students connected to teacher? Throw? 
    return [];
}

async function getAreaActivity(filter: ReportFilter, page: DateRangeFilter): Promise<ReportDataAreaActivity> {
    if (!!filter.selectedStudent) {
        return await apiEndpointCalls['GET/report/activity/areas/student/:studentId']({ studentId: filter.selectedStudent.id }, page);
    }
    if (!!filter.selectedClass) {
        return await apiEndpointCalls['GET/report/activity/areas/class/:classId']({ classId: filter.selectedClass.id }, page);
    }

    return [];
}

async function getOverviewActivity(filter: ReportFilter, page: DateRangeFilter): Promise<ReportDataOverviewActivity> {
    if (!!filter.selectedStudent) {
        return await apiEndpointCalls['GET/report/activity/overview/student/:studentId']({ studentId: filter.selectedStudent.id }, page);
    }
    if (!!filter.selectedClass) {
        return await apiEndpointCalls['GET/report/activity/overview/class/:classId']({ classId: filter.selectedClass.id }, page);
    }

    return [];
}

export const reportService = {
    getDashboard,
    getTaskActivity,
    getAreaActivity,
    getOverviewActivity,
}; 