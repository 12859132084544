import React from 'react';
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from 'logo3.svg';

import dictionary from 'translations/dictionary.sv.json';
import styles from './Header.module.css';
import { useAuth, useTeacher } from 'providers/SignInProvider';

const navLinkClassName = ({ isActive }: { isActive: boolean }) => {
  return styles.headerLink + (isActive ? ' ' + styles.selected : '')
}

const SmallScreenHeader = () => {
  const auth = useAuth();
  const teacher = useTeacher();

  return (
    <>
      <div className='d-md-none row text-end'>
        {teacher && <div className='text-end'>
          <label className=' align-middle'>{teacher?.signedInUser.shortName}</label><br />
          <label className='align-middle'>{teacher.school?.name}</label>
        </div>}
        <div className='offset-1 col-1 text-center text-end'>
          <NavLink className={navLinkClassName + 'text-center'} to="/">
            <Logo style={{ height: '50px', width: '50px' }} />
          </NavLink>
        </div>
        <nav className="navbar navbar-dark offset-6 col-1">
          <div className="container-fluid col">
            <button className="navbar-toggler" style={{ backgroundColor: '#f67535', color: '#f67535' }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
        <div id="navbarToggleExternalContent" className='collapse'>
          <div id={auth?.isLoggedIn ? 'loggedIn' : 'loggedOut'} className="row">
            {auth?.isLoggedIn && <>
              {teacher && teacher.id !== '0' && <>
                <NavLink className={(isActive) => navLinkClassName(isActive) + ' col-12'} to="/CreateHomework">
                  {dictionary.CreateHomework}
                </NavLink>
                <NavLink className={(isActive) => navLinkClassName(isActive) + ' col-12'} to="/Reports">
                  {dictionary.FollowUp}
                </NavLink>
                <NavLink className={(isActive) => navLinkClassName(isActive) + ' col-12'} to="/InviteStudents">
                  {dictionary.InviteStudents}
                </NavLink>
                <NavLink className={navLinkClassName} to="/Feedback">
                  {dictionary.Feedback}
                </NavLink>
                <NavLink className={(isActive) => navLinkClassName(isActive) + ' col-12'} to="/Assignment">
                  {dictionary.Config}
                </NavLink>
              </>}
              <a className={styles.headerLink} href="/#" onClick={(e) => { e.preventDefault(); auth.logout() }}>
                {dictionary.Logout}
              </a>
              {(teacher?.email.includes('@11k.se') || teacher?.email.includes('@systemvaruhuset.se') || process.env.REACT_APP_API_BASE?.includes('localhost'))
                && <NavLink className={navLinkClassName + ' col-12'} to="/Admin">Admin</NavLink>}
            </>}
          </div>
        </div>
      </div>
    </>
  )
};

export default SmallScreenHeader;