import React, { useState } from 'react';

import Task, { MultipleChoiceTaskDefinition, TaskDefinition, taskIs } from '@task';
import dictionary from 'translations/dictionary.sv.json';
import AddNewMultipleChoice from './AddNewMultipleChoice';
import styles from './../task-components.module.css';
import TaskTypeCard from 'pages/Assignment/_components/TaskTypeCard';
import goldenArrowAnimated from './../golden_arrow animated.png'
import SimpleXlsxFileInput, { SimpleXlsxFile } from 'components/SimpleXlsx/SimpleXlsxFileInput';
import ImportTasksFromExcel from '../Task Functions/ImportTasksFromExcel';
import AddMultiplChoiceSentence from './AddMultipleChoiceSentence';

const EMPTY_TASK: MultipleChoiceTaskDefinition & { id: string } = { id: '', type: 'MultipleChoice', question: '', alternatives: [] };

const createMultipleChoiceSentence = (meta: TaskDefinition['meta']) => (sentence: string): (MultipleChoiceTaskDefinition & { id: string }) => {
    return { id: '', type: 'MultipleChoice', question: sentence.replace(/\s+/g, ' ').trim(), alternatives: [], meta, comment: '' };
}

type CreateMultipleChoiceProps = {
    onChange: (tasks: (MultipleChoiceTaskDefinition & { id: string })[], meta: Required<TaskDefinition>['meta']) => void;
}

function CreateMultipleChoice(props: CreateMultipleChoiceProps) {
    const [data, _setData] = useState<(MultipleChoiceTaskDefinition & { id: string })[]>([]);
    const [showSentence, setshowSentence] = useState(false);
    const [readInstructions, setReadInstruction] = useState<boolean>(false)
    const [showImport, setshowImport] = useState(false);
    const [metaData, setMetaData] = useState<Required<TaskDefinition>['meta']>({ subarea: '', gradeYear: '', instructions: '', difficulty: 0 });
    const gradeYears = [{ difficulty: 100, text: 'Årskurs 1' }, { difficulty: 200, text: 'Årskurs 2' }, { difficulty: 300, text: 'Årskurs 3' }, { difficulty: 400, text: 'Årskurs 4' }, { difficulty: 500, text: 'Årskurs 5' }, { difficulty: 600, text: 'Årskurs 6' }, { difficulty: 700, text: 'Årskurs 7' }, { difficulty: 800, text: 'Årskurs 8' }, { difficulty: 900, text: 'Årskurs 9' }, { difficulty: 1000, text: 'Gymnasium' }]

    const setData: typeof _setData = (action) => {
        _setData((state) => {
            const newData = typeof action === 'function' ? action(state) : action;
            props.onChange(newData, { subarea: '' }); // TODO: move the state to the parent
            return newData;
        });
    }

    const handleChange = (index: number, value: typeof data[number]) => {
        const newData = data.slice();
        newData[index] = value;
        setData(newData);
    }

    const handleDelete = (index: number) => {
        setData(data.filter((v, i) => i !== index));
    }

    const appendTasks = (tasks: TaskDefinition[]) => {
        if (tasks.length === 0) return;
        const validTasks: typeof data = tasks.filter(taskIs('MultipleChoice'));
        if (tasks.length !== validTasks.length) {
            console.error('Not all tasks were of the correct type');
        }
        setData(state => state.concat(validTasks));
    }

    const appendSentence = (sentence: string) => {
        setData(state => state.concat(createMultipleChoiceSentence(metaData)(sentence.replace(/\s+/g, ' ').trim())));
    }

    const onXlsxFileInputChange = (xlsxFiles: readonly SimpleXlsxFile[]) => {
        let tasks: (MultipleChoiceTaskDefinition & { id: string })[] = []
        const createTask = createMultipleChoiceSentence(metaData)

        tasks = (ImportTasksFromExcel(xlsxFiles, createTask))

        appendTasks(tasks)
    }

    const handleSetMetaData = (value: string) => {
        const meta = { ...metaData, gradeYear: gradeYears[+value].text, difficulty: gradeYears[+value].difficulty }
        setMetaData(meta)
        setData(state => state.map((v, i) => ({ ...v, meta })))
    }

    return (
        <div>
            <TaskTypeCard taskType="MultipleChoice" taskTypeName={dictionary.MultiChoiceQuestion} taskTypeDescription={dictionary.MultiChoiceQuestionDescription} onSelect={() => { }}></TaskTypeCard>
            <h4>{dictionary.CreateMultipleChoiceQuestion}</h4>
            <span>
                {dictionary.CreateTaskDescriptions.CreateMultipleChoiceQuestions.LineOne}
                <ol>
                    <li>{dictionary.CreateTaskDescriptions.CreateMultipleChoiceQuestions.ListLineOne}</li>
                    <li>{dictionary.CreateTaskDescriptions.CreateMultipleChoiceQuestions.ListLineTwo}</li>
                    <li>{dictionary.CreateTaskDescriptions.CreateMultipleChoiceQuestions.ListLineThree}</li>
                    {dictionary.CreateTaskDescriptions.CreateMultipleChoiceQuestions.ListLineFour}
                    <li>Testa att fråga och svar ser bra ut för eleven längst ned på sidan innan du sparar</li>
                </ol>
            </span>

            {!readInstructions && <div style={{ textAlign: "center" }}> <img src={goldenArrowAnimated} alt='Next arrow' style={{ minWidth: '5%', maxWidth: '5%' }} onClick={() => setReadInstruction(true)} /></div>}
            {readInstructions && <>
                <div>
                    <label>Välj lämplig årskurs</label><label style={{ color: 'red' }}>*</label> <br />
                    <select onChange={(event) => handleSetMetaData(event.target.value)}>
                        <option selected hidden>Årskurser</option>
                        {gradeYears.map((year, index) => <option value={index}>{year.text}</option>)}
                    </select>
                </div>
                {data.length === 0 && <div>
                    {!showSentence && !showImport &&
                        <div className='col-12 col-md-6 mb-3'>
                            <label className='form-label'>{dictionary.CreateMultipleChoiceQuestionBelow}:</label>
                            <AddMultiplChoiceSentence onSubmit={appendSentence} />
                            <label className='form-label'>{dictionary.Or}</label>
                            <button className='form-control btn btn-primary' onClick={() => setshowImport(true)}>{dictionary.UploadAnExcelFileWithSentences}</button>
                        </div>
                    }
                    {showImport && <><br />
                        <div className='mt-1'>Vid inläsning av kalkylbladet så skapar vi en uppgift per rad, med hjälp av texten som står i den första kolumnen.</div>
                        <div> Inga andra kolumner kommer att läsas in.</div></>}
                    <SimpleXlsxFileInput onChange={onXlsxFileInputChange} />
                </div>}

                {data.map((value, index) => (
                    <div key={index} style={{ margin: '1em' }}>
                        <AddNewMultipleChoice state={value} onChange={newValue => handleChange(index, newValue)} />
                        {/* <button style={{ color: 'red' }} onClick={() => handleDelete(index)}>{dictionary.Remove}</button> */}
                    </div>
                ))}

                {/* <div style={{ marginTop: '1em' }}>
                <button onClick={() => setData(data.concat(EMPTY_TASK))}>{dictionary.AddQuestion}</button>
                <GetSuggestions taskType="MultipleChoice" onReceived={appendTasks} />
            </div> */}

                {data.length > 0 && <div>
                    <h5>{dictionary.Preview}</h5>
                    {data.map((value, index) => (
                        value.alternatives?.some(alt => alt.isCorrect) && <div className={`${styles.taskBox} col-md-4`} key={index}>
                            <Task data={value} onCompletion={(taskDef, score) => alert(score > 0 ? dictionary.Correct : dictionary.Incorrect)} />
                            <br />
                        </div>
                    ))}
                </div>}</>}
        </div>
    )
}

export default CreateMultipleChoice;
