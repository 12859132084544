import { TaskDefinition } from "@task";
import { TaskStatus } from "@viewmodel/tasks";

async function postTasks(tasks: { data: Omit<TaskDefinition, 'id'>, sortRandom: boolean }[]) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/tasks`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(tasks),
    });
    return response
}

async function getTaskCount(nodeId: number | undefined = undefined): Promise<{ count: number }> {
    let nodeIdQuery = nodeId ? `?node=${nodeId}` : ''
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/tasks/count${nodeIdQuery}`, {
        credentials: 'include',
    });
    return response.json();
}

async function getTasksCountByDate(date: Date, limit: number) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/tasks/count/date?date=${date.toISOString()}&limit=${limit}`, {
        credentials: 'include'
    })
    return response.json();
}

async function getTaskCountByNode(): Promise<{ node: string, count: number }[]> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/tasks/count/nodes`, {
        credentials: 'include',
    });
    return response.json();
}

async function getTasks(options?: { nrOfQuestions?: string, nodeId?: string }): Promise<{ id: string, data: TaskDefinition }[]> {
    const query = `?${options?.nrOfQuestions ? `nr=${options?.nrOfQuestions}` : ''}${options?.nrOfQuestions && options?.nodeId ? '&' : ''}${options?.nodeId ? `nodeId=${options?.nodeId}` : ''}`
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/tasks${query}`, {
        credentials: 'include'
    })
    return response.json()
}

async function getByStatus(status: string, nrOfQuestions?: string, area?: string): Promise<{ id: string, data: TaskDefinition }[]> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/tasks?status=${status}${nrOfQuestions ? `&nr=${nrOfQuestions}` : ''}${area ? `&nodeId=${area}` : ''}`, {
        credentials: 'include'
    })
    return response.json()
}

async function getbyFilter(options?: { id?: string, date?: string, status?: string, nodes?: string, limit?: number, order_by?: string })
    : Promise<{ id: string, data: TaskDefinition, status: TaskStatus, created_date: Date }[]> {
    const { id, date, status, nodes, limit, order_by } = options ?? {}
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/tasks/filter?${id ? `id=${id}` : ''}${date ? `&date=${date}` : ''}${status ? `&status=${status}` : ''}${nodes ? `&nodes=${nodes}` : ''}${limit ? `&limit=${limit}` : ''}${order_by ? `&order_by=${order_by}` : ''}`, {
        credentials: 'include'
    })
    return response.json()
}

async function getByFeedback(...type: string[]) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/tasks/feedback?type=${type.join(',')}`, {
        credentials: 'include'
    })
    return response.json()
}

async function postTaskStatuses(approved: string[], draft: string[], rejected: string[]) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/tasks/status`, {
        credentials: 'include',
        method: 'post',
        body: JSON.stringify({ approved, draft, rejected })
    })
    return response.json()
}

async function postFeedbackHandle(tasks: { taskId: string, status: TaskStatus, delete: boolean }[]) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/feedback/tasks/handle`, {
        credentials: 'include',
        method: 'post',
        body: JSON.stringify(tasks)
    })
    return response.json()
}

async function getFeedbackMessages(taskId: string) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/feedback/bug/tasks/${taskId}`, {
        credentials: 'include',
    })
    return response.json()
}

async function getFeedbackCountByDate(date: Date, limit: number) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/feedback/bug/count/date?date=${date.toISOString()}&limit=${limit}`, {
        credentials: 'include'
    })
    return response.json();
}

async function getTaskActivitesCountByDate(date: Date, limit: number) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/activity/count/date?date=${date.toISOString()}&limit=${limit}`, {
        credentials: 'include'
    })
    return response.json();
}

async function getDifficultyCountByDate(date: Date, limit: number) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/activity/count/difficulty?date=${date.toISOString()}&limit=${limit}`, {
        credentials: 'include'
    })
    return response.json();
}

async function deleteTasks(ids: string[]) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/tasks`, {
        credentials: 'include',
        method: 'delete',
        body: JSON.stringify(ids)
    })
    return response.json()
}

async function deleteAll() {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/tasks/all`, {
        credentials: 'include',
        method: 'delete',
    })
    return response.json()
}

export const taskService = {
    postTasks,
    getTaskCount,
    getTaskCountByNode,
    getTasksCountByDate,
    getTasks,
    getByStatus,
    getbyFilter,
    getByFeedback,
    getFeedbackCountByDate,
    getTaskActivitesCountByDate,
    getDifficultyCountByDate,
    postTaskStatuses,
    postFeedbackHandle,
    getFeedbackMessages,
    deleteTasks,
    deleteAll,
}

export default taskService