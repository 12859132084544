import { StudentBase } from '@viewmodel/users'
import PreviewEmail, { CreateEmailPreview, emailPreview } from 'components/EmailPreview/EmailPreview'
import React, { useEffect, useState } from 'react'
import NotificationsService, { notification } from 'services/NotificationsService'
import { IconCorrect, IconMail } from 'ux_components/icons'
import dictionary from '../../translations/dictionary.sv.json'
import styles from './Reports.module.scss';



function FeedbackFlow(props: { students: StudentBase[] }) {
    const { students } = props
    const [notifications, setNotifications] = useState<notification[]>([])
    const [loading, setLoading] = useState(false)
    const [includeHandled, setIncludeHandled] = useState(false)
    const [emailPreview, setEmailPreview] = useState<emailPreview | undefined>(undefined)
    const [emailPreviewStatusMessage, setEmailPreviewStatusMessage] = useState<string | undefined>(undefined)
    const [previewedNote, setPreviewedNote] = useState<notification | undefined>(undefined)
    const [sending, setSending] = useState(false)

    useEffect(() => {
        setNotifications([])
        setLoading(true)
        NotificationsService.getByStudentIds(students.map(stu => stu.id)).then(result => {
            setNotifications(n => n.concat(result).sort((a, b) => a.created > b.created ? -1 : 1))
        }).finally(() => {
            setLoading(false)
        })
    }, [students])

    const previewEmail = async (note: notification) => {
        setPreviewedNote(note)
        setEmailPreviewStatusMessage(dictionary.GettingPreview)
        setEmailPreview(undefined)
        try {
            const result = await NotificationsService.previewFeedbackEmail(note.id)
            const emailPreview = CreateEmailPreview(result)
            setEmailPreview(emailPreview)
            setEmailPreviewStatusMessage(undefined);
        } catch (err) {
            console.log('err', err)
            setEmailPreviewStatusMessage(dictionary.FailedToGetPreview)
        }

    }

    const sendFeedbackEmail = (note: notification) => {
        setSending(true)
        setEmailPreviewStatusMessage(dictionary.Sending)
        const notes = notifications.slice()
        setNotifications(notes)
        NotificationsService.sendFeedbackEmail(note.id).then(result => {
            const noteIndex = notes.findIndex(existingNote => existingNote.id === note.id)
            notes[noteIndex].handled = new Date()
            setEmailPreview(undefined)
            setEmailPreviewStatusMessage(dictionary.Sent)
            setNotifications([])
            NotificationsService.getByStudentIds(students.map(stu => stu.id)).then(result => {
                setNotifications(n => n.concat(result).sort((a, b) => a.created > b.created ? -1 : 1))
            })
        }).catch(err => {
            console.log('err', err); setEmailPreviewStatusMessage('Failed to send email')
        }).finally(() => {
            setSending(false)
        })
    }

    const NotificationContainer = (props: { note: notification, index: number, student: StudentBase | undefined }) => {
        const { note, index, student } = props
        return <div className={styles.notificationContainer + ' container mt-2 mb-2'} key={'note' + index} id={'note' + index} role={'note' + index} style={{ border: 'thin solid black', borderRadius: '5px' }}>
            <div className='row'>
                <div className='col'>{dictionary.Student}: {student?.fullName !== '' ? student?.fullName : student.email}</div>
            </div>
            <div className='row'>
                <div className='col'>{dictionary.Event}: {note?.studentEvent?.name} </div>
                {!note.handled ? <>
                    <div className='col-md-1 d-flex justify-content-end align-items-center' id={'previewEmail' + index} role={'previewEmail' + index} onClick={() => previewEmail(note).catch(err => console.log('err', err))}><IconMail /></div>
                </> : <div className='col-md-1 d-flex justify-content-end align-items-center' id={'handled' + index} role={'handled' + index} ><IconCorrect /></div>
                }
            </div>
            <div className='row'><div className='col'>{dictionary.Achieved}: {note.created.toString().substring(0, 10)}</div> </div>
        </div>
    }

    return <div>
        <div className='row'>
            <h3 className='col'>{dictionary.StudentAchievements}</h3>
        </div>
        <div className='row'>
            <div className='col mb-2'>
                <input className='me-2' id="includeHandled" role="includedHandled" type="checkbox" onChange={() => setIncludeHandled(!includeHandled)} checked={includeHandled} />
                <label className='' htmlFor='includeHandled'>{dictionary.IncludeAlreadyHandled}</label>
            </div>
        </div>
        {emailPreviewStatusMessage && <div>{emailPreviewStatusMessage}</div>}
        {emailPreview && <>
            <PreviewEmail preview={emailPreview} />
            {previewedNote && <input type="button" id="sendEmail" role={"sendEmail" + 0} onClick={() => sendFeedbackEmail(previewedNote)} value={dictionary.Send} disabled={sending} />}
        </>}
        {
            loading ? <div className='mt-2 mb-2' > {dictionary.GettingNotifications} </div>
                : notifications.filter(note => (includeHandled || !note?.handled))?.length > 0
                    ? notifications.map((note, index) => {
                        const student = students.find(s => s.id === note.studentId)
                        return (includeHandled || !note.handled) && <NotificationContainer key={index} {...{ note, index, student }} />
                    }) : <><div role='alert' className='mt-2 mb-2'>{dictionary.NoNotifications}</div></>
        }
    </div >
}

export default FeedbackFlow