import { TeacherSessionContext } from "@viewmodel/session";
import { TeacherBase } from "@viewmodel/users";

type SessionErrorVariety = 'SessionExpired' | 'BadCredentials' | 'TechnicalErrorRetryReasonable' | 'TechnicalErrorRetryNotReasonable';
export type SessionError = `SessionError${SessionErrorVariety}`;

// Basic idea: Use these to differentiate error messages and possible next step for user. 
// Something like this: 
//
// const errorHandlers: { [key in SessionError]: () => void } = {
//     SessionErrorBadCredentials: () => tellUserEmailOrPasswordWrong(),
//     SessionErrorSessionExpired: () => attemptAutomaticSessionRefreshORAskForNewSignIn(),
//     SessionErrorTechnicalErrorRetryNotReasonable: () => tellUserSomethingIsWrongAndToComeBackLater(),
//     SessionErrorTechnicalErrorRetryReasonable: () => tellUserToTryAgain()
// };
// login(user).catch(err => {
//     const handler = (errorHandlers[err.name as SesionError] || () => { throw err; })(); 
// });

// //Or maybe: 
// login(user).catch(err => {
//     switch (err.name as SessionError) {
//         case 'SessionErrorBadCredentials': 
//          ....
//     }
//     ... 
//     some never-protection to make sure we exhaust the possibilites
// });

class SessionErrorHelper extends Error {
    constructor(error: SessionError, message: string) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(message);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, SessionErrorHelper);
        }

        this.name = error;
    }
}

async function login(user: string): Promise<void> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/session/teacher`, {
        method: 'POST',
        credentials: 'include',
    });
    // Check that all is OK, else throw something!
    return;
}

async function logout(): Promise<void> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/session`, {
        method: 'DELETE',
        credentials: 'include',
    });
    // Check that all is OK, else throw something!
    return;
}

async function register(user: string): Promise<void> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/user`, {
        method: 'POST',
        credentials: 'include',
    });
    // Check that all is OK, else throw something!
    return;
}

async function getLoggedInTeacher(): Promise<TeacherSessionContext | null> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/session/context/teacher`, {
        method: 'GET',
        credentials: 'include',
    }).catch(() => {
        // TODO: Need better error! 
        throw new SessionErrorHelper('SessionErrorTechnicalErrorRetryReasonable', 'Unknown error when fetching session context');
    });
    if (response.status === 404) {
        return null;
    }
    if (!response.ok) {
        // TODO: better error
        throw new SessionErrorHelper('SessionErrorTechnicalErrorRetryReasonable', 'Unknown error when fetching session context');
    }
    return response.json();
}

async function getUsers(): Promise<TeacherBase[]> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/users`, {
        credentials: 'include'
    })
    return response.json();
}

async function deleteUsers(userIds: string[]) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/users`, {
        credentials: 'include',
        method: 'delete',
        body: JSON.stringify(userIds)
    })
    return response.json();
}

async function ping() {
    fetch(`${process.env.REACT_APP_API_BASE}/session/init/ping`)
    await fetch(`${process.env.REACT_APP_API_BASE}/data/ping`,
        {
            credentials: 'include'
        })
}

export const userService = {
    ping,
    getLoggedInTeacher,
    getUsers,
    login,
    logout,
    register,
    deleteUsers
};

export default userService; 