import { TeacherBase } from "@viewmodel/users";

async function createTeacherRole(institutionId: string): Promise<string> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/teacher`, {
        method: 'post',
        credentials: 'include',
        body: JSON.stringify({ institutionId })
    });
    return response.json();
}

async function getTeachers(): Promise<TeacherBase[]> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/teacher`, {
        credentials: 'include'
    })
    return response.json();
}

async function getTeachersInfo(): Promise<{ full_name: string, short_name: string, email: string, mobile: string, skola: string }[]> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/teacher/info`, {
        credentials: 'include'
    })
    return response.json();
}

    async function getTeachersCountByDate(date: Date, limit: number) {
        const response = await fetch(`${process.env.REACT_APP_API_BASE}/teacher/count/date?date=${date.toISOString()}&limit=${limit}`, {
            credentials: 'include'
        })
        return response.json();
    }

    export const TeacherRoleService = {
        createTeacherRole,
        getTeachers,
        getTeachersInfo,
        getTeachersCountByDate,
    }

    export default TeacherRoleService