import { AreaNode } from "@viewmodel/areaNode";
import React, { useEffect, useState } from "react";
import areaNodeService from "services/areaNodeService";
import ImportAssignmentsFromExcel from "./ImportAssignmentsFromExcel";

function ImportTasks() {
    const [nodes, setNodes] = useState<AreaNode[] | undefined>(undefined)

    useEffect(() => {
        let running = true;

        if (!nodes) {
            areaNodeService.getAreaNodes()
                .then(nodes => {
                    if (running) {
                        setNodes(nodes)
                    }
                })
        }
        return () => { running = false; };

    }, [nodes])

    return <div>
            <ImportAssignmentsFromExcel nodes={nodes} setNodes={setNodes} />
        </div>
}

export default ImportTasks;