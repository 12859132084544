import React from 'react';

function ShowAssignmentParts(props: {partBuilders: JSX.Element[]}) {

    return (<div>
        {props.partBuilders.map((builder, index) => (
            <div key={index} style={{ marginTop: '2em' }}>
                {builder}
            </div>
        ))}
    </div>)
}

export default ShowAssignmentParts;