import React, { useState } from 'react'
import emailService from 'services/emailService'


function SendTestEmail() {
    const [isSending, setIsSending] = useState<boolean>(false)
    const [toEmails, setToEmails] = useState<{ email: string, name: string }[]>([{ email: '', name: '' }])

    const handleToEmails = (index: number, type: 'email' | 'name', value: string) => {
        let newToEmails = toEmails.slice(0, toEmails.length)
        newToEmails[index][type] = value
        newToEmails = newToEmails.filter((toEmail) => toEmail.email.trim() !== '' || toEmail.name.trim() !== '')
        newToEmails.push({ email: '', name: '' })
        setToEmails(newToEmails)
    }
    const sendEmail = async () => {
        setIsSending(true)
        const response = await emailService.sendTestEmail(toEmails[0].email)
        console.log('response', response)
        setIsSending(false)
        alert('Sent')
    }

    const sendBrevoEmail = async () => {
        const tempToEmails = toEmails.slice(0, toEmails.length - 1);
        setIsSending(true)
        const response = await emailService.testBrevo(tempToEmails)
        console.log('response', response)
        setIsSending(false)
        alert(`Sent Brevo! \nStatus: ${response.status} ${response.statusText}`)
    }

    const checkDisabled = (onlyEmail: boolean) => {
        if (isSending) return true;
        if (toEmails.length <= 1) return true;
        let isDisabled = false;
        toEmails.slice(0, toEmails.length - 1).forEach((toEmail) => {
            if (toEmail.email.trim() === '' || !toEmail.email.includes('@')) {
                isDisabled = true
            }
            else if (!onlyEmail && toEmail.name.trim() === '') {
                isDisabled = true
            }
        })
        return isDisabled
    }

    return <>
        {toEmails.map((toEmail, index) => <>
            <div key={index} className='row mb-3'>
                <label className="offset-1 col-2" htmlFor="emailAddress">Email address: </label>
                <input className="col-2" id="emailAddress" type="email" value={toEmail.email} onChange={(event) => handleToEmails(index, 'email', event.target.value)} />
                <label className="offset-1 col-2" htmlFor="name">Name: </label>
                <input className="col-2" id="name" type="name" value={toEmail.name} onChange={(event) => handleToEmails(index, 'name', event.target.value)} />
            </div>
        </>)}
        <div className='row mt-3'>
            <button className="offset-4 col-2 me-3" onClick={sendEmail} disabled={checkDisabled(true)} >Send Email</button>
            <button className="col-2" onClick={sendBrevoEmail} disabled={checkDisabled(false)} >Send Brevo Email</button>
        </div>
    </>

}

export default SendTestEmail;
