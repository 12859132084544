import React from 'react';
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from 'logo3.svg';

import dictionary from 'translations/dictionary.sv.json';
import styles from './Header.module.css';
import { useAuth, useTeacher } from 'providers/SignInProvider';
import SmallScreenHeader from './SmallScreen-Header';

const navLinkClassName = ({ isActive }: { isActive: boolean }) => {
  return styles.headerLink + (isActive ? ' ' + styles.selected : '')
}

const Header = () => {
  const auth = useAuth();
  const teacher = useTeacher();

  return (
    <>
      <SmallScreenHeader />
      <div id="" className='row d-none d-md-block'>
        <div className='row'>
          <div id={auth?.isLoggedIn ? 'loggedIn' : 'loggedOut'} className="col">
            <NavLink className={navLinkClassName} to="/">
              <Logo style={{ height: '50px', width: '50px', transform: 'translate(0, 25%)' }} />
            </NavLink>
            {auth?.isLoggedIn && <>
              {teacher && teacher.id !== '0' && <>
                <NavLink className={navLinkClassName} to="/InviteStudents">
                  {dictionary.InviteStudents}
                </NavLink>
                <NavLink className={navLinkClassName} to="/Reports">
                  {dictionary.FollowUp}
                </NavLink>
                <NavLink className={navLinkClassName} to="/CreateHomework">
                  {dictionary.CreateHomework}
                </NavLink>
                <NavLink className={navLinkClassName} to="/Feedback">
                  {dictionary.Feedback}
                </NavLink>
                <NavLink className={navLinkClassName} to="/Assignment">
                  {dictionary.Config}
                </NavLink>
              </>}
              <a className={styles.headerLink} href="/#" onClick={(e) => { e.preventDefault(); auth.logout() }}>
                {dictionary.Logout}
              </a>
              {(teacher?.email.includes('@11k.se') || teacher?.email.includes('@systemvaruhuset.se') || process.env.REACT_APP_API_BASE?.includes('localhost'))
                && <NavLink className={navLinkClassName} to="/Admin">Admin</NavLink>}
            </>}
          </div>
          {teacher && <div className='col-2 text-end align-middle'>
            <label className='align-middle'>{teacher.signedInUser.shortName}</label> <br />
            <label className='align-middle'>{teacher.school?.name}</label></div>}
        </div>
      </div>
      <hr className={styles.headerLine} />
    </>
  )
};

export default Header;