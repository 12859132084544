import { ClassBase } from '@viewmodel/school';
import { TeacherSessionContext } from '@viewmodel/session';
import emailService from 'services/emailService';

async function sendHomeworkEmail(studentIds: string[], studentClass: ClassBase | undefined, teacher: TeacherSessionContext | undefined, message: string | undefined, data: any) {  
  const email = {
    studentIds: studentIds,
    assignment: {    
      teacher_id: teacher?.id ?? 0,
      data: data,
    },
    message
  }
  const fetchOptions = {
    method: 'POST',
    credentials: 'include' as RequestCredentials,
    body: JSON.stringify(email)
}
  return await emailService.sendHomeworkEmail(fetchOptions)
}

export async function previewHomeworkEmail(studentIds: string[], studentClass: ClassBase | undefined, teacher: TeacherSessionContext | undefined, message: string | undefined, data: any) {  
  const email = {
    studentIds: studentIds,
    assignment: {    
      teacher_id: teacher?.id ?? 0,
      data: data,
    },
    message
  }
  const fetchOptions = {
    method: 'POST',
    credentials: 'include' as RequestCredentials,
    body: JSON.stringify(email)
}
  return await (await emailService.sendHomeworkEmail(fetchOptions, true)).json()
}

export default sendHomeworkEmail;