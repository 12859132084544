import { roleStatus, RoleStatus, School } from '@viewmodel/school'
import SimpleXlsxFileInput, { SimpleXlsxFile } from 'components/SimpleXlsx/SimpleXlsxFileInput'
import { SimpleXlsxSheet } from 'components/SimpleXlsx/simple-xlsx'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import InstitutionService from 'services/InstitutionService'
import dictionary from 'translations/dictionary.sv.json'

function ImportSchools() {
    const [schools, setSchools] = useState<{ name: string, postalCode: string, county: string, homepage: string, status?: RoleStatus }[] | undefined>(undefined)
    const [isWorking, setIsWorking] = useState(false)
    const [uploadStatus, setUploadStatus] = useState<string>()
    useEffect(() => {

    }, [])

    const handleSheet = async (sheet: SimpleXlsxSheet) => {
        const schools: { name: string, postalCode: string, county: string, homepage: string, status?: RoleStatus }[] = []
        const rowNames: Record<string, number> = sheet.rows[0].reduce((acc, cur, index) => ({ ...acc, [cur.toString().toLowerCase()]: index }), {})
        const allowedSkippedRows = 10;
        let skippedRows = 0;
        for (let i = 1; i < sheet.rows.length; i++) {
            const row = sheet.rows[i]
            const school = {
                name: row[rowNames['skola']]?.toString() ?? '',
                postalCode: row[rowNames['postnr']]?.toString() ?? '',
                county: row[rowNames['postort']]?.toString() ?? '',
                homepage: row[rowNames['hemsida']]?.toString() ?? '',
                status: 'approved' as RoleStatus
            }
            if(skippedRows > allowedSkippedRows && school.name === '') break;
            if (skippedRows <= allowedSkippedRows && school.name === '') {
                skippedRows++
                continue;
            }
            if (school.name === '') {
                console.log('school name is empty', school)
                console.log('row', row)
            }
                schools.push(school)
        }
        setSchools(schools)
    }

    const onXlsxFileInputChange = (xlsxFiles: readonly SimpleXlsxFile[]) => {
        setIsWorking(true)
        setUploadStatus(undefined)

        const sheets = xlsxFiles[0].sheets
        if (!sheets || sheets.length === 0) return

        handleSheet(sheets[0]).finally(() => setIsWorking(false))
    }

    const submit = async () => {
        if (!schools) return
        setUploadStatus(dictionary.Uploading + '...')
        let index = 0;
        let count = 0;
        while (index <= schools.length) {
            count++
            index += 500
        }
        index = 0;
        while (index + 500 < schools.length) {
            setUploadStatus(`${dictionary.Uploading}...(${index / 500 + 1}/${count})`)
            await InstitutionService.createInstitutions(schools.slice(index, index + 500)).then(() => {
                setUploadStatus(dictionary.UploadCompleted)
            }).catch(() => {
                setUploadStatus(dictionary.UploadFailed)
            })
            index += 500
        }
        setUploadStatus(`${dictionary.Uploading}...(${count}/${count})`)
        InstitutionService.createInstitutions(schools.slice(index)).then(() => {
            setUploadStatus(dictionary.UploadCompleted)
        }).catch(() => {
            setUploadStatus(dictionary.UploadFailed)
        }).finally(() => setSchools([]))
    }

    return <><h4>Import Schools from excel </h4>
        {!isWorking ? <SimpleXlsxFileInput onChange={onXlsxFileInputChange} />
            : <div>Working...</div>}
        {schools && schools.length > 0 && <div>
            <div>{dictionary.NrOfSchoolsToUpload}: {schools.length}</div>
            <button className='btn btn-primary' onClick={submit}>Save</button>
        </div>}
        {uploadStatus && <div>{uploadStatus}</div>}
    </>
}

type HandleSchoolType = School & {
    initialStatus: RoleStatus
}

function ShowSchools() {
    const [schools, setSchools] = useState<HandleSchoolType[] | undefined>(undefined)
    const [sending, setSending] = useState(false)
    const [filterStatuses, setFilterStatuses] = useState<{ status: RoleStatus, show: boolean }[]>(roleStatus.map(rs => ({ status: rs, show: true })))

    const handleStatus = (newStatus: RoleStatus, schools: HandleSchoolType[], index: number) => {
        const tempSchools = schools.slice()
        tempSchools[index].status = newStatus ?? tempSchools[index].status
        setSchools(tempSchools)
    }

    const handleFilter = (index: number) => {
        const tempFilterStatus = filterStatuses.slice()
        tempFilterStatus[index].show = !tempFilterStatus[index].show;
        setFilterStatuses(tempFilterStatus)
    }

    const resetSchools = (schools: HandleSchoolType[]) => {
        setSchools(schools.slice().map(s => ({ ...s, status: s.initialStatus })))
    }

    const saveSchools = (schools: HandleSchoolType[]) => {
        const changedSchools = schools.filter(s => s.initialStatus !== s.status)
        if (changedSchools.length === 0) return;
        setSending(true)
        InstitutionService.updateInstitutionStatuses(changedSchools.map(cs => ({ id: cs.id, status: cs.status }))).then(res => {
            InstitutionService.getInstitutions(roleStatus.map(rs => rs)).then(result => {
                setSchools(result.map(r => ({ ...r, initialStatus: r.status })))
            }).finally(() =>
                setSending(false)
            )
        }).catch(() =>
            setSending(false)
        )
    }

    useEffect(() => {
        if (!schools) {
            InstitutionService.getInstitutions(roleStatus.map(rs => rs)
            ).then(result => {
                setSchools(result.map(r => ({ ...r, initialStatus: r.status })))
            }).catch(() => setSchools([]))
        }
    }, [schools, setSchools])

    return <div>
        <h3>Handle Schools</h3>
        <div className='row'>{filterStatuses.map((fs, index) =>
            <div key={index} className='col'>
                <input id={`filter-status-` + index} className='me-2' type="checkbox" onChange={() => handleFilter(index)} checked={fs.show} /><label htmlFor={`filter-status-` + index}>{fs.status}</label>
            </div>)}
        </div>
        {schools ? <div>
            <div className='row mt-3 mb-3'>
                <button className='btn btn-primary col-12 col-md-2' onClick={() => saveSchools(schools)} disabled={sending}>Spara</button>
                <button className='btn btn-secondary mt-3 col-12 mt-md-0 offset-md-1 col-md-2' onClick={() => resetSchools(schools)} disabled={sending}>Reset</button>
            </div>
            {schools.filter(s => filterStatuses.some(fs => fs.status === s.status && fs.show)).length > 0
                ? schools.filter(s => filterStatuses.some(fs => fs.status === s.status && fs.show)).map((s, index) =>
                    <div key={index} className='row'>
                        <h5>
                            <span>{`${s.id})`} {s.name}: </span>
                            <select onChange={event => handleStatus(event.target.value as RoleStatus, schools, index)} value={s.status} >
                                {roleStatus.map(rs =>
                                    <option key={rs} value={rs} >{rs}</option>)}
                            </select><span style={{ top: '-0.5em', position: 'relative' }}>{s.initialStatus !== s.status && '*'}</span>
                        </h5>
                    </div>)
                : <div>Inga skolor hittade testa att ändra filtrering</div>}
            <div className='row mt-3'>
                <button className='btn btn-primary col-12 col-md-2' onClick={() => saveSchools(schools)} disabled={sending}>Spara</button>
                <button className='btn btn-secondary mt-3 col-12 mt-md-0 offset-md-1 col-md-2' onClick={() => resetSchools(schools)} disabled={sending}>Reset</button>
            </div>
        </div> : <div>Hämtar Skolor</div>
        }
    </div >
}
const tabs = ['None', 'Show', 'Import'] as const
export type Tabs = (typeof tabs)[number];
const isTab = (x: any): x is Tabs => tabs.includes(x);
const isTabType = (x: any, y: Tabs): x is Tabs => x === y;

function HandleSchools() {
    const [chosenTab, setChosenTab] = useState<Tabs>('None')
    const params = useParams();
    const { subtab } = params;
    const GetTab = () => {
        let tab = <div style={{ textAlign: 'center' }}>Select a tab</div>
        chosenTab !== 'None' ? window.history.pushState({ tab: chosenTab }, `Admin/Skolor/${chosenTab}`, `/Admin/Skolor/${encodeURIComponent(chosenTab)}`)
            : window.history.pushState({ tab: chosenTab }, `Admin Skolor`, `/Admin/Skolor`)
        switch (chosenTab) {
            case 'Show':
                tab = <ShowSchools />
                break;
            case 'Import':
                tab = <ImportSchools />
                break;
            case 'None':
            default:
                <div>Select a tab</div>
                break;
        }
        return tab;
    }

    return <div>
        <div className="text-center mb-3">
            {tabs.map((t, index) => (
                !isTabType(t, 'None') && <span key={index} className="me-5" style={{ cursor: 'pointer', textDecoration: 'underline', marginRight: '10px' }}
                    onClick={() => setChosenTab(t)}>{t}</span>
            ))}
        </div>
        <GetTab />
        {/* <ImportSchools />
        <ShowSchools /> */}
    </div >
}

export default HandleSchools