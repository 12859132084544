import React, { useEffect, useState } from 'react'
import InstitutionService from 'services/InstitutionService'
import TeacherRoleService from 'services/teacherRoleService'
import userDataService from 'services/userDataService'
import style from './Signup.module.css'

import dictionary from '../../translations/dictionary.sv.json'
import { School } from '@viewmodel/school'

// type School = {
//     id: string
//     name: string
// }

const checkToDisplayErrorText = (displayErrorText: boolean, value: string | undefined) => {
    return (displayErrorText && (!value || value.trim() === ''))
}

const checkTelephoneNumber = (displayErrorText: boolean, telephoneNumber: string,) => {
    if (!displayErrorText) return true;
    if (telephoneNumber.trim().length === 0) return true;
    var pattern = /^[0-9+-]+$/;
    return (telephoneNumber.length > 6
        && telephoneNumber.length < 18
        && pattern.test(telephoneNumber))
}

type NewSchoolProps = {
    schoolPostCode: string
    setSchoolPostCode: (postcode: string) => void
    schoolCounty: string
    setSchoolCounty: (postcode: string) => void
    schoolHomepage: string
    setSchoolHomepage: (postcode: string) => void
    displayErrorText: boolean
}

const NewSchool = (props: NewSchoolProps) => {
    const {
        schoolPostCode,
        setSchoolPostCode,
        schoolCounty,
        setSchoolCounty,
        schoolHomepage,
        setSchoolHomepage,
        displayErrorText
    } = props
    return (
        <div className='mt-4'>
            <h4>Du verkar arbeta på en skola vi inte känner till!</h4>
            <p>Om du är säker på att du skrivit rätt namn på skolan så behöver vi veta lite mer om den för att kunna sätta upp allting åt dig.
                Har du råkat skriva fel namn är det bara att korrigera ovan.</p>
            <div className='row mb-2'>
                <div className='col-12 col-md-4'>
                    <input className='col-12' onChange={event => setSchoolPostCode(event.target.value)} value={schoolPostCode} placeholder={"Postnummer*"} />
                    {checkToDisplayErrorText(displayErrorText, schoolPostCode) && <label className='mt-2 text-danger'>Postnummer är obligatoriskt</label>}

                </div>
                <div className='col-12 offset-md-1 col-md-7'>
                    <input className='col-12' onChange={event => setSchoolCounty(event.target.value)} value={schoolCounty} placeholder={"Postort*"} />
                    {checkToDisplayErrorText(displayErrorText, schoolCounty) && <label className='mt-2 text-danger'>Postort är obligatoriskt</label>}
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12'>
                    <input className='col-12 col-md-4' onChange={event => setSchoolHomepage(event.target.value)} value={schoolHomepage} placeholder={"Hemsida"} />
                </div>
            </div>
        </div>
    )
}

function Signup() {
    const [school, setSchool] = useState<School | undefined>(undefined)
    const [schools, setSchools] = useState<School[] | undefined>(undefined)
    const [schoolName, setSchoolName] = useState<string | undefined>(undefined)
    const [schoolPostCode, setSchoolPostCode] = useState<string>('')
    const [schoolCounty, setSchoolCounty] = useState<string>('')
    const [schoolHomepage, setSchoolHomepage] = useState<string>('')
    const [firstName, setFirstName] = useState<string | undefined>(undefined)
    const [lastName, setLastName] = useState<string | undefined>(undefined)
    const [telephoneNumber, setTelephoneNumber] = useState<string>('')
    const [uploadStatus, setUploadStatus] = useState<string | undefined>(undefined)
    const [filteredSchools, setFilteredSchools] = useState<School[]>([])
    const [currentSchoolIndex, setCurrentSchoolIndex] = useState<number>(0)
    const [isNewSchool, setIsNewSchool] = useState<boolean>(false)
    const [showFilteredList, setShowFilteredList] = useState<boolean>(true)
    const [displayErrorText, setDisplayErrorText] = useState<boolean>(false)

    // const hardcodedSchools = (): School[] => {
    //     return [{ id: '1', name: 'skola 1' }, { id: '2', name: 'skola 2' }, { id: '3', name: 'skola 3' },
    //     { id: '4', name: 'en skola 1' }, { id: '5', name: 'annan skola 2' }, { id: '6', name: 'lärarställe 3' },
    //     { id: '7', name: 'här 4' }]
    // }

    const filterSchools = (schoolName: string) => {
        setSchoolName(schoolName)
        // const filtSchools = hardcodedSchools().filter(s => s.name.toLowerCase().includes(schoolName.trim().toLowerCase()))
        const filtSchools = schools ? schools.filter(s => s.name.toLowerCase().includes(schoolName.trim().toLowerCase())) : []
        setFilteredSchools(filtSchools)
    }

    const searchInput = React.useRef<HTMLInputElement>(null);

    const handleClick = (school: School) => {
        setSchoolName(school.name);
        setSchool(school);
        setIsNewSchool(false);
        if (searchInput.current) searchInput.current.focus();
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== 'Enter' && event.key !== 'ArrowDown' && event.key !== 'ArrowUp') return;
        if (filteredSchools.length === 0) return;
        if (event.key === 'Enter') {
            const selectedSchool = filteredSchools[currentSchoolIndex]
            if (!selectedSchool) return
            setCurrentSchoolIndex(0)
            handleClick(selectedSchool)
            return
        }
        event.preventDefault();
        let newNumber = currentSchoolIndex + (event.key === 'ArrowDown' ? 1 : -1);
        newNumber = newNumber < 0 ? filteredSchools.length - 1 : newNumber
        setCurrentSchoolIndex(newNumber % filteredSchools.length)
    }

    const handleNewSchoolSubmit = async (firstName: string, lastName: string, telephoneNumber: string, schoolName: string) => {
        try {
            setUploadStatus(`${dictionary.Creating}... (0/3)`);
            await userDataService.putUserData(firstName, lastName, telephoneNumber)
            setUploadStatus(`${dictionary.Creating}... (1/3)`);
            const { id: institutionId } = await InstitutionService.createInstitutions([{ name: schoolName, postalCode: schoolPostCode, county: schoolCounty, homepage: schoolHomepage }])
            if (institutionId) {
                setUploadStatus(`${dictionary.Creating}... (2/3)`);
                await TeacherRoleService.createTeacherRole(institutionId);
                setUploadStatus(`${dictionary.Creating}... (3/3)`);
                setUploadStatus(dictionary.teacherCreated);
                window.location.reload();
            } else {
                setUploadStatus('Upload Failed');
            }
        } catch (error) {
            setUploadStatus('Upload Failed');
        }

    }

    const handleExistingSchoolSubmit = async (firstName: string, lastName: string, telephoneNumber: string, school: School) => {
        try {
            setUploadStatus(`${dictionary.Creating}... (0/2)`);
            await userDataService.putUserData(firstName, lastName, telephoneNumber)
            setUploadStatus(`${dictionary.Creating}... (1/2)`);
            await TeacherRoleService.createTeacherRole(school.id);
            setUploadStatus(`${dictionary.Creating}... (2/2)`);
            setUploadStatus(dictionary.teacherCreated);
            window.location.reload();
        } catch {
            setUploadStatus('Upload Failed');
        }
    }

    const checkIfSubmitDisabled = () => {
        if (uploadStatus !== undefined) return true
        else if (isNewSchool) {
            return schoolPostCode.trim() === '' || schoolCounty.trim() === ''
        }
        return firstName === undefined || lastName === undefined || schoolName === undefined
    }

    const handleSubmit = async () => {
        setDisplayErrorText(false)
        if (schoolName && !school && !isNewSchool) {
            setIsNewSchool(true)
            setFilteredSchools([])
            return;
        }
        if (!firstName || !lastName || !schoolName) {
            setDisplayErrorText(true)
            return
        }
        if(!checkTelephoneNumber(true, telephoneNumber)){
            setDisplayErrorText(true)
            return
        }

        if (isNewSchool && schoolPostCode.trim() !== '' && schoolCounty.trim() !== '') {
            await handleNewSchoolSubmit(firstName, lastName, telephoneNumber, schoolName)
        } else if (school) {
            await handleExistingSchoolSubmit(firstName, lastName, telephoneNumber, school)
        }
        else setDisplayErrorText(true)
    }

    const handleBlur = (event: any) => {
        // if the blur was because of outside focus
        // currentTarget is the parent element, relatedTarget is the clicked element
        if (event.currentTarget === null || !event.currentTarget.contains(event.relatedTarget)) {
            setShowFilteredList(false)
        }
    }

    useEffect(() => {
        if (!schools) {
            InstitutionService.getInstitutions().then(result => {
                result.sort((a, b) => a.name > b.name ? 1 : -1)
                setSchools(result)
            })
        }
    }, [schools])

    return <div className='row'>
        <div className='offset-md-1 col-md-7'>
            <h3>{dictionary.WelcomeToElevert}</h3>
            <p className=''>Innan vi startar behöver vi lite information från dig för att kunna ge dig bästa möjliga stöd i din undervisning.</p>
            <br />
            <div className='row text-right mb-0 mb-md-4'>
                {/* <label className='col-md-3'>Namn:</label> */}
                <div className='col-12 col-md-4 mb-2 mb-md-0'>
                    <input className='col-12' id='firstName' type="text" onChange={event => setFirstName(event.target.value)} placeholder={dictionary.FirstName + '*'} />
                    {checkToDisplayErrorText(displayErrorText, firstName) && <label className='mt-2 text-danger'>{dictionary.FirstNameIsRequired}</label>}
                </div>
                <div className='col-12 offset-md-1 col-md-7 mb-2 mb-md-0'>
                    <input className='col-12' id='lastName' type="text" onChange={event => setLastName(event.target.value)} placeholder={dictionary.LastName + '*'} />
                    {checkToDisplayErrorText(displayErrorText, lastName) && <label className='mt-2 text-danger'>{dictionary.LastNameIsRequired}</label>}
                </div>
            </div>
            <div className='row text-right mb-0 mb-md-4'>
                <div className='col-12 col-md-4 mb-2 mb-md-0'>
                    <input className='col-12' id='telephoneNumber' type="tel" onChange={event => setTelephoneNumber(event.target.value)} placeholder={dictionary.TelephoneNumber} value={telephoneNumber} />
                </div>
                {!checkTelephoneNumber(displayErrorText, telephoneNumber) && <label className='mt-2 text-danger'>{dictionary.TelephoneNumberMustBeBetween7And17Symbols}</label>}
            </div>

            <div className='row' onBlur={handleBlur} onKeyDown={handleKeyDown}>
                <div>
                    <input className='col-12' type="text" ref={searchInput}
                        onChange={event => { filterSchools(event.target.value); setSchool(undefined) }} value={schoolName ?? ''} placeholder={dictionary.School + '*'}
                        onFocus={() => setShowFilteredList(true)} />
                    {showFilteredList && <div className={style.dropdown + ' '}>
                        {school?.name !== schoolName && schoolName && schoolName.trim().length > 0 && filteredSchools.map((sn, index) =>
                            <div key={index} tabIndex={index} className={(currentSchoolIndex === index ? style.selected : style.notSelected)} onClick={() => handleClick(sn)}>{sn.name}</div>)
                        }
                    </div>}
                    {checkToDisplayErrorText(displayErrorText, schoolName) && <label className='col-12 mb-2 mt-2 text-danger'>{dictionary.SchoolIsRequired}</label>}
                </div>
            </div>
            {isNewSchool && <NewSchool {...{ schoolPostCode, setSchoolPostCode, schoolCounty, setSchoolCounty, schoolHomepage, setSchoolHomepage, displayErrorText }} />}
            <div className='row mt-4'>
                <input type='submit' className={`btn btn-primary col-12 col-md-5 ${checkIfSubmitDisabled() ? style.disabled : ''}`} style={{ cursor: checkIfSubmitDisabled() ? 'default' : 'pointer' }} onClick={async () => await handleSubmit()} value={school ? dictionary.CreateTeacherAccount : `${dictionary.CreateTeacherAccount}`} />
                {/* <a className='btn btn-secondary col-12 offset-md-2 col-md-5 mt-4 mt-md-0' href={process.env.REACT_APP_GAME_URL}>{'Gå till spel sida'}</a> */}
            </div>
            {uploadStatus && <div>{uploadStatus}</div>}
        </div>
    </div>
}


export default Signup
