import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import SimpleSelect, { SelectOption } from 'ux_components/select';
import TaskActivityReport from './TaskActivity';
import AreaActivityReport from './AreaActivity';
import SyllabusHeatmapReport from './SyllabusHeatmap';
import ReportDashboard from './Dashboard';
import OverviewReport from './Overview';
import dictionary from 'translations/dictionary.sv.json';
import styles from './Reports.module.scss';
import { useTeacher } from 'providers/SignInProvider';
import { School } from '@viewmodel/school';
import FeedbackFlow from './FeedbackFlow';

// type SupportedReport = 'dashboard' | 'syllabusHeatmap' | 'overview' | 'areaActivity' | 'taskActivity';
type SupportedReport = 'overview' | 'areaActivity' | 'taskActivity';

const reportOptionLabels: { [key in SupportedReport]: string } = {
    'overview': dictionary.Overview,
    'areaActivity': dictionary.PracticedAreas,
    'taskActivity': dictionary.TaskHistory,
    // 'syllabusHeatmap': dictionary.Syllabus,
    // 'dashboard': dictionary.Dashboard,
    // 'overview': dictionary.Overview
};
const supportedReports = Object.keys(reportOptionLabels) as SupportedReport[];

type ClassOptionType = School['classes'][number];
type StudentOptionType = ClassOptionType['students'][number];

export interface ReportFilter {
    selectedClass: ClassOptionType | undefined,
    selectedStudent: StudentOptionType | undefined
}

const reportSelectionOptions: SelectOption<SupportedReport>[] = supportedReports.map(report => ({
    value: report,
    label: reportOptionLabels[report]
}));

const renderReport = (selectedReport: SupportedReport, filterState: ReportFilter, selectStudent: (value: string) => void) => {
    switch (selectedReport) {
        // case 'dashboard':
        //     return <ReportDashboard {...filterState} />;
        // case 'syllabusHeatmap':
        //     return <SyllabusHeatmapReport {...filterState} />;
        case 'overview':
            if (filterState.selectedStudent) selectStudent(dictionary.AllStudents)
            return <OverviewReport reportFilter={filterState} selectStudent={selectStudent} />;
        case 'areaActivity':
            return <AreaActivityReport {...filterState} />;
        case 'taskActivity':
            return <TaskActivityReport {...filterState} />;
        default:
            return <ReportDashboard {...filterState} />;
    }
};

function Reports(): JSX.Element {
    // const fromParams = useParams<{selectedReport: SupportedReport, filterState: string}>(); // TODO: Support deep linking

    const [filterState, setFilterState] = useState<ReportFilter>({ selectedClass: undefined, selectedStudent: undefined });
    // const [selectedReport, setSelectedReport] = useState<SupportedReport>('dashboard');
    const [selectedReport, setSelectedReport] = useState<SupportedReport>('overview');

    const teacher = useTeacher();

    const classOptions = useMemo<SelectOption<ClassOptionType>[]>(() => {
        return (teacher?.school?.classes || []).map(c => ({ value: c, label: c.headline }))
    }, [teacher]);

    const studentSelectionOptions = useMemo<SelectOption<StudentOptionType>[]>(() => {
        return (filterState?.selectedClass?.students || []).filter(s => s.created_event).map(s => ({ label: s.shortName.trim() !== '' ? s.shortName : s.email, value: s }));
    }, [teacher, filterState]);

    const selectStudentAreaActivity = (studentId: string) => {
        const student = studentSelectionOptions.find(s => s.value.id === studentId);
        if (student) {
            setSelectedReport('areaActivity')
            setFilterState({ ...filterState, selectedStudent: student.value });
        } else {
            setFilterState({ ...filterState, selectedStudent: undefined });
        }
    }

    const handleSelectReport = (report: SupportedReport) => {
        setSelectedReport(report);
        window.dataLayer.push({ event: 'followup', followupProps: { type: selectedReport } })
    }

    return (
        <div className={`${styles.reportsContainer} row`}>
            <div className={`${styles.leftColumn} col-12 col-md-6 mb-2 row`}>
                <div className='row'>
                    <div className='col-6 col-md-12 col-lg-2 mb-2'>{dictionary.ChooseClass}:</div>
                    <div className='col-6 d-block d-md-none mb-2' style={{ height: '30px' }}>
                        <button onClick={() => window.location.reload()}>{dictionary.GetClasses}</button>
                    </div>
                    <div className='col-md-6 mb-2'>
                        <SimpleSelect
                            onChange={newValue => setFilterState({ ...filterState, selectedClass: newValue, selectedStudent: undefined })}
                            options={classOptions}
                            keyPrefix="report-class"
                            placeholder={dictionary.SelectClass}
                            extractUniqueKey={value => value?.headline?.replace(' ', '') || '--class'}
                            selectedValue={filterState.selectedClass}
                        ></SimpleSelect>
                    </div>
                    <div className='col-6 col-lg-4 d-none d-md-block'>
                        <button onClick={() => window.location.reload()}>{dictionary.GetClasses}</button>
                    </div>
                </div>
                <div className={`${styles.reportTypeSelectorContainer} row`}>
                    <div className='col-2 col-md-12 col-lg-2 mb-2'>{dictionary.FollowUp}:</div>
                    <div className='col-md-6 mb-2'>
                        {/* <SimpleSelect onChange={newValue => setSelectedReport(newValue || 'dashboard')} options={reportSelectionOptions} keyPrefix="report-type" extractUniqueKey={value => value} selectedValue={selectedReport} ></SimpleSelect> */}
                        <SimpleSelect
                            onChange={newValue => handleSelectReport(newValue || 'overview')}
                            options={reportSelectionOptions}
                            keyPrefix="report-type"
                            extractUniqueKey={value => value}
                            selectedValue={selectedReport}
                        ></SimpleSelect>
                    </div>
                </div>
                {selectedReport !== 'overview' && <div className="row">
                    <div className='col-12 col-md-2 mb-2'>{dictionary.ChooseStudent}:</div>
                    <div className='col-md-6 mb-2'>
                        <SimpleSelect
                            onChange={newValue => setFilterState({ ...filterState, selectedClass: filterState?.selectedClass, selectedStudent: newValue })}
                            options={studentSelectionOptions}
                            keyPrefix="report-student"
                            placeholder={dictionary.AllStudents}
                            extractUniqueKey={value => value?.id || '--student'}
                            selectedValue={filterState.selectedStudent}
                        ></SimpleSelect>
                    </div>
                </div>}
                <div className={styles.reportContainer}>{renderReport(selectedReport, filterState, selectStudentAreaActivity)}</div>

            </div>
            <div className={`${styles.rightColumn} col-12 col-md-5`}>
                <div className={styles.eventFlowContainer + ' mt-3'}>
                    {filterState.selectedClass && <FeedbackFlow students={filterState.selectedStudent ? [filterState.selectedStudent] : filterState.selectedClass.students} />}
                </div>
            </div>
        </div>
    );
}

export default Reports;

