import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { TeacherSessionContext } from '@viewmodel/session';
import sessionService from 'services/sessionService';

type SignedInAuthContext = {
    isLoggedIn: true,
    logout: () => Promise<boolean>
};
type SignedOutAuthContext = {
    isLoggedIn: false,
    login: (userName: string) => Promise<boolean>,
    register: (userName: string) => Promise<boolean>
};
export type AuthContext = SignedInAuthContext | SignedOutAuthContext;

const ContextForAuth = React.createContext<AuthContext | undefined>(undefined);
const ContextForUser = React.createContext<{ teacher?: TeacherSessionContext, updateContext: (context: TeacherSessionContext) => void } | undefined>(undefined);

const signedInAuthContext = (on: { logout: () => void }): SignedInAuthContext => {
    return {
        isLoggedIn: true,
        logout: async () => {
            // TODO: Make this function pass on more helpful error messages, probably by demanding an error handler
            try {
                await sessionService.logout();
                on.logout();
                return true;
            } catch {
                return false;
            }
        }
    };
};
const signedOutAuthContext = (on: { login: () => void, register: () => void }): SignedOutAuthContext => {
    return {
        isLoggedIn: false,
        login: async (userName: string) => {
            // TODO: Make this function pass on more helpful error messages, probably by demanding an error handler
            try {
                await sessionService.login(userName);
                on.login();
                return true;
            } catch {
                return false;
            }
        },
        register: async (userName: string) => {
            // TODO: Make this function pass on more helpful error messages, probably by demanding an error handler
            try {
                await sessionService.register(userName);
                on.register();
                return true;
            } catch {
                return false;
            }
        }
    };
};

export const useAuth = () => React.useContext(ContextForAuth);
export const useTeacher = () => React.useContext(ContextForUser)?.teacher; // To make it clear that this must be extended in case others than teachers
export const useUpdateTeacher = () => React.useContext(ContextForUser)?.updateContext;

export const SignInProvider = (props: { children: React.ReactNode }) => {
    const [shouldFetch, setShouldFetch] = useState<boolean>(true);
    const [userContext, setUserContext] = useState<TeacherSessionContext>();
    const [authContext, setAuthContext] = useState<AuthContext>();

    const onSigninSucessful = useCallback(() => {
        setShouldFetch(true);
    }, []);
    const onRegisterSuccessful = useCallback(() => {
        setShouldFetch(true);
    }, []);
    const onSignoutSuccessful = useCallback(() => {
        setUserContext(undefined);
        setAuthContext(signedOutAuthContext({
            login: onSigninSucessful,
            register: onRegisterSuccessful
        }));
    }, [onSigninSucessful, onRegisterSuccessful]);

    useEffect(() => {
        async function checkLoggedIn() {
            if (shouldFetch) {
                setShouldFetch(false);

                const teacherContext = await sessionService.getLoggedInTeacher();
                if (!!teacherContext) {
                    setUserContext(teacherContext)
                    setAuthContext(
                        signedInAuthContext({
                            logout: onSignoutSuccessful
                        }));
                } else {
                    setUserContext(undefined);
                    setAuthContext(
                        signedOutAuthContext({
                            login: onSigninSucessful,
                            register: onRegisterSuccessful
                        }));
                }
            }
        }
        checkLoggedIn();
        // TODO Return function to cancel request
    }, [shouldFetch]);

    return (
        <ContextForAuth.Provider value={authContext}>
            <ContextForUser.Provider value={{ teacher: userContext, updateContext: setUserContext }}>
                {props.children}
            </ContextForUser.Provider>
        </ContextForAuth.Provider>
    );
}; 