import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import dictionary from 'translations/dictionary.sv.json';
import SimpleSelect, { SelectOption } from 'ux_components/select'
import { ClassBase } from '@viewmodel/school'
import { useTeacher } from 'providers/SignInProvider';
import { StudentBase } from '@viewmodel/users';
import studentService from 'services/studentService';
import ChooseSubarea, { getTaskCount } from '../../components/ChooseSubarea/ChooseSubarea';
import { AreaNode } from '@viewmodel/areaNode';
import sendHomeworkEmail, { previewHomeworkEmail } from './HomeworkEmail/sendHomeworkEmail';
import PreviewEmail, { emailPreview, CreateEmailPreview } from '../../components/EmailPreview/EmailPreview';
import areaNodeService from 'services/areaNodeService';
import { TeacherSessionContext } from '@viewmodel/session';
import { useGameGlobals } from 'App';
import taskService from 'services/taskService';
import styles from "./CreateHomework.module.scss";
import { TaskDefinition } from '@task';
import { WriteSentence } from '@task/TaskUtils';
import refreshSvg from './refresh_flat.svg';
import LoadingPage from 'components/LoadingPage/LoadingPage';

function RenderStudent(props: { student: StudentBase, selectedStudents: StudentBase[], handleSelectStudent: (studentId: string) => void }) {
    const { student, selectedStudents, handleSelectStudent } = props;
    return <div className=''>
        <input type="checkbox" className='' id={"selectedStudentCheckboxId" + student.id} checked={selectedStudents.some(ss => ss.id === student.id)} onChange={() => handleSelectStudent(student.id)} />
        <label className='ps-2' htmlFor={'selectedStudentCheckboxId' + student.id}>{student.fullName && student.fullName.trim() !== '' ? student.fullName : student.email}</label>
    </div>
}

type HomeworkDetailsProps = {
    namePlaceholder: string,
    name: string,
    setName: (name: string) => void,
    description: string,
    setDescription: (description: string) => void,
    amountOfQuestions?: number,
    amountOfQuestionsOptions: SelectOption<number>[],
    setAmountOfQuestions: (amount: number) => void,

}

function HomeworkDetails(props: HomeworkDetailsProps) {
    const { namePlaceholder, name, setName, description, setDescription, amountOfQuestions, amountOfQuestionsOptions, setAmountOfQuestions } = props
    return (<>
        <div className="row mb-3">
            <div className="col-12 col-md-6">{dictionary.HomeworkName}</div>
            <div className="col-12 col-md-6">
                <input className="col-12" type="text" value={name} onChange={(event) => setName(event.target.value)} placeholder={namePlaceholder} />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col-12 col-md-6" >{dictionary.HomeworkDescription}</div>
            <div className="col-12 col-md-6">
                <input type="text" className="col-12" value={description} onChange={(event) => setDescription(event.target.value)} />
            </div>
        </div>
        <div className="row mb-3">
            <div className="col-12 col-md-6">{dictionary.AmountOfQuestionsInHomework}</div>
            <div className="col-12 col-md-6">
                <select className="col-12" value={amountOfQuestions} onChange={(event) => setAmountOfQuestions(+event.target.value)}>
                    {amountOfQuestionsOptions.map((aq, index) => <option key={index} value={aq.value}>{aq.label}</option>)}
                </select>
            </div>
        </div>
    </>)
}

function HandleClass(props: {
    classOption: SelectOption<ClassBase>[],
    studentClass: ClassBase | undefined,
    setStudentClass: (classBase: ClassBase) => void,
    classStudents: StudentBase[] | undefined,
    selectedStudents: StudentBase[],
    handleSelectStudent: (studentId: string) => void,
}) {
    const { classOption, studentClass, setStudentClass, classStudents,
        selectedStudents, handleSelectStudent
    } = props
    return (<>
        {dictionary.SelectTheClassYouWantToAssignTheHomeworkToo}, {dictionary.OrCreate} <a href="InviteStudents">{dictionary.NewClass}</a>
        <div className='row'>
            <div className='mt-md-1 col-12 col-md-6 mb-3'>
                {classOption.length > 0 ? classOption.map((co, index) => <div key={index}><input radioGroup='classOptions' id={`classOptions-${index}`} className='me-2' type="radio" onChange={() => (setStudentClass(co.value))} checked={studentClass?.id === co.value.id} /><label htmlFor={`classOptions-${index}`}>{co.label}</label><br /></div>)
                    : <div>{dictionary.NoClassesFound}</div>}
            </div>
        </div>
        {studentClass && <>
            {dictionary.StudentToReceiveHomeworks}:
            {classStudents ? <div>
                {classStudents.map((student, index) =>
                    <RenderStudent key={index} student={student} selectedStudents={selectedStudents ?? []} handleSelectStudent={handleSelectStudent} />)}
            </div> : <div>{dictionary.GettingStudents}</div>}
        </>}
    </>)
}

function EmailPreview(props: { selectedStudents: StudentBase[], emailPreview: emailPreview | undefined, emailPreviewStatusMessage: string | undefined, submitMessage: string, handleMessage: (event: any) => void, sendEmail: () => void, sending: boolean }) {
    const { selectedStudents, emailPreview, emailPreviewStatusMessage, submitMessage, handleMessage, sendEmail, sending } = props;

    return <>
        <textarea className=' col-12 col-md-4 mb-3' placeholder={dictionary.AddOptionalMessageAboutHomeworkHere} onChange={(event) => handleMessage(event)} />
        <br />
        <h5>{dictionary.PreviewEmail}</h5>
        {emailPreviewStatusMessage && <div>{emailPreviewStatusMessage}</div>}
        {emailPreview && <div className=''><PreviewEmail preview={{ ...emailPreview, toAddresses: selectedStudents.map(s => s.email) }} /></div>}
        <br />
        <button className='btn btn-primary col-12 col-md-6' onClick={() => sendEmail()} disabled={sending}>{dictionary.Send}</button> <br />
        <span>
            {submitMessage === 'complete' && <>{dictionary.MessageSent}<br /></>}
            {submitMessage === 'failed' && <>{dictionary.FailedToSend}<br /></>}
            {submitMessage === 'submitting' && <>{dictionary.Sending}<br /></>}
        </span>
        <br />
    </>
}

function HomeworkPreview(props: { node: AreaNode, nodesTaskCount: { node: string, count: number }[] | undefined, amountOfQuestions: number, setSelectedQuestions: (value: string[]) => void },) {
    const { node, nodesTaskCount, amountOfQuestions, setSelectedQuestions } = props
    const [selectNone, setSelectNone] = useState(false)
    const [tasks, setTasks] = useState<(TaskDefinition & { selected: boolean, rejected: boolean })[] | undefined>()
    const [displayedTasks, setDisplayedTasks] = useState<(TaskDefinition & { selected: boolean, rejected: boolean })[]>([])
    const [gettingTasks, setGettingTasks] = useState(false)
    // const displayedTasks2 = useMemo(() => tasks ? tasks.filter(t => t.selected) : [], [tasks])


    const handleTaskCount = (node: AreaNode, nodesTaskCount: { node: string, count: number }[]) => {
        return tasks?.filter(t => t.selected).length ?? 0
    }

    const displayTask = (taskDef: TaskDefinition) => {
        switch (taskDef.type) {
            case 'FillGaps':
                const fillGapSentence = taskDef.words.map((w, index) => taskDef.gaps[index] ? `§§${w}§§` : w).join(' ')
                const fillGapSplitSentence = fillGapSentence.split(/(§§.+?§§)/g)
                return <>{fillGapSplitSentence.map((seg, index) => seg.startsWith('§§')
                    ? <select key={index} value={seg.replaceAll('§§', '')} style={{ fontWeight: 'bold' }} >
                        {taskDef.options.map((o, index) => <option style={{ fontWeight: o === seg.replaceAll('§§', '') ? 'bold' : 'normal' }} key={index}>{o}</option>)}
                    </select> : <WriteSentence key={index} sentenceFragment={seg} />)}
                </>
            case 'FreeText':
                const freeTextSplitSentence = taskDef.sentence.split(/(§§.+?§§)/g);
                return <>{freeTextSplitSentence.map((seg, index) => seg.startsWith('§§')
                    ? seg.replaceAll('§§', '').length === 1
                        ? <span style={{ borderBottom: '1px solid black', backgroundColor: 'white', opacity: '65%' }}>{seg.replaceAll('§§', '')}</span>
                        : <input key={index} type="text" value={seg.replaceAll('§§', '')} disabled />
                    : <WriteSentence key={index} sentenceFragment={seg} />)}</>
            case 'MultipleChoice':
                return <>
                    <WriteSentence sentenceFragment={taskDef.question} />
                    {taskDef.alternatives.map((a, index) => <><br />- {a.isCorrect ? <b><WriteSentence key={index} sentenceFragment={a.answer} /></b> : <WriteSentence key={index} sentenceFragment={a.answer} />}</>)}
                </>

            default:
                return <></>;
        }
    }

    const selectTasks = (event: React.MouseEvent, taskIndex: number, value?: boolean) => {
        const tempDisplayedTasks = displayedTasks?.slice()
        if (tasks === undefined) return;
        const filteredTask = tasks?.filter(t => !t.selected && !t.rejected)[0]
        tempDisplayedTasks[taskIndex].rejected = true;
        setDisplayedTasks(tempDisplayedTasks)
        setSelectedQuestions(tempDisplayedTasks.map(t => t.id))
        setTimeout(() => {
            const rejectedTask = tempDisplayedTasks?.splice(taskIndex, 1, { ...filteredTask, selected: true })
            setDisplayedTasks(tempDisplayedTasks)
            setSelectedQuestions(tempDisplayedTasks.map(t => t.id))
            setTasks(tasks?.map((t, i) => t.id === rejectedTask[0].id ? { ...t, selected: false, rejected: true }
                : t.id === filteredTask?.id ? { ...t, selected: true } : t))
            if (tasks.filter(t => !t.selected && !t.rejected).length === 10) {
                getTasks();
            }
        }, 400);
    }

    const getTasks = () => {
        taskService.getbyFilter({ id: tasks ? tasks[tasks.length - 1].id : undefined, limit: 25, nodes: `${node.id}` }).then(result => {
            setTasks(r => r?.concat(result?.map(r => ({ ...r.data, id: r.id, selected: false, rejected: false }))))
        }).finally(() => setGettingTasks(false))
    }

    useEffect(() => {
        setGettingTasks(true)
        if (!tasks) {
            taskService.getbyFilter({ limit: (amountOfQuestions ?? 25) + 25, nodes: `${node.id}` }).then(result => {
                const tasks = result.map((r, i) => ({ ...r.data, id: r.id, selected: i < (amountOfQuestions ?? 25), rejected: false }))
                setTasks(t => t ? t?.concat(tasks) : tasks)
                setDisplayedTasks(tasks.filter(r => r.selected))
                setSelectedQuestions(tasks.filter(r => r.selected).map(r => r.id))
            }).finally(() => setGettingTasks(false))
        } else if (displayedTasks.length < amountOfQuestions) {
            const tempDisplayedTasks = tasks.filter(r => r.selected).slice(0, amountOfQuestions)
            const tempTasks = tasks.filter(r => !r.selected && !r.rejected).slice(0, amountOfQuestions - tempDisplayedTasks.length)
            const newDisplay = tempDisplayedTasks.concat(tempTasks);
            setTasks(t => t?.map(r => newDisplay.some(td => td.id === r.id) ? { ...r, selected: true } : { ...r, selected: false }))
            setDisplayedTasks(newDisplay)
            setSelectedQuestions(newDisplay.map(r => r.id))
            if (newDisplay.length < amountOfQuestions) {
                taskService.getbyFilter({ id: tasks[tasks.length - 1].id, limit: amountOfQuestions - newDisplay.length + 25, nodes: `${node.id}` }).then(result => {
                    const newTasks = result.map((r, i) => ({ ...r.data, id: r.id, selected: i < (amountOfQuestions - newDisplay.length), rejected: false }))
                    setTasks(r => r?.concat(newTasks))
                    setDisplayedTasks(newDisplay.concat(newTasks.filter(r => r.selected)))
                    setSelectedQuestions(newDisplay.concat(newTasks.filter(r => r.selected)).map(r => r.id))
                })
            }
        } else {
            const tempDisplayedTasks = tasks.filter(r => r.selected).slice(0, amountOfQuestions)
            setTasks(t => t?.map(r => tempDisplayedTasks.some(td => td.id === r.id) ? { ...r, selected: true } : { ...r, selected: false }))
            setDisplayedTasks(tempDisplayedTasks)
            setSelectedQuestions(tempDisplayedTasks.map(r => r.id))
        }
    }, [node, amountOfQuestions, setSelectedQuestions])

    return (<div className="ms-3">
        <h2>{dictionary.Preview}</h2>
        <b>
            {dictionary.HomeworkName}: {node?.headline} <br />
            {dictionary.HomeworkLength}: {nodesTaskCount ? handleTaskCount(node, nodesTaskCount) : ''}
        </b>
        {tasks ? <div>
            {tasks.length > 0 ? <div>
                <div className='row'>
                    <div className='row col col-lg-7 ms-1' style={{ border: 'solid 1px #f67535', borderLeft: '', paddingLeft: 0 }}>
                        <div className='col-2 text-center' style={{ borderRight: 'solid 1px #f67535', padding: 0 }}>
                            <b>{dictionary.Replace}</b>
                        </div>
                        <div className='col'><b>{dictionary.Tasks}</b></div>
                    </div>
                </div>
                {displayedTasks.map((task, index) => {
                    return <div key={index} id={`display-task-${index}`} className='row mt-2' style={{ paddingLeft: 0 }} >
                        <div className="row col col-lg-7 ms-1" style={{ borderBottom: 'solid 1px #f67535', padding: '5px' }}>
                            <div className={'col-2 text-center ' + styles.rotateImageContainer} >
                                <img src={refreshSvg} alt='refresh' className={` ${task.rejected ? styles.rotateFast : styles.rotateImage}`} style={{ cursor: 'pointer' }} onClick={(event) => selectTasks(event, index)} />
                            </div>
                            <div className={`col ${task.rejected ? styles.fadeOut : ''}`} >{displayTask(task)}</div>
                        </div>
                    </div>
                })}
                {/* <div><button className='btn btn-primary' onClick={() => getTasks()} disabled={gettingTasks}>Get 25 more</button></div> */}
            </div> : <div>{dictionary.NoTasksFound}</div>}
        </div> : <>
            <div>{dictionary.GettingTasks}</div>
            <LoadingPage />
        </>}
    </div>)
}

export function CreateHomework() {
    const teacher = useTeacher();
    const [studentClass, setStudentClass] = useState<ClassBase | undefined>(undefined);
    const [classStudents, setClassStudents] = useState<StudentBase[] | undefined>(undefined);
    const [selectedStudents, setSelectedStudents] = useState<StudentBase[]>([]);
    const [node, setNode] = useState<AreaNode | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [emailPreview, setEmailPreview] = useState<emailPreview | undefined>(undefined);
    const [previewMessageStartIndex, setMessageStartIndex] = useState<number>(-1);
    const [gettingPreview, setGettingPreview] = useState(false);
    const [emailPreviewStatusMessage, setEmailPreviewStatusMessage] = useState<string | undefined>(undefined);
    const submitState = useRef<'' | 'submitting' | 'failed' | 'complete'>('');
    const [submitMessage, setSubmitMessage] = useState(submitState.current);
    const [nodes, setNodes] = useState<AreaNode[] | undefined>(undefined);
    const [recommendedNodes, setRecommendedNodes] = useState<AreaNode[] | undefined>(undefined);
    const [nodesTaskCount, setNodesTaskCount] = useState<{ node: string, count: number }[] | undefined>(undefined);
    const [sending, setSending] = useState(false);
    const [date, setDate] = useState(new Date());
    const [namePlaceholder, setNamePlaceHolder] = useState<string>('Homework name');
    const [name, setName] = useState<string>();
    const [description, setDescription] = useState('');
    const [amountOfQuestions, setAmountOfQuestions] = useState<number | undefined>(25);
    const amountOfQuestionsOptions = useMemo<SelectOption<number>[]>(() => [
        // { value: -1, label: 'Alla som finns' },
        { value: 10, label: '10' },
        { value: 25, label: '25' },
        { value: 50, label: '50' },
        { value: 100, label: '100' },
    ], []);
    const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);

    const recommendedNodeStrings = [
        { headline: 'Särskrivning', description: '25 vanliga särskrivningsmisstag och betydelseskillnaden som uppstår (blå-svart övning)' },
        { headline: 'Förled och efterled', description: '20 vanliga sammansatta ord och skillnaden som uppstår om man byter plats på ordleden, t.ex. motorbåt <=> båtmotor (blå/vit-övning)' },
        { headline: 'Svåra ord', description: 'Sveriges 200 mest felstavade ord (svart övning)' },
    ]

    const gameGlobals = useGameGlobals()

    useEffect(() => {
        setNamePlaceHolder(`${date?.toISOString().split('T')[0]}_${node?.headline.toLocaleLowerCase()}_${studentClass ? studentClass.headline.toLocaleLowerCase() : ''}`)
    }, [date, node, studentClass])

    const classOption = useMemo<SelectOption<ClassBase>[]>(() => {
        return (teacher?.school?.classes || []).map(c => ({ value: c, label: c.headline }))
    }, [teacher])

    const handleNode = useCallback((node: AreaNode) => {
        setNode(node);
    }, [])

    const checkNode = useCallback((id: number) => {
        return node?.id === id
    }, [node])

    const previewEmail = async () => {
        if ((!node || !classStudents)) return
        setEmailPreviewStatusMessage(dictionary.GettingPreview)
        setEmailPreview(undefined)
        const studentIds = selectedStudents.map((student) => student.id);
        try {
            const data = { node_id: node.id.toString(), amount_of_questions: amountOfQuestions, name: name ?? namePlaceholder, description, }
            const result = await (await previewHomeworkEmail(studentIds, studentClass, teacher, message, data)).json()
            const emailPreview = CreateEmailPreview(result)
            setEmailPreview(emailPreview)
            setEmailPreviewStatusMessage(undefined);
        } catch {
            setEmailPreviewStatusMessage(dictionary.FailedToGetPreview)
        }

    }

    const sendEmail = async () => {
        if ((!node || !classStudents)) return
        setSending(true)
        submitState.current = 'submitting';
        setSubmitMessage(submitState.current);

        const studentIds = selectedStudents.map((student) => student.id);
        try {
            const data = {
                node_id: node.id.toString(),
                amount_of_questions: amountOfQuestions,
                name: name ?? namePlaceholder,
                description,
                questions: selectedQuestions,
            }
            const response = await sendHomeworkEmail(studentIds, studentClass, teacher, message, data)
            submitState.current = response.ok ? 'complete' : 'failed'
        } catch (error) {
            console.error(error);
            submitState.current = 'failed';
        }
        finally {
            setSending(false)
            window.dataLayer.push({ event: 'createHomework', createHomeworkProps: { node: { id: node.id, name: node.headline }, school: { id: teacher?.school.id, name: teacher?.school.name }, status: submitState.current } });
            setSelectedQuestions([]);
            setSelectedStudents([]);
            setName(undefined);
            setDescription('');
            setMessage(undefined);
            setEmailPreview(undefined);
            setStudentClass(undefined);
            setAmountOfQuestions(25);
            setNode(undefined)

        }
        console.log('state', submitState.current)
        setSubmitMessage(submitState.current);
    }

    const handleSelectStudent = (studentId: string) => {
        const emailCopy = Object.assign({}, emailPreview)
        if (selectedStudents.some(ss => ss.id === studentId)) {
            setSelectedStudents(selectedStudents.filter(stu => stu.id !== studentId))
            emailCopy.toAddresses = selectedStudents.filter(stu => stu.id !== studentId).map(s => s.email);

        } else {
            const student = classStudents?.find(cs => cs.id === studentId)
            if (student) {
                const tempSelected = selectedStudents.slice();
                tempSelected.push(student)
                setSelectedStudents(tempSelected)
                emailCopy.toAddresses = tempSelected.map(s => s.email);
            }
        }
        setEmailPreview(emailCopy)
    }

    const handleMessage = (event: any) => {
        const newMessage = event.target.value
        event.preventDefault()
        if (emailPreview && emailPreview.messageText) {
            const emailCopy = Object.assign({}, emailPreview)
            if (newMessage === '') {
                emailCopy.messageText = emailCopy.messageText
                    .replace(`Din lärare skriver: \r\n ${message}`, '')
                setMessage(newMessage)
                setEmailPreview(emailCopy)
            }
            else if (newMessage && newMessage !== message) {
                emailCopy.messageText = emailCopy.messageText.includes('Din lärare skriver:') ? emailCopy.messageText.replace(`Din lärare skriver: \r\n ${message}`,
                    `Din lärare skriver: \r\n ${newMessage}`)
                    : emailCopy.messageText.substring(0, previewMessageStartIndex) + `Din lärare skriver: \r\n ${newMessage}` + emailCopy.messageText.substring(previewMessageStartIndex)
                setMessage(newMessage)
                setEmailPreview(emailCopy)
            }
        }
        else setMessage(newMessage)
    }

    useEffect(() => {
        if (node && studentClass && !gettingPreview && !emailPreview) {
            try {
                setGettingPreview(true);
                const data = { node_id: node.id.toString() };
                previewHomeworkEmail([], studentClass, teacher, 'Lorem Ipsum', data).then(result => {
                    let emailPreview = CreateEmailPreview(result)
                    const index = emailPreview.messageText.indexOf('Din lärare skriver:')
                    emailPreview.messageText = emailPreview.messageText.replace('Din lärare skriver: \r\n', '')
                    emailPreview.messageText = emailPreview.messageText.replace('Lorem Ipsum', '')
                    setMessageStartIndex(index)
                    setEmailPreview(emailPreview)
                    setEmailPreviewStatusMessage(undefined)
                    setGettingPreview(false)
                })
            } catch (error) {
                setEmailPreviewStatusMessage(dictionary.FailedToGetPreview)
                setGettingPreview(false)
            }
        }
    }, [studentClass, emailPreview, emailPreviewStatusMessage, previewMessageStartIndex, teacher, node, gettingPreview])

    useEffect(() => {
        let running = true;
        if (!nodes && gameGlobals && gameGlobals.getAreaNodes) {
            gameGlobals.getAreaNodes(false).then(result => {
                if (running) {
                    setNodes(result)
                    setRecommendedNodes(recommendedNodeStrings.map(rns => findNode(result, rns.headline)).filter(rn => rn !== undefined) as AreaNode[]);
                }
                taskService.getTaskCountByNode().then(result => {
                    setNodesTaskCount(result)
                })
            })
            // areaNodeService.getAreaNodes()
            //     .then(nodes => {
            //         if (running) {
            //             setNodes(nodes)
            //         }
            //     })

        }
        if (studentClass) {
            setClassStudents(undefined)
            setSelectedStudents([])
            studentService.getStudentsbyClassId(studentClass.id)
                .then(students => {
                    if (running) {
                        setClassStudents(students.filter(s => s.created_event))
                        setSelectedStudents(students.filter(s => s.created_event))
                    }
                });
        }
        return () => { running = false; };
    }, [nodes, studentClass])

    function findNode(nodes: AreaNode[], headline: string): AreaNode | undefined {
        for (const node of nodes) {
            if (node.headline.toLowerCase() === headline.toLowerCase()) {
                return node;
            };

            if (node.subareas) {
                const subResult: AreaNode | undefined = findNode(node.subareas, headline);
                if (subResult) {
                    return subResult;
                }
            }
        }
        return undefined;
    }

    return (<>
        <h2>{dictionary.CreateHomework}</h2>
        {!node && <>
            <div><b>{dictionary.HomeworkText1}</b></div>
            <div>{dictionary.HomeworkText2}</div>
            <br />
            <div>{dictionary.HomeworkText3}</div>
            <br />
            {recommendedNodes && recommendedNodes.length > 0 && <div>
                <h4>{dictionary.ElevertRecommends}</h4>
                {recommendedNodes.map((rn, index) => {
                    const rns = recommendedNodeStrings.find(rns => rns.headline === rn.headline);
                    return <div key={index} className="row">
                        <div className='col-1 text-center'>-</div>
                        <div className='col'>
                            <span className={styles.recommendedNodeName} onClick={() => { handleNode(rn) }}>{rn.headline}</span> - {rns?.description}
                        </div>
                    </div>
                })}
            </div>}
            <ChooseSubarea headline={dictionary.OtherSubjectsAreas} preamble={<></>} nodes={nodes} checkNode={checkNode} setNode={handleNode} showMyNodesButton={false} uninteractNodes={undefined} nodeTaskCounts={nodesTaskCount} ></ChooseSubarea>
        </>}
        {node && <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <h4>{dictionary.ChosenArea}: {node?.headline} </h4>
                    <HomeworkDetails {...{ namePlaceholder, name: name ?? '', setName, description, setDescription, amountOfQuestions, amountOfQuestionsOptions, setAmountOfQuestions }} />
                    <HandleClass {...{ classOption, studentClass, setStudentClass, classStudents, selectedStudents, handleSelectStudent, handleMessage, emailPreview, emailPreviewStatusMessage, submitMessage, sendEmail, sending }} />
                    {selectedStudents.length > 0 && <EmailPreview {...{ selectedStudents, emailPreview, emailPreviewStatusMessage, submitMessage, handleMessage, sendEmail, sending }} />}
                </div>
                {<div className="col col-md-6">
                    {amountOfQuestions && <HomeworkPreview {...{ node, nodesTaskCount, amountOfQuestions, setSelectedQuestions }} />}
                </div>}
            </div>
        </>}
    </>)
}