import { FillGapsTaskDefinition, TaskDefinition } from "@task";
import { SimpleXlsxFile } from "components/SimpleXlsx/SimpleXlsxFileInput";

function ImportTasksFromExcel<T>(xlsxFiles: readonly SimpleXlsxFile[],
     createTask: (sentence: string) => T) {
    const tasks: T[] = []

    for (const file of xlsxFiles) {
        if (!file.sheets) {
            // if `file.sheets` is null, the file was not a valid `.xlsx` file
            // TODO: display an error to the user?
            continue
        }
        let nrOfBlankRows = 0;
        for (const sheet of file.sheets) {
            for (const row of sheet.rows) {
                const sentence = row[0] as string
                if (nrOfBlankRows > 10) break;
                if (sentence === undefined || sentence.trim() === '') {
                    nrOfBlankRows++;
                    continue;
                }
                console.log('sentence', sentence)
                tasks.push(createTask('' + sentence))

                // for (const col of row) {
                //     if (col != null && col !== '') {
                //         tasks.push(createTask('' + col))
                //     }
                // }
            }
        }
    }

    return tasks;
}

export default ImportTasksFromExcel;