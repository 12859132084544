import { StudentBase, TeacherBase } from '@viewmodel/users';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import userService from 'services/sessionService';
import studentSevice from 'services/studentService';
import TeacherRoleService from 'services/teacherRoleService';

type handleStudentBase = StudentBase & {
    isChecked: boolean
}

type handleTeacherBase = TeacherBase & {
    isChecked: boolean
}

type handleUserBase = TeacherBase & {
    isChecked: boolean
}


type ShowRoleProps<T> = {
    people: T[] | undefined,
    handleSubmit: (people: T[]) => void
    selectPerson: (id: string, people: T[]) => void
    isGetting: boolean
    personLabel: string
    disableChoice: boolean
}

const ShowPeople = (props: ShowRoleProps<handleStudentBase | handleTeacherBase>) => {
    const { people, handleSubmit, selectPerson, isGetting, personLabel, disableChoice } = props
    return (
        <div>
            <h4>{personLabel}</h4>
            {people && <>
                {people.length > 0 ? <div>
                    <ul>
                        {people.map(s => <li>id: {s.id}, name: {s.fullName},  email: {s.email} {!disableChoice && <input type='checkbox' onChange={() => selectPerson(s.id, people)} checked={s.isChecked} />}</li>)}
                    </ul>
                    {!disableChoice && <>
                        {<div>selected {personLabel}: {people.filter(s => s.isChecked).map(s => s.email).join(',')} </div>}
                        {people.some(s => s.isChecked) && <button className='btn btn-primary' onClick={() => handleSubmit(people)} disabled={isGetting}>Delete Selected</button>}
                    </>}
                </div> : <div>No {personLabel} found</div>}
            </>}
            {!people && <div>Getting {personLabel}</div>}

        </div>
    )
}

const tabs = ['None', 'Students', 'Teachers', 'Users', 'Teachers per school'] as const
export type Tabs = (typeof tabs)[number];
const isTab = (x: any): x is Tabs => tabs.includes(x);
const isTabType = (x: any, y: Tabs): x is Tabs => x === y;

function HandlePeople() {
    const [students, setStudents] = useState<handleStudentBase[]>()
    const [teachers, setTeachers] = useState<handleTeacherBase[]>()
    const [teachersInfo, setTeachersInfo] = useState<{ full_name: string, short_name: string, email: string, mobile: string, skola: string }[]>()
    const [users, setUsers] = useState<handleUserBase[] | undefined>()
    const [started, setStarted] = useState<boolean>(false)
    const [isGetting, setIsGetting] = useState<boolean>(false)
    const [chosenTab, setChosenTab] = useState<Tabs>('None')
    const params = useParams();
    const { subtab } = params;

    const GetTab = () => {
        let tab = <div style={{ textAlign: 'center' }}>Select a tab</div>
        chosenTab !== 'None' ? window.history.pushState({ tab: chosenTab }, `Admin/People/${chosenTab}`, `/Admin/People/${encodeURIComponent(chosenTab)}`)
            : window.history.pushState({ tab: chosenTab }, `Admin People`, `/Admin/People`)
        switch (chosenTab) {
            case 'Students':
                tab = <ShowPeople {...{ people: students, handleSubmit: handleStudentSubmit, selectPerson: selectStudent, isGetting, personLabel: 'students', disableChoice: false }} />
                break;
            case 'Teachers':
                tab = <ShowPeople {...{ people: teachers, handleSubmit: () => { }, selectPerson: () => { }, isGetting, personLabel: 'teachers', disableChoice: true }} />
                break;
            case 'Users':
                tab = <ShowPeople {...{ people: users, handleSubmit: handleUserSubmit, selectPerson: selectUser, isGetting, personLabel: 'users', disableChoice: false }} />
                break;
            case 'Teachers per school':
                tab = <div>{teachersInfo ?
                    teachersInfo.length > 0 ? teachersInfo.map((ti, index) => <div key={index} className='mb-3'>
                        <div key={index} className='row'>
                            <div className="col-4"><b>full name</b>: {ti.full_name ?? 'Not given'}</div>
                            <div className="col-4"><b>short name</b>: {ti.short_name ?? 'Not given'}</div>
                            <div className="col-4"><b>school</b>: {ti.skola ?? 'Not given'}</div>
                        </div>
                        <div key={index} className='row'>
                            <div className="col-4"><b>email</b>: {ti.email ?? 'Not given'}</div>
                            <div className="col-4"><b>telephone</b>: {ti.mobile ?? 'Not given'}</div>
                        </div>
                    </div>) : <div>No teachers found</div>
                    : <div>Getting teachers</div>}</div>
                break;
            case 'None':
            default:
                <div>Select a tab</div>
                break;
        }
        return tab;
    }

    useEffect(() => {
        if (subtab) {
            setChosenTab(isTab(subtab) ? subtab : 'None')
        }
    }, [subtab])


    useEffect(() => {
        if (!started) {
            setStarted(true)
            if (!students) {
                setIsGetting(true)
                studentSevice.getStudents().then(response => {
                    setStudents(response.map(r => ({ ...r, isChecked: false })))
                    setIsGetting(false)
                })
            }
            if (!teachers) {
                setIsGetting(true)
                TeacherRoleService.getTeachers().then(response => {
                    setTeachers(response.map(r => ({ ...r, isChecked: false })))
                    setIsGetting(false)
                })
            }
            if (!users) {
                setIsGetting(true)
                userService.getUsers().then(response => {
                    setUsers(response.map(r => ({ ...r, isChecked: false })))
                    setIsGetting(false)
                })
            }
            if (!teachersInfo) {
                setIsGetting(true)
                TeacherRoleService.getTeachersInfo().then(response => {
                    setTeachersInfo(response)
                    setIsGetting(false)
                })
            }
        }

    }, [students, teachers, users, teachersInfo, started])


    const selectStudent = (id: string, students: handleStudentBase[]) => {
        const tempStudents = students.slice()
        setStudents(tempStudents.map(ts => (ts.id === id ? { ...ts, isChecked: !ts.isChecked } : ts)))
    }

    const handleStudentSubmit = (students: handleStudentBase[]) => {
        setIsGetting(true)
        studentSevice.deleteStudents(students.filter(s => s.isChecked).map(s => s.id)).then(result =>
            studentSevice.getStudents().then(response => {
                setStudents(response.map(r => ({ ...r, isChecked: false })))
                setIsGetting(false)
            })
        )
    }

    const selectUser = (id: string, users: handleUserBase[]) => {
        const tempUsers = users.slice()
        setUsers(tempUsers.map(tu => (tu.id === id ? { ...tu, isChecked: !tu.isChecked } : tu)))
    }

    const handleUserSubmit = (users: handleUserBase[]) => {
        setIsGetting(true)
        userService.deleteUsers(users.filter(s => s.isChecked).map(s => s.id)).then(result => {
            // window.location.reload()
            setIsGetting(false)
            // userService.getUsers().then(response => {
            //     setUsers(response.map(r => ({ ...r, isChecked: false })))
            //     setIsGetting(false)
            // })
        })
    }

    return <div>
        <div className="text-center mb-3">
            {tabs.map((t, index) => (
                !isTabType(t, 'None') && <span key={index} className="me-5" style={{ cursor: 'pointer', textDecoration: 'underline', marginRight: '10px' }}
                    onClick={() => setChosenTab(t)}>{t}</span>
            ))}
        </div>
        <GetTab />
    </div>
}

export default HandlePeople;