import { RoleStatus, School } from "@viewmodel/school";

async function createInstitutions(schools: {name: string, postalCode: string, county: string, homepage: string, status?: RoleStatus}[]): Promise<{ id: string }> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/institution`, {
        method: 'post',
        credentials: 'include',
        body: JSON.stringify(schools)
    });
    return response.json();
}

async function getInstitutions(statuses?: RoleStatus[]): Promise<School[]> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/institution?${statuses ? `status=${statuses?.join(',')}` : ''}`, {
        method: 'get',
        credentials: 'include',
    })
    return response.json()
}

async function getInstitutionsCountByDate(date: Date, limit: number) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/institution/count/date?date=${date.toISOString()}&limit=${limit}`, {
        credentials: 'include'
    })
    return response.json();
}

async function updateInstitutionStatuses(updatedSchools: { id: string, status: RoleStatus }[]): Promise<School[]> {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/institution/status`, {
        method: 'put',
        credentials: 'include',
        body: JSON.stringify({ schools: updatedSchools }),
    })
    return response.json()
}

async function getInstitutionActivityCount(limit: number) {
    const response = await fetch(`${process.env.REACT_APP_API_BASE}/institution/count/activity?limit=${limit}`, {
        credentials: 'include'
    })
    return response.json();
}


export const InstitutionService = {
    createInstitutions,
    getInstitutions,
    getInstitutionsCountByDate,
    updateInstitutionStatuses,
    getInstitutionActivityCount,
}

export default InstitutionService