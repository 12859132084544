import { AreaNode } from '@viewmodel/areaNode'
import { SimpleXlsxSheet } from 'components/SimpleXlsx/simple-xlsx'
import SimpleXlsxFileInput, { SimpleXlsxFile } from 'components/SimpleXlsx/SimpleXlsxFileInput'
import React, { useState } from 'react'
import dictionary from 'translations/dictionary.sv.json'
import { FillGapsTaskDefinition, FreeTextTaskDefinition, MultipleChoiceTaskDefinition, TaskDefinition } from '@task';
import taskService from 'services/taskService'
import PrepareNodesForImport from './PrepareNodesForImport'


const ImportAssignmentsFromExcel = (props: { nodes: AreaNode[] | undefined, setNodes: (nodes: AreaNode[] | undefined) => void }) => {
    const [tasks, setTasks] = useState<{ data: Omit<TaskDefinition, 'id'>, sortRandom: boolean }[]>([])
    const [isWorking, setIsWorking] = useState<boolean>(false)
    const [uploadStatus, setUploadStatus] = useState<string | undefined>(undefined)
    const { nodes, setNodes } = props



    const handleTask = (type: string, sentence: string, correctAnswers: string[], allAnswers: string[], difficulty: number, comment: string, node: string, caseSensetive?: boolean): Omit<TaskDefinition, 'id'> | undefined => {
        switch (type.toLowerCase()) {
            case 'multiple':
            case 'multiplechoice':
            case 'multiple choice':
                const alternatives = correctAnswers.filter(ca => !allAnswers.some(alt => alt === ca))
                    .map(ca => ({ answer: ca, isCorrect: true }))
                    .concat(allAnswers
                        .map(alt => { return { answer: alt, isCorrect: correctAnswers.some(ca => ca === alt) } }))
                return { question: sentence, alternatives: alternatives, type: 'MultipleChoice', meta: { subarea: node, difficulty }, comment: comment, } as MultipleChoiceTaskDefinition
            case 'fritext':
            case 'fri text':
            case 'freetext':
            case 'free text':
                return { sentence: sentence, type: 'FreeText', meta: { subarea: node, difficulty }, caseSensetive: caseSensetive !== undefined ? caseSensetive : false, comment: comment } as FreeTextTaskDefinition
            case 'luckmening':
            case 'luck mening':
            case 'lucktext':
            case 'luck text':
            case 'fillgaps':
            case 'fill gaps':
                let words = sentence.split(' ')
                const gaps = []
                let isGap = false;
                for (let index = 0; index < words.length; index++) {
                    if (words[index].startsWith('§§')) { isGap = true }
                    gaps.push(isGap)
                    if (words[index].endsWith('§§')) { isGap = false }
                }
                words = words.map(word => word.replaceAll('§§', ''))
                return { words, gaps, comment, options: allAnswers, type: 'FillGaps', meta: { subarea: node, difficulty } } as FillGapsTaskDefinition
            default:
                console.warn('default', type)
                break;
        }
    }

    const handleSentece = (baseSentence: string, insertWord?: string, insertWord2?: string) => {
        let sentence = baseSentence
        sentence = insertWord ? sentence.replace('#', insertWord) : sentence
        sentence = insertWord2 ? sentence.replace('%', insertWord2) : sentence
        return sentence
    }

    const handleAssignmentRow = async (assignmentRow: (string | number)[]) => {
        const nodes = assignmentRow[0].toString().split('/')
        const node = nodes[nodes.length - 1]
        const type = assignmentRow[1].toString()
        const sentence = handleSentece(assignmentRow[2].toString(), assignmentRow[3].toString(), assignmentRow[4].toString())
        const correctAnswers = assignmentRow[5].toString().split(';').map(alt => alt.trim())
        const allAnswers = assignmentRow[6].toString().split(';').map(alt => alt.trim()).filter(alt => alt !== '')
        const difficulty = assignmentRow[7]
        const sortRandom = assignmentRow[8].toString().trim().length > 0
        //TODO Check which row for case sensetive
        const caseSensetive = assignmentRow[12] && assignmentRow[12].toString().trim().length > 0 ? true : false
        const comment = assignmentRow[13] ?? ''
        return { task: handleTask(type, sentence, correctAnswers, allAnswers, difficulty as number, comment.toString(), node, caseSensetive), sortRandom }
    }



    const handleSheet = async (sheet: SimpleXlsxSheet) => {
        if (sheet.rows[0][0] === 'Nod') {
            let nrOfEmptyRowsInARow = 0;
            const rows: (string | number)[][] = []

            for (let index = 1; index < sheet.rows.length; index++) {
                const row = sheet.rows[index]
                // Allow for spaces in between rows 
                if (!row[0] || row[0] === '') {
                    nrOfEmptyRowsInARow++;
                    if (nrOfEmptyRowsInARow === 11) break;
                    continue;
                }
                rows.push(row)
            }
            const nodesAndRules = rows.map((row) => {
                const nodePath = row[0].toString()
                let rule = { node: nodePath, text: '' }
                if (nodePath.trim() !== '') {
                    const nodeArray = nodePath.split('/')
                    rule = { node: nodeArray[nodeArray.length - 1], text: (row[9].toString().trim() !== '' || row[10].toString().trim() !== '' || row[11].toString().trim() !== '') ? `${row[9]}${row[10] ? ` | ${row[10]}` : ''}${row[11] ? ` | Länk: ${row[11]}` : ''}` : '' }
                }
                return { nodePath, rule }
            })
            const nodePaths = nodesAndRules.map(nr => nr.nodePath).filter(node => node.trim() !== '')
            const rules = nodesAndRules.map(nr => nr.rule).filter(r => r.node.trim() !== '' && r.text.trim() !== '')
            for (let i = 0; i < nodePaths.length; i++) {
                const firstPath = nodePaths[i].toLowerCase().split('/').reverse()
                for (let j = i + 1; j < nodePaths.length; j++) {
                    const secondPath = nodePaths[j].toLowerCase().split('/').reverse()
                    let tempConflictFound: boolean = false
                    firstPath.forEach((fPath, fIndex) => {
                        secondPath.forEach((sPath, sIndex) => {
                            if (fPath === sPath) {
                                const firstPathSub = firstPath.slice(fIndex).join('/')
                                const secondPathSub = secondPath.slice(sIndex).join('/')
                                if (!(firstPathSub === secondPathSub || firstPathSub.includes(secondPathSub) || secondPathSub.includes(firstPathSub))) {
                                    setUploadStatus(`found conflicting paths: "${nodePaths[i]}" and "${nodePaths[j]}"`)
                                    console.log(`found conflicting paths: "${nodePaths[i]}" and "${nodePaths[j]}"`)
                                    tempConflictFound = true;
                                }
                            }
                        })
                    });
                    if (tempConflictFound) {
                        return
                    }
                }
            }
            await PrepareNodesForImport.ensureNodesExist(nodePaths, nodes ?? [], rules, setNodes)

            const newTasks: { data: Omit<TaskDefinition, 'id'>, sortRandom: boolean }[] = []
            for (let index = 0; index < rows.length; index++) {
                const row = rows[index]
                const taskRow = await handleAssignmentRow(row)
                if (taskRow.task) {
                    newTasks.push({ data: taskRow.task, sortRandom: taskRow.sortRandom })
                }
            }
            return setTasks(t => t.concat(newTasks))
        }
    }

    const onXlsxFileInputChange = (xlsxFiles: readonly SimpleXlsxFile[]) => {
        setIsWorking(true)
        setUploadStatus(undefined)
        // const sheetPromises: Promise<{ data: TaskDefinition, sortRandom: boolean }[]>[] = []
        // xlsxFiles[0].sheets?.forEach(sheet => sheetPromises.push(handleSheet(sheet)))
        const sheets = xlsxFiles[0].sheets
        if (!sheets || sheets.length === 0) return

        handleSheet(sheets[0]).finally(() => setIsWorking(false))
        // await Promise.all(sheetPromises).then(result => {
        //     console.log('result', result)
        //     setTasks( t => t.concat(result.flat()))
        //     console.log('done')
        // }).catch(e => {
        //     console.error('error', e.message)
        // }).finally(() => setIsWorking(false))
    }

    const submit = async () => {
        try {
            setUploadStatus(dictionary.Uploading + '...')
        let index = 0;
        let count = 0;
        let sizeOfUploads = 100
        while (index <= tasks.length) {
            count++
            index += sizeOfUploads
        }
        index = 0;
        while (index + sizeOfUploads < tasks.length) {
            setUploadStatus(`${dictionary.Uploading}...(${index / sizeOfUploads + 1}/${count})`)
            await taskService.postTasks(tasks.slice(index, index + sizeOfUploads))
            index += sizeOfUploads
        }
        setUploadStatus(`${dictionary.Uploading}...(${count}/${count})`)
        await taskService.postTasks(tasks.slice(index, tasks.length))
        setUploadStatus(dictionary.UploadCompleted)
        setUploadStatus(undefined)
        } catch (error) {
            console.error(error);
            setUploadStatus('Failed');
        } finally {
            setTasks([])
        }
    }

    return <>
        <h4 className='mb-3'>{dictionary.CreateAssignmentsFromExcelFile}:</h4>
        {!isWorking ? <><SimpleXlsxFileInput onChange={onXlsxFileInputChange} />
            <div className='mt-3'>
                {tasks.length > 0 && <>
                    <div>{dictionary.NrOfTasksToUpload}: {tasks.length}</div>
                    {!uploadStatus && <div className='row mt-3'>
                        <button className='btn btn-primary col-md-2' onClick={() => submit()}>{dictionary.Upload}</button>
                        <button className='btn btn-secondary offset-1 col-md-2' onClick={() => setTasks([])}>{dictionary.Clear}</button>
                    </div>}
                </>}
                {uploadStatus && <div>{uploadStatus}</div>}
            </div>
        </> : <div>Working...</div>}
    </>
}

export default ImportAssignmentsFromExcel
