import React, { useRef, useState } from 'react';

import { TaskDefinition } from '@task';
import dictionary from 'translations/dictionary.sv.json';
import taskService from 'services/taskService';

type AssignmentPart = TaskDefinition[];

function SubmitAssignment(props: {
    parts: AssignmentPart[],
    meta: Required<TaskDefinition>['meta'],
    afterSubmit: () => void,
    handleExit: (isValid: boolean, onSubmit: () => void) => void
}) {
    const { parts, meta, afterSubmit, handleExit } = props;
    const submitState = useRef<'' | 'Submitting' | 'Failed' | 'Complete'>('');
    const [submitMessage, setSubmitMessage] = useState((dictionary as any)[submitState.current] ?? submitState.current);

    const checkMetadataValidity = (metaData?: Required<TaskDefinition>['meta']) => {
        if (!metaData) return false;
        console.log('metaData', metaData)
        return metaData.gradeYear?.trim() !== ''
            && metaData.difficulty
    }
    const checkIfValid = (part: TaskDefinition) => {
        if (!checkMetadataValidity(part.meta)) return false;
        switch (part.type) {
            case "FillGaps":
                part.options = part.options.filter(o => o.trim() !== '')
                return part.words.length > 0
                    && part.words.length === part.gaps.length
                    && part.words.every(w => w.trim() !== '')
                    && part.gaps.includes(true) && part.gaps.includes(false)
                    && part.options.length > 0
            case "MultipleChoice":
                part.alternatives = part.alternatives.filter(a => a.answer.trim() !== '')
                return part.question.trim() !== ''
                    && part.alternatives.length > 0
                    && part.alternatives.every(a => a.answer.trim() !== '')
                    && part.alternatives.some(a => a.isCorrect) && part.alternatives.some(a => !a.isCorrect)
            case "FreeText":
                return part.sentence.trim() !== ''
                    && (part.sentence.includes('§§')
                        && part.sentence.split(' ').some(t => t.startsWith('§§') && t.endsWith('§§') && t.length > 4)
                    )

            default:
                return false;
        }
    }

    const checkValidity = () => {
        return parts.length > 0
            && parts.every(p => p.length > 0)
            && parts.every(p => p.every(t => checkIfValid(t)))
    }

    const onSubmit = async () => {
        if (submitState.current === 'Submitting' || submitState.current === 'Complete') {
            return;
        }
        const isValid = checkValidity()
        if (!isValid) return;

        submitState.current = 'Submitting';
        setSubmitMessage((dictionary as any)[submitState.current] ?? submitState.current);
        // TODO: persist the assignment and connect each task to it by its primary key
        const tasks: TaskDefinition[] = ([] as TaskDefinition[]).concat(...parts)
            .map<TaskDefinition>(task => ({ ...task, meta: { ...task.meta, ...meta } }))

        try {
            let sizeOfUploads = 50
            let index = 0;
            let count = 0;
            while (index <= tasks.length) {
                count++
                index += sizeOfUploads
            }
            index = 0;
            while (index + sizeOfUploads < tasks.length) {
                setSubmitMessage(`${dictionary.Uploading}...(${index / sizeOfUploads + 1}/${count})`)
                await taskService.postTasks(tasks.slice(index, index + sizeOfUploads).map(task => ({ data: task, sortRandom: true })))
                index += sizeOfUploads
            }
            setSubmitMessage(`${dictionary.Uploading}...(${count}/${count})`)
            const response = await taskService.postTasks(tasks.slice(index, tasks.length).map(task => ({ data: task, sortRandom: true })))
            submitState.current = response.ok ? 'Complete' : 'Failed';
            if (response.ok) {
                alert('Sent')
                afterSubmit();
            }
        } catch (error) {
            console.error(error);
            submitState.current = 'Failed';
        }

        setSubmitMessage((dictionary as any)[submitState.current] ?? submitState.current);
    }

    return (<div>
        <nav>
            {submitMessage && <span>{dictionary.Status}: {submitMessage}<br /></span>}
            <button type="button" className='btn btn-primary col-5 col-md-2' onClick={onSubmit} disabled={!checkValidity()}>{dictionary.SaveAssignment}</button>
            <button type="button" className='btn btn-secondary offset-1 col-5 col-md-2' onClick={() => handleExit(checkValidity(), onSubmit)} >{dictionary.Cancel}</button>
        </nav>
    </div>)
}

export default SubmitAssignment;