import { StudentBase, TeacherBase } from "./users";

export const roleStatus = ['requested', 'approved', 'denied', 'archived'] as const
export type RoleStatus = typeof roleStatus[number];

export type ClassBase = {
    id: string,
    headline: string,
    students: StudentBase[],
};

export type TeacherClasses = TeacherBase & {
    classes: ClassBase[]
};

export type School = {
    id: string,
    name: string;
    classes: ClassBase[]; // TODO: Only classes visible to the signed in teacher, or all?
    teachers: TeacherBase[]; // TODO: Only teachers present in the list of classes?
    syllabi: string[];
    status: RoleStatus;
};
