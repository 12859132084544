import Task, { TaskDefinition, TaskType } from '@task';
import { TaskStatus } from '@viewmodel/tasks';
import { taskTypes } from 'pages/Assignment/CreateNewTask';
import React, { useEffect, useState } from 'react';
import taskService from 'services/taskService';
import dictionary from 'translations/dictionary.sv.json';

type TaskFeedback = {
    toBeClosed: boolean,
    id: string,
    data: TaskDefinition,
    status: TaskStatus,
    newStatus: TaskStatus,
    report_count: number,
    newest_report: Date
    toBeDeleted: boolean

}

function HandleTaskFeedback() {
    const [tasks, setTasks] = useState<TaskFeedback[]>()
    const [expandedTask, setExpandedTask] = useState<TaskFeedback>()
    const taskStatuses: TaskStatus[] = ['draft', 'approved', 'rejected']
    const [sending, setSending] = useState(false)
    const [messages, setMessages] = useState<{taskId: string, feedback: any[]}>()

    const handleStatus = (newStatus: string, tasks: TaskFeedback[], index: number) => {
        const tempTasks = tasks.slice()
        const task = tempTasks.slice()[index]
        if (!task) return
        if (newStatus === task.status) {
            task.toBeClosed = false
        }
        else {
            task.toBeClosed = true
        }
        task.newStatus = newStatus as TaskStatus
        tempTasks.splice(index, 1, task)
        setTasks(tempTasks)
    }

    const changedCheckbox = (tasks: TaskFeedback[], index: number) => {
        const tempTasks = tasks.slice()
        const task = tempTasks.slice()[index]
        if (!task) return
        const newValue = !task.toBeClosed
        task.toBeClosed = newValue
        tempTasks.splice(index, 1, task)
        setTasks(tempTasks)
    }

    const handleExpand = (task: TaskFeedback) => () => {
        setExpandedTask(expandedTask && expandedTask.id === task.id ? undefined : task)
    }

    const handleGetMessages = (taskId: string) => {
        setMessages(undefined)
        taskService.getFeedbackMessages(taskId).then((res) => setMessages({taskId, feedback: res}))
    }

    const handleToBeDeleted = (tasks: TaskFeedback[], taskId: string) => {
        const tempTasks = tasks.slice()
        const task = tempTasks.find((t) => t.id === taskId)
        if (!task) return
        task.toBeDeleted = !task.toBeDeleted
        if(task.toBeDeleted) {
            task.toBeClosed = true
        }
        else if (task.status === task.newStatus) {
            task.toBeClosed = false
        }
        setTasks(tempTasks)
    }

    const saveChanges = (tasks: TaskFeedback[]) => {
        setSending(true)
        taskService.postFeedbackHandle(tasks.filter((task) => task.toBeClosed).map(t => ({ taskId: t.id, status: t.toBeDeleted ? 'rejected' : t.newStatus, delete: t.toBeDeleted })))
            .then(() => {
                taskService.getByFeedback('bug').then((res) => {
                    setTasks(res.map((task: TaskFeedback) => ({ ...task, newStatus: task.status, toBeClosed: false, toBeDeleted: false })))
                }).catch((err) => { console.log(err); setTasks([]) })
                .finally(() => setSending(false))
            })
    }

    useEffect(() => {
        taskService.getByFeedback('bug').then((res) => {
            setTasks(res.map((task: TaskFeedback) => ({ ...task, newStatus: task.status, toBeClosed: false, toBeDeleted: false })))
        }).catch((err) => { console.log(err); setTasks([]) })
    }, [])

    return (
        <div>
            <h4>HandleTaskFeedback</h4>
            <h5>Work in progress</h5>
            {tasks ? <>
                <table>
                    <thead>
                        <tr>
                            <th>Close feedback</th>
                            <th>Task</th>
                            <th>Nr of reports</th>
                            <th>Change status of task</th>
                            <th>Get messages</th>
                            <th>Delete task</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.length > 0 ? tasks.map((task, index) => {
                            return (<>
                                <tr key={task.id}>
                                    <td><input type='checkbox' onChange={(event) => changedCheckbox(tasks, index)} checked={task.toBeClosed} /></td>
                                    <td onClick={handleExpand(task)}>{task.id}</td>
                                    <td style={{ textAlign: 'right' }} onClick={handleExpand(task)}>{task.report_count}</td>
                                    <td style={{ textAlign: 'center' }}><select value={task.newStatus} onChange={(event) => handleStatus(event.target.value, tasks, index)} disabled={task.toBeDeleted}>{taskStatuses.map((ts, index) => <option key={index} value={ts}>{ts}</option>)}</select></td>
                                    <td><button onClick={() => handleGetMessages(task.id)}>Get messages</button></td>
                                    <td><button onClick={() => handleToBeDeleted(tasks, task.id)}>{!task.toBeDeleted ? 'Mark for deleting' : 'Undelete'}</button></td>
                                </tr>
                                {
                                    messages && messages.taskId === task.id ? messages.feedback.map((message, index) => <tr key={index}><td colSpan={5}>{message.feedback.message && `${message.name}: ${message.feedback.message}`}</td></tr>) : <></>
                                }
                                {expandedTask && expandedTask.id === task.id ? <tr><td colSpan={5}>{
                                    <Task data={task.data} onCompletion={(taskDef, score) => alert(score > 0 ? dictionary.Correct : dictionary.Incorrect)} />
                                } </td></tr> : <></>}
                            </ >)
                        }) : <tr><td>No tasks</td></tr>}
                    </tbody>
                </table>
                <button className='btn btn-primary mt-3' onClick={() => saveChanges(tasks)} disabled={tasks.filter((task) => task.toBeClosed).length === 0 || sending}>{!sending ? 'Save handled bug reports' : 'Saving'}</button>
            </> : <div>Loading...</div>}
        </div>
    )
}

export default HandleTaskFeedback