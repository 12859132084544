import React, { ChangeEvent, useState } from 'react';
// import only types from './simple-xlsx' statically, because its dependencies are huge and rarely used
import type { SimpleXlsxSheet } from './simple-xlsx';
import LoadingPage from 'components/LoadingPage/LoadingPage';

export type SimpleXlsxFile = {
    /** The file name. */
    name: string
    /** The sheets in the file. If the file is not a valid `.xlsx` file, this will be `null`. */
    sheets: SimpleXlsxSheet[] | null
}

export type SimpleXlsxFileInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'accept' | 'onChange' | 'type'> & {
    onChange: (xlsxFiles: SimpleXlsxFile[]) => void
}

const parseXlsxFile = async (file: File): Promise<SimpleXlsxFile> => ({
    name: file.name,
    sheets: await (await import('./simple-xlsx')).parseAsSimpleXlsxSheets(file),
})

const parseXlsxFiles = (files: ArrayLike<File>): Promise<SimpleXlsxFile[]> => {
    return Promise.all(Array.from(files, parseXlsxFile))
}

const SimpleXlsxFileInput = (props: SimpleXlsxFileInputProps) => {
    const [isLoading, setIsLoading] = useState(false)

    const loadInput = async (files: ArrayLike<File>): Promise<void> => {
        try {
            props.onChange(await parseXlsxFiles(files))
        } catch {
            // TODO: display an error to the user
        }
    }

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (isLoading) return

        setIsLoading(true)
        loadInput(event.target.files ?? [])
            .finally(() => setIsLoading(false))
    }

    return (<>
        <input {...props} type="file" accept=".xlsx" onChange={onChange} disabled={isLoading} />
        {isLoading && <LoadingPage  />}
    </>
    )
}

export default SimpleXlsxFileInput;
