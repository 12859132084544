import { AreaNode, ExportNode } from '@viewmodel/areaNode';
import React from 'react'
import areaNodeService from 'services/areaNodeService';

const findNodePath = (node: AreaNode): string[] => {
    const headline = `/${node.headline}`;
    let hierarchies: string[] = [];
    node.subareas?.forEach(sub => {
        hierarchies = hierarchies.concat(
            findNodePath(sub).map(sub => `${headline}${sub}`)
        );
    });
    return hierarchies.length > 0 ? hierarchies : [headline]
}

const findNodePaths = (compareNodePaths: string, nodeArray: AreaNode[]) => {
    const paths = nodeArray.flatMap(findNodePath);
    const compareNodePathsArray = compareNodePaths.split('/');
    let tempPaths = paths
    let comparePath = ''
    compareNodePathsArray.forEach(path => {
        if (path !== '') {
            comparePath += `/${path}`
            tempPaths = paths
                .filter(tp => tp.includes(`${comparePath}/`)
                    || tp.endsWith(`${comparePath}`))
        }
    });
    return tempPaths;
}

const findNodeFromNodePath = (nodeString: string, nodeArray: AreaNode[], currentCheckPath: string[]): AreaNode | null => {
    currentCheckPath = currentCheckPath.filter(h => h !== '')
    const nodePathArray = nodeString.split('/')
    let returnNode: AreaNode | null = null
    nodeArray.forEach(node => {
        if (node.headline === nodePathArray[nodePathArray.length - 1]) {
            returnNode = node;
            return;
        }
        if (node.headline === currentCheckPath.splice(0, 1)[0] && node.subareas) {
            returnNode = findNodeFromNodePath(nodeString, node.subareas, currentCheckPath)
            return;
        }
    })
    return returnNode;
}

const findExistingPartOfPath = (nodePath: string, nodeArray: AreaNode[]): string => {
    if (nodePath.trim() === '') {
        return ''
    }
    const paths = findNodePaths(nodePath, nodeArray)

    if (paths.length === 0) {
        return findExistingPartOfPath(nodePath.substring(0, nodePath.lastIndexOf('/')), nodeArray)
    }
    return paths[0].slice(0, paths[0].indexOf(nodePath) + nodePath.length)
}

const createNodeFromString = (nodeName: string, ruleText: string): AreaNode => {
    return { id: -1, headline: nodeName, description: ruleText, subareas: [] }

}

const createNodesFromPathArray = (nodeNameArray: string[], rules: { node: string; text: string; }[], existingPath: string): ExportNode[] => {
    if (!existingPath.endsWith('/')) existingPath += '/'
    const filteredArray = nodeNameArray.filter(n => !existingPath.includes(`/${n}/`))
    return filteredArray.map((n, index) => (
        {
            ...createNodeFromString(n, rules.find(r => r.node === n)?.text ?? ''),
            parentPath: `${existingPath}${filteredArray.slice(0, index).join('/')}`
        }
    ))
}

function removeDuplicates(a: ExportNode[]) {
    if (a.length === 0 || a.length === 1) {
        return a;
    }
    const unique: ExportNode[] = []
    a.forEach(element => {
        const existingIndex = unique.findIndex(u => u.headline === element.headline && u.parentPath === element.parentPath)
        if (existingIndex === -1) {
            unique.push(element);
        } else if (unique[existingIndex].description?.trim() === '' && element.description?.trim() !== '') {
            unique[existingIndex].description = element.description
        }
    });
    return unique;
}

const ensureNodesExist = async (nodePaths: string[], nodeArray: AreaNode[], rules: { node: string, text: string }[], setNodes: (nodes: AreaNode[]) => void) => {
    const uniqueNodePaths = nodePaths.filter((node, index, self) => self.indexOf(node) === index);
    const uniqueRules = rules.filter((value, index, self) => self.findIndex(i => i.node === value.node) === index)

    const newNodes = uniqueNodePaths.flatMap((n) => { return createNodesFromPathArray(n.split('/'), uniqueRules, findExistingPartOfPath(n, nodeArray)) })
    if (newNodes.length > 0) {
        const uniqueNewNodes = removeDuplicates(newNodes)

        await areaNodeService.insert(uniqueNewNodes, rules)
    }
    const updatedNodes = await areaNodeService.getAreaNodes()
    setNodes(updatedNodes)
}





const PrepareNodesForImport = {
    ensureNodesExist,
    findNodePath,
    findNodePaths,
    findNodeFromNodePath,
    findExistingPartOfPath,
    createNodesFromPathArray,
    removeDuplicates,
}

export default PrepareNodesForImport;
